// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import { QueryBuilder } from 'common/api/resources/Request/QueryBuilder';
import {
    IVpcNetworkAddIpRequest,
    IVpcNetworkAddIpsRequest,
    IVpcNetworkIpResponse,
    IVpcNetworkListRequest,
    IVpcNetworkRequest,
    IVpcNetworkResponse,
} from 'common/api/resources/VpcNetwork/model';
import { IPaginatedWithSearch } from 'common/api/resources/Request/request';
import { CancelTokenSource } from 'axios';

export const vpcNetwork = {
    list: (params?: IVpcNetworkListRequest, cancelToken?: CancelTokenSource) => api.get<IPaginateApiResponse<IVpcNetworkResponse[]>>('vpc_networks', {
        params: new QueryBuilder(params).build(),
        cancelToken: cancelToken?.token,
    }),
    item: (id: number) => api.get<IApiResponse<IVpcNetworkResponse>>(`vpc_networks/${id}`),
    remove: (id: number) => api.delete(`vpc_networks/${id}`),
    create: (data: IVpcNetworkRequest) => api.post<IApiResponse<IVpcNetworkResponse>>('vpc_networks', data),
    update: (id: number, data: IVpcNetworkRequest) => api.put<IApiResponse<IVpcNetworkResponse>>(`vpc_networks/${id}`, data),

    ips: {
        list: (id: number, params?: IPaginatedWithSearch) => api.get<IPaginateApiResponse<IVpcNetworkIpResponse[]>>(`vpc_networks/${id}/ips`, {
            params: new QueryBuilder(params).build(),
        }),
        addSingle: (id: number, data: IVpcNetworkAddIpRequest) => api.post<IApiResponse<IVpcNetworkIpResponse>>(`vpc_networks/${id}/add_ip`, data),
        addMultiple: (id: number, data: IVpcNetworkAddIpsRequest) => api.post<IApiResponse<IVpcNetworkIpResponse[]>>(`vpc_networks/${id}/add_ips`, data),
        remove: (vpcNetworkId: number, id: number) => api.delete(`vpc_networks/${vpcNetworkId}/ips/${id}`),
        removeBatch: (vpcNetworkId: number, ids: number[]) => api.delete(`vpc_networks/${vpcNetworkId}/ips`, { data: { ids } }),
    },
};
