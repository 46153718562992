// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { COLORS } from 'common/theme';
import { CSSProperties } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getControlBackgroundColor = (state: any) => {
    let color = COLORS.WHITE_0;

    if (state.selectProps.hasErrors) {
        color = COLORS.RED_10;
    }

    if (state.isDisabled) {
        color = COLORS.GREY_20;
    }

    return color;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getControlBorderStyle = (state: any) => {
    let color = COLORS.GREY_60;

    if (state.selectProps.hasErrors) {
        color = COLORS.RED_0;
    }

    if (state.isDisabled) {
        color = COLORS.GREY_40;
    }

    return `1px solid ${color}`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getControlColor = (state: any) => {
    let color = COLORS.GREY_70;

    if (state.isDisabled) {
        color = COLORS.GREY_100;
    }

    return color;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const container = (base: CSSProperties, state: any): CSSProperties => ({
    ...base,
    width: state.selectProps.width ? state.selectProps.width : '100%',
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const control = (base: CSSProperties, state: any): CSSProperties => ({
    ...base,
    minHeight: state.selectProps.minHeight ? state.selectProps.minHeight : '28px',
    width: state.selectProps.width ? state.selectProps.width : '100%',
    padding: '0',
    border: getControlBorderStyle(state),
    borderRadius: '2px',
    backgroundColor: getControlBackgroundColor(state),
    color: getControlColor(state),
    boxShadow: 'unset',
    flexWrap: 'unset',
    boxSizing: 'border-box',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [':hover' as any]: {
        borderColor: '#259ccc',
    },
});

const input = (base: CSSProperties): CSSProperties => ({
    ...base,
    margin: 0,
});

const multiValue = (base: CSSProperties): CSSProperties => ({
    ...base,
    background: COLORS.GREY_20,
    maxHeight: '16px',
});

const dropdownIndicator = (base: CSSProperties): CSSProperties => ({
    ...base,
    padding: 0,
});

const clearIndicator = (base: CSSProperties): CSSProperties => ({
    ...base,
    padding: 0,
});

const indicatorsContainer = (base: CSSProperties): CSSProperties => ({
    ...base,
    paddingRight: '8px',
});

const multiValueLabel = (base: CSSProperties): CSSProperties => ({
    ...base,
    padding: '1px',
    fontSize: '12px',
    lineHeight: '16px',
});

const singleValue = (base: CSSProperties): CSSProperties => ({
    ...base,
    position: 'initial',
    display: 'flex',
    alignItems: 'center',
    top: 'unset',
    transform: 'unset',
});

const multiValueRemove = (base: CSSProperties) => ({
    ...base,
    color: COLORS.GREY_80,
    ':hover': {
        backgroundColor: COLORS.GREY_20,
        color: COLORS.GREY_100,
    },
});

export const selectInputStyles = {
    container,
    control,
    multiValue,
    multiValueLabel,
    input,
    dropdownIndicator,
    indicatorsContainer,
    clearIndicator,
    singleValue,
    multiValueRemove,
} as CSSProperties;
