// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    useCallback,
    useRef,
    useState,
    FC,
    ChangeEventHandler,
    KeyboardEvent,
    PropsWithChildren,
    MouseEventHandler,
    ComponentProps,
} from 'react';
import { Icon } from '@plesk/ui-library';
import {
    ActionsContainer,
    Control,
    Input,
    InputAction,
    Tag,
    TagAction,
    TagValue,
    ValueContainer,
} from 'common/components/Form/FormFieldTags/Styles';
import { ICONS } from 'common/constants';

export const TAG_INPUT = {
    REMOVE_TAG: 'remove-tag',
    CLEAR: 'clear-button',
};

interface IInputTagProps {
    inputId: string;
    name: string;
    value: string[] | null;
    onChange: (tags: string[]|null) => void;
    delimiters?: string[];
}

interface IInputTagItemProps {
    onDelete: () => void;
}

const TagInputItem: FC<PropsWithChildren<IInputTagItemProps>> = ({ onDelete, children }) => {
    const handleClickDelete = useCallback<MouseEventHandler<HTMLButtonElement>>(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            onDelete && onDelete();
        },
        [onDelete]
    );

    return (
        <Tag>
            <TagValue>{children}</TagValue>
            <TagAction
                onClick={handleClickDelete}
                data-cy='remove-tag'
            >
                <Icon name='cross-mark' size='12' />
            </TagAction>
        </Tag>
    );
};

const TagsInput: FC<IInputTagProps & Omit<ComponentProps<typeof Input>, 'value' | 'onChange' | 'onKeyDown' | 'onBlur'>> = (props) => {
    const {
        inputId,
        name,
        placeholder,
        value: tags,
        onChange: setTags,
        delimiters = [',', 'Enter'],
        disabled,
        ...inputProps
    } = props;

    const [input, setInput] = useState('');

    const inputRef = useRef<HTMLInputElement>(null);
    const focus = useCallback(
        () => inputRef.current?.focus(),
        [inputRef]
    );

    const handleInput = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (e) => setInput(e.target.value),
        [setInput]
    );

    const handleKeyDown = (e: KeyboardEvent) => {
        const { key } = e;
        const trimmedInput = input.trim();

        if (delimiters.includes(key)) {
            e.preventDefault();
            e.stopPropagation(); // prevent submitting form

            if (trimmedInput.length && (!Array.isArray(tags) || !tags.includes(trimmedInput))) {
                setTags([...(tags || []), trimmedInput]);
                setInput('');
            }
        }

        if (key === 'Backspace' && !input.length && Array.isArray(tags) && tags.length) {
            e.preventDefault();
            const tagsCopy = [...tags];
            const poppedTag = tagsCopy.pop();

            if (poppedTag) {
                setTags(tagsCopy);
                setInput(poppedTag);
            }
        }
    };

    const handleBlur = () => {
        const trimmedInput = input.trim();

        if (trimmedInput.length && (!Array.isArray(tags) || !tags.includes(trimmedInput))) {
            setTags([...(tags || []), trimmedInput]);
            setInput('');
        }
    };

    const handleTagDelete = (tagIndex: number) => {
        const tagsCopy = [...tags!];
        tagsCopy.splice(tagIndex, 1);
        setTags(tagsCopy);
    };

    const handleClearClicked = useCallback(
        (): void => {
            setTags(null);
            setInput('');
        },
        [setTags]
    );

    return (
        <Control disabled={disabled} onClick={focus}>
            <ValueContainer>
                {tags && tags.map((tag, index) => (
                    <TagInputItem
                        key={index}
                        onDelete={() => handleTagDelete(index)}
                    >
                        {tag}
                    </TagInputItem>
                ))}
                <Input
                    ref={inputRef}
                    id={inputId}
                    name={name}
                    value={input}
                    placeholder={tags && tags.length > 0 ? '' : placeholder}
                    onChange={handleInput}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    disabled={disabled}
                    {...inputProps}
                />
            </ValueContainer>
            <ActionsContainer>
                <InputAction
                    onClick={handleClearClicked}
                    icon={ICONS.CROSS_MARK}
                    ghost
                    data-cy={TAG_INPUT.CLEAR}
                />
            </ActionsContainer>
        </Control>
    );
};

export default TagsInput;
