// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Alert,
    Form,
    FormField,
    FormFieldText,
    Icon,
    Link,
    Translate,
} from '@plesk/ui-library';
import {
    ICONS,
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import {
    AdditionalIpDialogText,
    AdditionalIpDialogSection,
    AdditionalIpDialogSwitch,
} from 'common/components/ServerTabs/NetworkingTab/Styles';
import { Button } from 'admin/common/components/Button/Button';
import { connect } from 'react-redux';
import {
    IAdditionalIpRequest,
    IVmResponse,
} from 'common/api/resources/ComputeResourceVm';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as computeResourceVmActions from 'common/modules/computeResourceVm/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { ICommonState } from 'common/store';
import {
    ipRule,
    requiredRule,
    validate,
} from 'common/validator';
import { useMemo } from 'react';
import { IFormProps } from 'common/components/Form/types';
import { SegmentedControl } from 'common/components/SegmentedControl/SegmentedControl';
import { IpBlockType } from 'common/api/resources/IpBlock';
import {
    formatPricePerHour,
    formatPricePerMonth,
} from 'common/helpers/token_pricing';

const ipBlockTypeOptions = Object.values(IpBlockType).map((type) => ({
    value: type.toString(),
    title: type.toString(),
}));

interface IAdditionalIpFormProps {
    server: IVmResponse;
    onSubmit?: () => void;
}

export type AdditionalIpFormProps =
    IAdditionalIpFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const AdditionalIPForm: React.FC<AdditionalIpFormProps> = ({
    server,
    onSubmit,
    formErrors,
    termsAndConditionsUrl,
    canAssignSpecificIp,
    isAdditionalIpAdding,
    createAdditionalIp,
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
}) => {
    const [withSpecificIp, setWithSpecificIp] = React.useState(false);
    const [submitValues, setSubmitValues] = React.useState<IAdditionalIpRequest>({ });

    React.useEffect(() => () => {
        clearFormErrors();
        setWithSpecificIp(false);
        setSubmitValues({});
    }, [clearFormErrors]);

    const toggleWithSpecificIp = () => {
        setWithSpecificIp(curr => !curr);
    };

    const handleTypeChange = (value: IpBlockType) => {
        setSubmitValues(curr => ({
            ...curr,
            type: value,
        }));
    };

    const handleIpChange = (value: string) => {
        setSubmitValues(curr => ({
            ...curr,
            ip: value,
        }));
    };

    const handleSubmit = async (values: IAdditionalIpRequest) => {
        // Omit IP property unless specific
        let data: IAdditionalIpRequest = (({ ip, ..._data }: IAdditionalIpRequest): IAdditionalIpRequest => _data)(values);

        if (withSpecificIp) {
            const errors = validate(values, {
                ip: [
                    requiredRule(<Translate content="validate.fieldRequired"/>),
                    ipRule(<Translate content="validate.badIpAddress"/>),
                ],
            });

            if (Object.keys(errors).length) {
                setFormErrors(errors);
                return;
            }

            data.ip = values.ip;
        }

        try {
            await createAdditionalIp(server.id, server.status, data);
            onSubmit?.();
        } catch (e) {
            throw e;
        }
    };

    const description = useMemo(
        () => buildDescription(server, termsAndConditionsUrl),
        [server, termsAndConditionsUrl]
    );

    return (
        <>
            <AdditionalIpDialogText>
                {description}
            </AdditionalIpDialogText>
            <Form
                id="additionalIpForm"
                footerClassName="hidden"
                onSubmit={handleSubmit}
                values={submitValues}
                errors={formErrors}
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <AdditionalIpDialogSection>
                    <FormField
                        name="type"
                        required={true}
                        label={<Translate content="servers.tabs.networking.additionalIps.createDialog.form.type" />}
                        onChange={handleTypeChange}
                    >
                        {({ setValue }: IFormProps<string>) => (
                            <SegmentedControl
                                buttons={ipBlockTypeOptions}
                                selected={submitValues.type || IpBlockType.IPv4}
                                onChange={setValue}
                            />
                        )}
                    </FormField>
                    {canAssignSpecificIp && (
                        <>
                            <AdditionalIpDialogSwitch
                                checked={withSpecificIp}
                                onChange={toggleWithSpecificIp}
                            >
                                <Translate content="servers.tabs.networking.additionalIps.createDialog.form.enableSpecificIp"/>
                            </AdditionalIpDialogSwitch>
                            <FormFieldText
                                size="fill"
                                name="ip"
                                label={<Translate content="servers.tabs.networking.additionalIps.createDialog.form.ip" />}
                                required={false}
                                onChange={handleIpChange}
                                placeholder={submitValues.type === IpBlockType.IPv6
                                    ? '2001:abcd:1234:5678::/64'
                                    : '192.0.2.1'
                                }
                                disabled={!withSpecificIp}
                            />
                        </>
                    )}
                </AdditionalIpDialogSection>
            </Form>
            <Button
                type="submit"
                form="additionalIpForm"
                intent={INTENT_TYPE.PRIMARY}
                size={SIZE.LG}
                fill={true}
                isLoading={isAdditionalIpAdding}
            >
                <Translate
                    content={`servers.tabs.networking.additionalIps.createDialog.${server.project.token_pricing !== undefined ? 'createAndBuyButton' : 'createButton'}`}
                />
            </Button>
        </>
    );
};

const buildDescription = (server: IVmResponse, termsAndConditionsUrl: string) => {
    const warning = (
        <Alert intent={INTENT_TYPE.WARNING}>
            <Icon intent={INTENT_TYPE.WARNING} name={ICONS.TRIANGLE_EXCLAMATION_MARK_FILLED} />
            &nbsp;
            <Translate content="servers.tabs.networking.additionalIps.createDialog.warning" />
        </Alert>
    );

    if (!server.project?.token_pricing) {
        return warning;
    }

    const price = (
        <Translate
            content="servers.tabs.networking.additionalIps.createDialog.description.price"
            params={{
                monthPrice: formatPricePerMonth(server.plan.ip_tokens_per_month, server.project.token_pricing),
                hourPrice: formatPricePerHour(server.plan.ip_tokens_per_hour, server.project.token_pricing),
            }}
        />
    );

    const taxes = (
        <Translate
            content={`servers.tabs.networking.additionalIps.createDialog.description.${server.project.token_pricing.taxes_inclusive ? 'taxesInclusive' : 'taxesExclusive'}`}
            params={{
                taxes: server.project.token_pricing.taxes.map(tax => `${tax.rate}% ${tax.label}`).join(', '),
            }}
        />
    );

    const termsAndConditions = (
        <Translate
            content="servers.tabs.networking.additionalIps.createDialog.description.termsAndConditions.text"
            params={{
                link: (
                    <Link href={termsAndConditionsUrl} target="blank">
                        <Translate content="servers.tabs.networking.additionalIps.createDialog.description.termsAndConditions.link"/>
                    </Link>
                ),
            }}
        />
    );

    return (
        <>{warning} {price} {server.project.token_pricing.taxes.length > 0 && taxes} {termsAndConditionsUrl && termsAndConditions}</>
    );
};

const mapStateToProps = (state: ICommonState) => ({
    formErrors: nestStringProperties(state),
    termsAndConditionsUrl: state.settings.theme.terms_and_conditions_url,
    canAssignSpecificIp: hasPermission(state, PERMISSION_LIST.MANAGE_SERVERS),
    isAdditionalIpAdding: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_ADDITIONAL_IP_ADDING),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    createAdditionalIp: bindActionCreators(computeResourceVmActions.createAdditionalIp, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalIPForm);
