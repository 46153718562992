// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    FormFieldText,
    Section,
    Translate,
} from '@plesk/ui-library';
import SettingsWrapper from 'admin/settings/containers/Settings/SettingsWrapper';
import * as settingsActions from 'common/modules/settings/actions';
import { connect } from 'react-redux';
import {
    Dispatch,
    bindActionCreators,
} from 'redux';
import { ISettingsResponse } from 'common/api/resources/Settings';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import {
    intMinRule,
    validate,
} from 'common/validator';
import { SIZE } from 'common/constants';

export type VpcNetworkSettingsProps = ReturnType<typeof mapDispatchToProps>;

const MTU_MIN: number = 1280;

export const VpcNetworkSettings: React.FC<VpcNetworkSettingsProps> = ({
    settingsActions: { saveSettings },
    formErrorsActions: { setFormErrors },
}) => {
    const handleSubmit = (values: ISettingsResponse) => {
        const formErrors = validate<ISettingsResponse>(values, {
            'vpc_network.mtu': intMinRule(<Translate content="validate.fieldIntMin" params={{ min: MTU_MIN }} />, MTU_MIN),
        });

        if (Object.keys(formErrors).length) {
            setFormErrors(formErrors);
            return;
        }

        saveSettings({
            vpc_network: values.vpc_network,
        });
    };

    return (
        <SettingsWrapper
            title="settings.titles.vpcNetwork"
            onSubmit={handleSubmit}
        >
            <Section>
                <FormFieldText
                    name="vpc_network[mtu]"
                    size={SIZE.MD}
                    label={<Translate content="settings.vpcNetwork.mtu" />}
                    required={true}
                    type="number"
                    inputMode="numeric"
                />
            </Section>
        </SettingsWrapper>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    settingsActions: bindActionCreators(settingsActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(null, mapDispatchToProps)(VpcNetworkSettings);
