// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Action,
    Translate,
} from '@plesk/ui-library';
import { INTENT_TYPE } from 'common/constants';
import { Status } from 'common/components/styles/Status';
import { ComputeResourceVmStatus } from 'common/api/resources/ComputeResourceVm';

export interface IVMStatusProps {
    status: string;
    isProcessing: boolean;
    progress?: number;
    compact?: boolean;
}

export const statusMappings: Record<ComputeResourceVmStatus, string> = {
    [ComputeResourceVmStatus.CREATING]: 'servers.status.creating',
    [ComputeResourceVmStatus.RESIZING]: 'servers.status.resizing',
    [ComputeResourceVmStatus.RESTARTING]: 'servers.status.restarting',
    [ComputeResourceVmStatus.STOPPING]: 'servers.status.stopping',
    [ComputeResourceVmStatus.STARTING]: 'servers.status.starting',
    [ComputeResourceVmStatus.SUSPENDING]: 'servers.status.suspending',
    [ComputeResourceVmStatus.RESUMING]: 'servers.status.resuming',
    [ComputeResourceVmStatus.REINSTALLING]: 'servers.status.reinstalling',
    [ComputeResourceVmStatus.MIGRATING]: 'servers.status.migrating',
    [ComputeResourceVmStatus.BACKUP_RESTORING]: 'servers.status.backupRestoring',
    [ComputeResourceVmStatus.BACKUP_CREATING]: 'servers.status.backupCreating',
    [ComputeResourceVmStatus.SNAPSHOT_CREATING]: 'servers.status.snapshotCreating',
    [ComputeResourceVmStatus.SNAPSHOT_REVERTING]: 'servers.status.snapshotReverting',
    [ComputeResourceVmStatus.SNAPSHOT_DELETING]: 'servers.status.snapshotDeleting',
    [ComputeResourceVmStatus.USAGE_RESETTING]: 'servers.status.usageResetting',
    [ComputeResourceVmStatus.CHANGING_PASSWORD]: 'servers.status.passwordChanging',
    [ComputeResourceVmStatus.UPDATING]: 'servers.status.updating',
    [ComputeResourceVmStatus.DELETING]: 'servers.status.deleting',
    [ComputeResourceVmStatus.UNAVAILABLE]: 'servers.status.unavailable',
    [ComputeResourceVmStatus.STARTED]: 'servers.status.running',
    [ComputeResourceVmStatus.STOPPED]: 'servers.status.stopped',
    [ComputeResourceVmStatus.PAUSED]: 'servers.status.paused',
    [ComputeResourceVmStatus.NOT_EXIST]: 'servers.status.notExist',
    [ComputeResourceVmStatus.NO_STATE]: 'servers.status.noState',
    [ComputeResourceVmStatus.ADDITIONAL_IP_ADDING]: 'servers.status.additionalIpAdding',
    [ComputeResourceVmStatus.ADDITIONAL_IP_DELETING]: 'servers.status.additionalIpDeleting',
    [ComputeResourceVmStatus.PRIMARY_IP_CHANGING]: 'servers.status.primaryIpChanging',
    [ComputeResourceVmStatus.ADDITIONAL_DISK_ADDING]: 'servers.status.additionalDiskAdding',
    [ComputeResourceVmStatus.ADDITIONAL_DISK_DELETING]: 'servers.status.additionalDiskDeleting',
    [ComputeResourceVmStatus.ADDITIONAL_DISK_RESIZING]: 'servers.status.additionalDiskResizing',
    [ComputeResourceVmStatus.INSTALLING_GUEST_TOOLS]: 'servers.status.installingGuestTools',
};

const intentMappings: Partial<Record<ComputeResourceVmStatus, INTENT_TYPE>> = {
    [ComputeResourceVmStatus.NOT_EXIST]: INTENT_TYPE.MUTED,
    [ComputeResourceVmStatus.UNAVAILABLE]: INTENT_TYPE.WARNING,
    [ComputeResourceVmStatus.PAUSED]: INTENT_TYPE.WARNING,
    [ComputeResourceVmStatus.STARTED]: INTENT_TYPE.SUCCESS,
    [ComputeResourceVmStatus.STOPPED]: INTENT_TYPE.DANGER,
    [ComputeResourceVmStatus.NO_STATE]: INTENT_TYPE.DANGER,
};

const statusWithProgress = [
    ComputeResourceVmStatus.CREATING,
    ComputeResourceVmStatus.REINSTALLING,
    ComputeResourceVmStatus.MIGRATING,
    ComputeResourceVmStatus.BACKUP_CREATING,
    ComputeResourceVmStatus.BACKUP_RESTORING,
];

export const VmStatus: React.FC<IVMStatusProps> = ({
    status,
    isProcessing,
    progress,
    compact,
}) => {
    if (isProcessing) {
        const processingProgress = statusWithProgress.includes(status as ComputeResourceVmStatus) ? `${progress ? progress : 0}%` : '';
        return (
            <Action progress={true}>
                <Translate content={statusMappings[status]} /> {processingProgress}
            </Action>
        );
    }

    if (!intentMappings.hasOwnProperty(status)) {
        return null;
    }

    return (
        <Status
            intent={intentMappings[status]}
            compact={compact}
        >
            <Translate content={statusMappings[status]} />
        </Status>
    );
};
