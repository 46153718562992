// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { Popover } from '@plesk/ui-library';
import { COLORS } from 'common/theme';

export const StyledPopover = styled(Popover)`
    padding: unset;
    
    .pul-popover__arrow--heading::after {
      background-color: ${COLORS.GREY_20} !important;
    }
`;
