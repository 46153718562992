// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Button,
    Popover,
} from '@plesk/ui-library';
import { INTENT_TYPE } from 'common/constants';
import { StyledButton } from 'common/components/styles/Button';
import { COLORS } from 'common/theme';

export interface IConfirmationTranslation {
    button: React.ReactNode;
    text?: React.ReactNode;
    title?: React.ReactNode;
}

export interface IConfirmationPopoverProps extends Omit<React.HTMLProps<HTMLDivElement>, 'target'> {
    opened?: boolean;
    children: React.ReactElement;
    translations: IConfirmationTranslation;
    error?: string;
    isLoading?: boolean;
    disabled?: boolean;
    dataCy?: string;
    buttonDataCy?: string;
    intent?: INTENT_TYPE | null;
    onClose?: () => void;
    onButtonClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    buttonColor?: string;
    placement?: string;
}

export const ConfirmationPopover: React.FC<IConfirmationPopoverProps> = ({
    opened,
    translations: {
        button,
        text,
        title,
    },
    error,
    isLoading,
    children,
    disabled,
    dataCy,
    buttonDataCy,
    intent,
    onClose,
    onButtonClick,
    buttonColor,
    placement,
}) => {
    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation();

    return (
        <Popover
            data-cy={dataCy}
            onClick={handleClick}
            onClose={onClose}
            intent={intent}
            visible={opened}
            target={children}
            placement={placement ? placement : 'left'}
        >
            {
                error
                    ? error
                    : (
                        <>
                            {title && <h4>{title}</h4>}
                            {text}
                            <StyledButton color={buttonColor !== undefined ? buttonColor : COLORS.RED_0}>
                                <Button
                                    data-cy={buttonDataCy}
                                    state={!disabled && isLoading ? 'loading' : undefined}
                                    disabled={disabled}
                                    onClick={onButtonClick}
                                    intent="primary"
                                    fill={true}
                                >
                                    {button}
                                </Button>
                            </StyledButton>
                        </>
                    )
            }
        </Popover>
    );
};

export default ConfirmationPopover;
