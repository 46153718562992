// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Button,
    Form,
    Paragraph,
    Section,
    setIn,
    Translate,
} from '@plesk/ui-library';
import FormFieldPassword from 'common/components/Form/FormFieldPassword/FormFieldPassword';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { IAuthenticateRequest } from 'common/api/resources/Auth';
import { ICommonState } from 'common/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import * as confirmIdentityActions from 'common/modules/auth/confirmIdentity/actions';
import { connect } from 'react-redux';
import {
    requiredRule,
    validate,
} from 'common/validator';
import { callInsecureAction } from 'common/modules/auth/confirmIdentity/insecureActionHelpers';

interface IConfirmIdentityFormProps {
    onSubmit: () => void;
}

export type ConfirmIdentityFormProps =
    IConfirmIdentityFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const defaultValues: IAuthenticateRequest = {
    password: '',
};

export const ConfirmIdentityForm: React.FC<ConfirmIdentityFormProps> = ({
    isAuthenticating,
    formErrors,
    onSubmit,
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
    confirmIdentityActions: {
        authenticate,
    },
}) => {
    const [submitValues, setSubmitValues] = React.useState(defaultValues);

    const handleFieldChange = (field: string, value: string) => setSubmitValues(setIn(submitValues, field, value));

    const handleSubmit = async (values: IAuthenticateRequest) => {
        const errors = validate(values, {
            password: requiredRule(<Translate content="validate.fieldRequired" />),
        });

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        await authenticate(values);
        onSubmit();
        await callInsecureAction();
    };

    React.useEffect(() => () => {
        clearFormErrors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Form
            onSubmit={handleSubmit}
            values={submitValues}
            onFieldChange={handleFieldChange}
            footerClassName="hidden"
            errors={formErrors}
            hideRequiredLegend={true}
            submitButton={false}
            cancelButton={false}
            applyButton={false}
            vertical={true}
        >
            <Section>
                <Paragraph>
                    <Translate content="auth.confirmIdentity.form.info" />
                </Paragraph>
                <FormFieldPassword
                    name="password"
                    size={SIZE.FILL}
                    label={<Translate content="auth.confirmIdentity.form.password" />}
                    required={true}
                />
            </Section>
            <Button
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                type="submit"
                size={SIZE.LG}
                state={isAuthenticating ? 'loading' : null}
            >
                <Translate content="auth.confirmIdentity.form.submit"/>
            </Button>
        </Form>
    );
};

const mapStateToProps = (state: ICommonState) => ({
    isAuthenticating: state.app.loadingFlags.has(LOADING_FLAGS.AUTHENTICATE),
    formErrors: nestStringProperties(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
    confirmIdentityActions: bindActionCreators(confirmIdentityActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmIdentityForm);
