// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { TASKS } from 'admin/computeResource/constants/tests';
import * as taskActions from 'admin/task/actions';
import { StyledActions } from 'common/components/Actions/Styles';
import { TASKS_TABLE } from 'admin/task/constants/test';
import {
    ITaskListFilters,
    ITaskResponse,
} from 'common/api/resources/Task';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import { TaskStatus } from 'admin/task/components/TaskStatus';
import ComputeResourceTaskDialog from 'admin/task/containers/TaskDialog';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { formatTableDate } from 'common/date';
import { EmptyAction } from 'admin/task/containers/Styles';
import CopyText from 'common/containers/CopyText/CopyText';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import {
    Action,
    Button,
    Icon,
    Translate,
} from '@plesk/ui-library';
import { ICONS } from 'common/constants';
import List from 'common/components/List/List';
import { getActionColumnProps } from 'common/helpers/list';
import { Link } from 'react-router-dom';
import { pathTo } from 'common/helpers/core';

const columns = [{
    key: 'colId',
    width: '1%',
    title: <Translate content="task.list.id" />,
}, {
    key: 'colAction',
    title: <Translate content="task.list.queue" />,
}, {
    key: 'colStatus',
    title: <Translate content="task.list.status" />,
}, {
    key: 'colComputeResource',
    title: <Translate content="task.list.computeResource" />,
}, {
    key: 'colUser',
    title: <Translate content="task.list.user" />,
}, {
    key: 'colServerId',
    title: <Translate content="task.list.serverId" />,
}, {
    key: 'colServerUuid',
    truncate: true,
    title: <Translate content="task.list.serverUuid" />,
}, {
    key: 'colDuration',
    title: <Translate content="task.list.duration" />,
}, {
    key: 'colCreated',
    title: <Translate content="task.list.created" />,
}, {
    key: 'colFinished',
    title: <Translate content="task.list.finished" />,
}, getActionColumnProps({ width: '5%' }),
];

interface ITaskTableProps {
    excludedColumns?: string[];
    isFirstLoading: boolean;
    borderless?: boolean;
    filters?: ITaskListFilters;
    toolbar?: React.ReactNode;
}

export type TaskTableProps =
    ITaskTableProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const TaskTable: React.FC<TaskTableProps> = ({
    tasks,
    isFirstLoading,
    borderless,
    taskActions: {
        getTaskList,
        setTask,
        retry,
        cancel,
    },
    isLoadingTaskList,
    excludedColumns,
    filters,
    toolbar,
}) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const handleCancel = (id: number) => () => cancel(id);
    const handleRetry = (id: number) => () => retry(id);
    const handleOpen = (task: ITaskResponse) => () => {
        setTask(task);
        setIsOpen(true);
    };
    const handleClose = () => setIsOpen(false);

    const loadPaginated = (page: number) => getTaskList({
        filters,
        page,
    });

    const data = tasks.data.map((item) => {
        const actionsEl = (
            <StyledActions>
                <Button
                    ghost={true}
                    className="action-icon"
                    icon={<Icon name={ICONS.RELOAD} />}
                    onClick={handleRetry(item.id)}
                    data-cy={TASKS_TABLE.RETRY_BUTTON}
                    disabled={!item.is_retryable}
                    tooltip={<Translate content="task.tooltip.retry" />}
                />
                <ButtonWithConfirmation
                    disabled={!item.is_cancelable}
                    translations={{
                        button: (
                            <Translate content="task.cancelPopover.button" />
                        ),
                        title: (
                            <Translate content="task.cancelPopover.title" />
                        ),
                        tooltip: (
                            <Translate content="task.cancelPopover.tooltip" />
                        ),
                    }}
                    handleConfirm={handleCancel(item.id)}
                    data-cy={TASKS_TABLE.CANCEL_BUTTON}
                    icon={ICONS.RECYCLE}
                />
                <EmptyAction />
            </StyledActions>
        );

        return {
            colId: item.id,
            colAction: <b>{item.action}</b>,
            colStatus: <TaskStatus item={item} onOpen={handleOpen(item)} />,
            colComputeResource: (
                item.compute_resource && (
                    <Action
                        component={Link}
                        to={pathTo(`compute_resources/${item.compute_resource.id}`)}
                    >
                        {item.compute_resource.host}
                    </Action>
                )
            ),
            colServerId: (
                item.compute_resource_vm_id && (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <Action
                        component={Link}
                        to={pathTo(`servers/${item.compute_resource_vm_id}`)}
                    >
                        {item.compute_resource_vm_id}
                    </Action>
                )
            ),
            colUser: item.user_email,
            colServerUuid: <CopyText>{item.compute_resource_vm_uuid}</CopyText>,
            colDuration: item.duration,
            colCreated: formatTableDate(item.started_at),
            colFinished: item.finished_at ? formatTableDate(item.finished_at) : null,
            colActions: actionsEl,
            key: item.id.toString(),
        };
    });

    const filtered = filters !== undefined && Object.values(filters).some(filter => !!filter);

    return (
        <>
            <List
                isLoading={isLoadingTaskList}
                isFirstLoading={isFirstLoading}
                loadItems={loadPaginated}
                borderless={borderless}
                meta={tasks.meta}
                filtered={filtered || isLoadingTaskList}
                toolbar={toolbar}
                emptyView={
                    <EmptyView
                        title="task.emptyView.title"
                        icon={ICONS.TASKS}
                    />
                }
                data-cy={TASKS.TABLE}
                sortColumn="colId"
                sortDirection="DESC"
                columns={columns.filter(
                    (column) => !excludedColumns || !excludedColumns.includes(column.key)
                )}
                data={data}
            />
            <ComputeResourceTaskDialog handleClose={handleClose} isOpen={isOpen} />
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    tasks: state.task.list,
    isLoadingTaskList: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_TASK_LIST),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    taskActions: bindActionCreators(taskActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskTable);
