// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { FORM } from 'admin/plan/constants/tests';
import {
    FormFieldCheckbox,
    Section,
    Translate,
} from '@plesk/ui-library';
import { IFormProps } from 'common/components/Form/types';
import VZNetfilterStatusSegmentedControl from 'common/components/VZNetfilterStatusSegmentedControl';
import { VZNetfilterStatus } from 'common/api/resources/ComputeResourceVm';
import {
    LabelText,
    OneLineFormField,
    VZParametersSectionFormFieldContainer,
} from 'common/components/plan/components/Styles';
import VZTUNTAPSegmentedControl from 'common/components/VZTUNTAPSegmentedControl';
import VZPPPSegmentedControl from 'common/components/VZPPPSegmentedControl';

export interface IVZParametersSectionProps {
    disabled?: boolean;
    netfilter?: {
        value: VZNetfilterStatus;
        is_editable: boolean;
    };
    tunTAP?: {
        value: boolean;
        is_editable: boolean;
    };
    ppp?: {
        value: boolean;
        is_editable: boolean;
    };
}

const VZParametersSection: React.FC<IVZParametersSectionProps> = ({
    disabled = false,
    netfilter = {
        value: VZNetfilterStatus.STATELESS,
        is_editable: false,
    },
    tunTAP = {
        value: false,
        is_editable: false,
    },
    ppp = {
        value: false,
        is_editable: false,
    },
}) => (
    <Section
        title={<Translate content="plan.actionDialog.vzParameters.title"/>}
        collapsible={true}
        collapsed={true}
        data-cy={FORM.VZ_PARAMETERS_SECTION}
    >
        <VZParametersSectionFormField
            name="netfilter"
            label="Netfilter"
            value={netfilter}
            disabled={disabled}
            render={
                ({ setValue, getValue }: IFormProps<VZNetfilterStatus>) =>
                    <VZNetfilterStatusSegmentedControl value={getValue()} onChange={setValue} disabled={disabled} />
            }
            data-cy={FORM.NETFILTER}
        />
        <VZParametersSectionFormField
            name="tun_tap"
            label="TUN/TAP"
            value={tunTAP}
            disabled={disabled}
            render={
                ({ setValue, getValue }: IFormProps<boolean>) =>
                    <VZTUNTAPSegmentedControl value={getValue()} onChange={setValue} disabled={disabled} />
            }
            data-cy={FORM.TUN_TAP}
        />
        <VZParametersSectionFormField
            name="ppp"
            label="PPP"
            value={ppp}
            disabled={disabled}
            render={
                ({ setValue, getValue }: IFormProps<boolean>) =>
                    <VZPPPSegmentedControl value={getValue()} onChange={setValue} disabled={disabled} />
            }
            data-cy={FORM.PPP}
        />
    </Section>
);

interface IVZParametersSectionFormFieldProps<T> {
    name: string;
    label: React.ReactNode;
    value: {
        value: T;
        is_editable: boolean;
    };
    disabled: boolean;
    'data-cy'?: string;
    render: (props: IFormProps<T>) => React.ReactNode;
}

const VZParametersSectionFormField = <T,>({
    name,
    label,
    value,
    disabled,
    'data-cy': dataCy,
    render,
}: IVZParametersSectionFormFieldProps<T>) => (
        <VZParametersSectionFormFieldContainer data-cy={dataCy}>
            <OneLineFormField
                name={`${name}[value]`}
                value={value.value}
                label={<LabelText>{label}</LabelText>}
            >
                {(props: IFormProps<T>) => (
                    <>
                        {render(props)}
                    </>
                )}
            </OneLineFormField>
            <FormFieldCheckbox
                name={`${name}[is_editable]`}
                label={<Translate content="plan.actionDialog.vzParameters.isEditable" />}
                value={value.is_editable}
                disabled={disabled}
            />
        </VZParametersSectionFormFieldContainer>
    );

export default VZParametersSection;
