// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { connect } from 'react-redux';
import * as authActions from 'common/modules/auth/actions';
import * as menuActions from 'common/modules/app/menu/actions';
import { RootState } from 'admin/core/store';
import {
    Logo,
    NavItemsContainer,
    NavItemsGroupBottom,
} from 'admin/menu/containers/Menu/Styles';
import AppLogo from 'common/assets/solus.svg';
import { ADMIN_MENU } from 'admin/menu/constants/test';
import { ICONS } from 'common/constants';
import Navigation from 'admin/menu/components/Navigation/Navigation';
import Item from 'admin/menu/components/Navigation/Item';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import { Translate } from '@plesk/ui-library';
import { IMenuItem } from 'common/modules/app/menu/reducer';

export type MenuProps =
    RouteComponentProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const Menu: React.FC<MenuProps> = ({
    history: { push },
    user,
    menu,
    authActions: { logout },
    menuActions: { toggleGroup, setActiveItem },
    pathname,
}) => {
    React.useEffect(() => {
        setActiveItem(pathname);
    }, [pathname, setActiveItem]);

    return (
        <NavItemsContainer className={ADMIN_MENU} data-cy={ADMIN_MENU}>
            <Logo>
                <img src={AppLogo} alt=""/>
            </Logo>
            <Navigation
                user={user}
                items={menu}
                isOpened={true}
                onNavigate={push}
                onNestedToggle={toggleGroup}
            />
            <NavItemsGroupBottom>
                <Item
                    icon={ICONS.ARROW_RIGHT_IN}
                    onClick={logout}
                >
                    <Translate content="auth.logout"/>
                </Item>
            </NavItemsGroupBottom>
        </NavItemsContainer>
    );
};

const mapStateToProps = (state: RootState) => ({
    user: state.auth.user,
    menu: state.app.menu
        .filter((item: IMenuItem) => item.feature === undefined || state.app.features.has(item.feature))
        .map((item: IMenuItem) => ({
            ...item,
            subItems: item.subItems?.filter((subItem: IMenuItem) => subItem.feature === undefined || state.app.features.has(subItem.feature)),
        })),
});

const mapDispatchToProps = (dispatch: Dispatch, ownProps: RouteComponentProps) => ({
    pathname: ownProps.history.location.pathname,
    authActions: bindActionCreators(authActions, dispatch),
    menuActions: bindActionCreators(menuActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
