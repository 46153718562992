// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import {
    IIpBlockCreateRequest,
    IpBlockType,
    IpListType,
    IpListTypeTranslations,
} from 'common/api/resources/IpBlock';
import * as ipBlockActions from 'admin/ipBlock/actions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    intMinMaxRule,
    ipRule,
    rangeRule,
    requiredRule,
    validate,
} from 'common/validator';
import { ISelectOption } from 'common/components';
import {
    Form,
    FormField,
    FormFieldText,
    Section,
    setIn,
    Translate,
    StatusMessage,
    FormFieldCheckbox,
    Columns,
    Column,
} from '@plesk/ui-library';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { Button } from 'admin/common/components/Button/Button';
import {
    computeResources,
    IComputeResourceResponse,
    NetworkType,
} from 'common/api/resources/ComputeResource';
import {
    IVlanResponse,
    vlans,
} from 'common/api/resources/Vlan';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import {
    createChangeHandler,
    createOptionsLoader,
} from 'common/components/Select/helpers';
import { SegmentedControl } from 'common/components/SegmentedControl/SegmentedControl';
import { IFormProps } from 'common/components/Form/types';
import { SubInputs } from 'admin/common/styles/Styles';
import { IpDash } from 'admin/ipBlock/containers/Styles';
import CopyText from 'common/containers/CopyText/CopyText';
import { IVariable } from 'admin/common/components/SystemVariablesPopover/SystemVariablesPopover';
import { COLORS } from 'admin/core/theme';

interface IIpBlockFormProps {
    onSubmit: () => void;
}

export type IpBlockFormProps =
    IIpBlockFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const ipBlockListTypeOptions = Object.values(IpListType).map((type) => ({
    value: type.toString(),
    title: <Translate content={IpListTypeTranslations[type.toString()]}/>,
}));

const ipBlockTypeOptions = Object.values(IpBlockType).map((type) => ({
    value: type.toString(),
    title: type.toString(),
}));

const dnsList = {
    'Google': {
        name: 'Google',
        nameservers: {
            [IpBlockType.IPv4]: ['8.8.8.8', '8.8.4.4'],
            [IpBlockType.IPv6]: ['2001:4860:4860::8888', '2001:4860:4860::8844'],
        },
    },
    'OpenDNS': {
        name: 'OpenDNS',
        nameservers: {
            [IpBlockType.IPv4]: ['208.67.222.222', '208.67.220.220'],
            [IpBlockType.IPv6]: ['2620:119:35::35', '2620:119:53::53'],
        },
    },
};

const variables: IVariable[] = [{
    variable: 'first-octet',
    description: <Translate content="ipBlock.form.reverseDns.template.variables.firstOctet" />,
}, {
    variable: 'second-octet',
    description: <Translate content="ipBlock.form.reverseDns.template.variables.secondOctet" />,
}, {
    variable: 'third-octet',
    description: <Translate content="ipBlock.form.reverseDns.template.variables.thirdOctet" />,
}, {
    variable: 'fourth-octet',
    description: <Translate content="ipBlock.form.reverseDns.template.variables.fourthOctet" />,
}];

const dnsOptions = Object.values(dnsList).map((option) => ({
    value: option.name,
    title: option.name,
}));

const computeResourcesToSelectOptions = (cr: IComputeResourceResponse) => ({
    label: cr.name,
    value: cr.id.toString(),
    meta: { network_type: cr.settings.network.type, ipv6_available: cr.metrics.network.ipv6_available },
});

const vlanToSelectOptions = (vlan: IVlanResponse) => ({
    label: vlan.name,
    value: vlan.id.toString(),
});


export const IpBlockForm: React.FC<IpBlockFormProps> = ({
    errors: formErrors,
    isItemSaving,
    ipBlock,
    ipBlockActions: {
        updateIpBlock,
        createIpBlock,
        unsetIpBlockItem,
    },
    onSubmit,
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
}) => {
    const [selectedDns, setSelectedDns] = React.useState<string>('');
    const [submitValues, setSubmitValues] = React.useState<IIpBlockCreateRequest>({
        ...ipBlock,
        compute_resources: ipBlock.compute_resources.map(cr => cr.id.toString()),
        vlan_id: ipBlock.vlan?.id,
    });
    const [selectedVlan, setSelectedVlan] = React.useState<ISelectOption | undefined>(
        ipBlock.vlan ? vlanToSelectOptions(ipBlock.vlan) : undefined
    );
    const [selectedComputeResources, setSelectedComputeResources] = React.useState<ISelectOption[]>(
        ipBlock.compute_resources ? ipBlock.compute_resources.map(computeResourcesToSelectOptions) : []
    );
    const [crsWithoutIpv6Support, setCrsWithoutIpv6Support] = React.useState(false);

    const isNew = !Boolean(ipBlock.id);

    React.useEffect(() => {
        let defaultDNS = '';
        for (const dns in dnsList) {
            if (dnsList.hasOwnProperty(dns)) {
                const nameservers = dnsList[dns].nameservers[submitValues.type];
                if (submitValues.ns_1 === nameservers[0]
                    && submitValues.ns_2 === nameservers[1]) {
                    defaultDNS = dns;
                }
            }
        }

        setSelectedDns(defaultDNS);
        // eslint-disable-next-line
    }, [submitValues.ns_1, submitValues.ns_2]);

    React.useEffect(() => () => {
        clearFormErrors();
        unsetIpBlockItem();
        if (ipBlock.vlan) {
            setSelectedVlan(vlanToSelectOptions(ipBlock.vlan));
        } else {
            setSelectedVlan(undefined);
        }
    }, [clearFormErrors, unsetIpBlockItem, setSelectedVlan, ipBlock.vlan]);

    React.useEffect(() => {
        const result = selectedComputeResources.find(item => (
            item.meta && item.meta.network_type === NetworkType.ROUTED && !item.meta.ipv6_available
        ));

        setCrsWithoutIpv6Support(result !== undefined);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedComputeResources]);

    const handleSubmit = async (values: IIpBlockCreateRequest) => {
        const version = values.type === IpBlockType.IPv4 ? 4 : 6;

        let rules = {
            name: requiredRule(<Translate content="validate.fieldRequired" />),
            gateway: [
                requiredRule(<Translate content="validate.fieldRequired" />),
                ipRule(<Translate content="validate.badGateway" />, version),
            ],
            ns_1: [
                requiredRule(<Translate content="validate.fieldRequired" />),
                ipRule(<Translate content="validate.badDNS" />, version),
            ],
            ns_2: [
                requiredRule(<Translate content="validate.fieldRequired" />),
                ipRule(<Translate content="validate.badDNS" />, version),
            ],
        };

        if (values.list_type === IpListType.RANGE) {
            if (values.type === IpBlockType.IPv4) {
                rules['netmask'] = [
                    requiredRule(<Translate content="validate.fieldRequired" />),
                    ipRule(<Translate content="validate.badNetmask" />, version),
                ];
                rules['from'] = [
                    requiredRule(<Translate content="validate.fieldRequired" />),
                    ipRule(<Translate content="validate.badIpAddress" />, version),
                ];
                rules['to'] = [
                    requiredRule(<Translate content="validate.fieldRequired" />),
                    ipRule(<Translate content="validate.badIpAddress" />, version),
                ];
            } else if (values.type === IpBlockType.IPv6) {
                rules['range'] = [
                    requiredRule(<Translate content="validate.fieldRequired" />),
                    rangeRule(<Translate content="validate.badRange" />, version),
                ];
                rules['subnet'] = [
                    requiredRule(<Translate content="validate.fieldRequired" />),
                    intMinMaxRule(<Translate content="ipBlock.validate.subnet" />, 1, 128),
                ];
            }
        } else {
            if (values.type === IpBlockType.IPv6) {
                // Default IPv6 subnet for Set Ip block
                values.subnet = '128';
            }
        }

        const errors = validate<IIpBlockCreateRequest>(values, rules);

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        try {
            isNew ? await createIpBlock(values) : await updateIpBlock(ipBlock.id, values);
            onSubmit();
        } catch (e) {
            throw e;
        }
    };

    const handleComputeResourcesChange = createChangeHandler(
        setSubmitValues,
        setSelectedComputeResources,
        'compute_resources'
    );

    const loadComputeResourceOptions = createOptionsLoader(
        computeResources.list,
        computeResourcesToSelectOptions
    );

    const handleVlanChange = (option?: ISelectOption) => {
        setSubmitValues((values): IIpBlockCreateRequest => ({
            ...values,
            vlan_id: option?.value ? Number(option.value) : undefined,
        }));
        setSelectedVlan(option);
    };

    const loadVlanOptions = createOptionsLoader(
        vlans.list,
        vlanToSelectOptions
    );

    const handleDnsChange = (option: string) => {
        const dns = dnsList[option].nameservers[submitValues.type];

        setSubmitValues((values) => ({
            ...values,
            ns_1: dns[0],
            ns_2: dns[1],
        }));
    };

    const onFieldChange = (key: string, value: string) => setSubmitValues(setIn(submitValues, key, value));

    return (
        <>
            <Form
                id="ipBlockForm"
                footerClassName="hidden"
                onSubmit={handleSubmit}
                onFieldChange={onFieldChange}
                values={submitValues}
                errors={formErrors}
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <Section>
                    <FormFieldText
                        size={SIZE.FILL}
                        name="name"
                        label={<Translate content="ipBlock.form.ipBlockName" />}
                        required={true}
                    />
                    {isNew && (
                        <>
                            <FormField
                                name="list_type"
                                required={true}
                                label={<Translate content="ipBlock.form.listType" />}
                            >
                                {({ setValue }: IFormProps<string>) => (
                                    <SegmentedControl
                                        buttons={ipBlockListTypeOptions}
                                        selected={submitValues.list_type}
                                        onChange={setValue}
                                    />
                                )}
                            </FormField>
                            <FormField
                                name="type"
                                required={true}
                                label={<Translate content="ipBlock.form.type" />}
                            >
                                {({ setValue }: IFormProps<string>) => (
                                    <SegmentedControl
                                        buttons={ipBlockTypeOptions}
                                        selected={submitValues.type}
                                        onChange={setValue}
                                    />
                                )}
                            </FormField>
                        </>
                    )}
                    {submitValues.type === IpBlockType.IPv4 ? (
                        (isNew || submitValues.list_type === IpListType.RANGE) && (
                            <Columns vertical={false}>
                                <Column>
                                    <FormFieldText
                                        name="from"
                                        label={<Translate content="ipBlock.form.from" />}
                                        required={true}
                                        disabled={submitValues.list_type === IpListType.SET}
                                        vertical
                                    />
                                </Column>
                                <Column width={24}>
                                    <IpDash>&mdash;</IpDash>
                                </Column>
                                <Column>
                                    <FormFieldText
                                        name="to"
                                        label={<Translate content="ipBlock.form.to" />}
                                        required={true}
                                        disabled={submitValues.list_type === IpListType.SET}
                                        vertical
                                    />
                                </Column>
                            </Columns>
                        )
                    ) : (
                        (isNew || submitValues.list_type === IpListType.RANGE) && (
                            <Columns gap={SIZE.LG} vertical={false}>
                                <Column fill={true}>
                                    <FormFieldText
                                        size={SIZE.FILL}
                                        name="range"
                                        placeholder={submitValues.list_type === IpListType.RANGE
                                            ? '2001:abcd:1234:5678::/64'
                                            : ''
                                        }
                                        label={<Translate content="ipBlock.form.range" />}
                                        required={true}
                                        disabled={submitValues.list_type === IpListType.SET}
                                        vertical
                                    />
                                </Column>
                                <Column width={74}>
                                    <FormFieldText
                                        size={SIZE.SM}
                                        name="subnet"
                                        label={<Translate content="ipBlock.form.subnet" />}
                                        required={true}
                                        placeholder="128"
                                        disabled={submitValues.list_type === IpListType.SET}
                                        vertical
                                    />
                                </Column>
                            </Columns>
                        )
                    )}
                    {submitValues.type === IpBlockType.IPv4 ? (
                        <Columns gap={SIZE.LG} vertical={false}>
                            <Column>
                                <FormFieldText
                                    name="gateway"
                                    label={<Translate content="ipBlock.form.gateway" />}
                                    required={true}
                                    vertical
                                />
                            </Column>
                            <Column>
                                <FormFieldText
                                    name="netmask"
                                    placeholder="255.255.0.0"
                                    label={<Translate content="ipBlock.form.netmask" />}
                                    required={true}
                                    vertical
                                />
                            </Column>
                        </Columns>
                    ) : (
                        <FormFieldText
                            size={SIZE.FILL}
                            name="gateway"
                            placeholder="fe80::1"
                            label={<Translate content="ipBlock.form.gateway" />}
                            required={true}
                        />
                    )}
                    <Columns gap={SIZE.LG} vertical={false}>
                        <Column>
                            <FormFieldText
                                name="ns_1"
                                label={<Translate content="ipBlock.form.ns_1" />}
                                required={true}
                                vertical
                            />
                        </Column>
                        <Column>
                            <FormFieldText
                                name="ns_2"
                                label={<Translate content="ipBlock.form.ns_2" />}
                                required={true}
                                vertical
                            />
                        </Column>
                    </Columns>
                    <FormField style={{ marginTop: '-8px' }}>
                        <SegmentedControl
                            buttons={dnsOptions}
                            selected={selectedDns}
                            onChange={handleDnsChange}
                        />
                    </FormField>
                    <FormField
                        name="vlan_id"
                        label={<Translate content="ipBlock.form.vlan" />}
                    >
                        {({ getId }: IFormProps<unknown>) => (
                            <AsyncSelectInput
                                inputId={getId()}
                                value={selectedVlan}
                                placeholder={<Translate content="ipBlock.form.vlanEmpty" />}
                                loadOptions={loadVlanOptions}
                                onChange={handleVlanChange}
                                debounceTimeout={1000}
                                additional={{ page: 1 }}
                                menuPosition="fixed"
                                isClearable={true}
                            />
                        )}
                    </FormField>
                    <FormFieldCheckbox
                        name="reverse_dns[enabled]"
                        label={
                            <Translate content="ipBlock.form.reverseDns.enabled"/>
                        }
                    />
                    <SubInputs>
                        <FormFieldText
                            disabled={!submitValues.reverse_dns.enabled}
                            name="reverse_dns[zone]"
                            size={SIZE.FILL}
                            placeholder={submitValues.type === IpBlockType.IPv4 ? 'in-addr.arpa' : 'ip6.arpa'}
                            label={(
                                <Translate content="ipBlock.form.reverseDns.zone"/>
                            )}
                            description={(
                                <Translate content="ipBlock.form.reverseDns.zoneDescription"/>
                            )}
                        />
                        <FormFieldText
                            disabled={submitValues.type === IpBlockType.IPv6 || !submitValues.reverse_dns.enabled }
                            name="reverse_dns[template]"
                            size={SIZE.FILL}
                            label={(
                                <Translate content="ipBlock.form.reverseDns.template.label"/>
                            )}
                            description={(
                                <Translate content="ipBlock.form.reverseDns.template.description"/>
                            )}
                            fullDescription={(
                                <>
                                    <h3>
                                        <Translate content="ipBlock.form.reverseDns.template.header"/>
                                    </h3>
                                    {variables.map((variable, key) => (
                                        <p key={key}>
                                            <b style={{ color: COLORS.PRIMARY }}>
                                                <div style={{ float: 'left' }}>
                                                    <CopyText>{`{{ ${variable.variable} }}`}</CopyText>
                                                </div>
                                            </b>&nbsp;— {variable.description}
                                        </p>
                                    ))}
                                </>
                            )}
                        />
                    </SubInputs>
                    <FormField
                        name="compute_resources"
                        label={<Translate content="ipBlock.form.computeResources" />}
                    >
                        {({ getId }: IFormProps<unknown>) => (
                            <AsyncSelectInput
                                inputId={getId()}
                                value={selectedComputeResources}
                                isMulti={true}
                                loadOptions={loadComputeResourceOptions}
                                onChange={handleComputeResourcesChange}
                                debounceTimeout={1000}
                                additional={{ page: 1 }}
                                menuPosition="fixed"
                            />
                        )}
                    </FormField>
                    {submitValues.type === IpBlockType.IPv6 && crsWithoutIpv6Support &&
                        <StatusMessage intent="warning">
                            <Translate content="ipBlock.form.ipv6NotAvailable" />
                        </StatusMessage>
                    }
                </Section>
            </Form>
            <Button
                type="submit"
                form="ipBlockForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size={SIZE.LG}
                isLoading={isItemSaving}
            >
                <Translate content="ipBlock.form.saveBtn" />
            </Button>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    ipBlock: state.ipBlock.item,
    isItemSaving: state.app.loadingFlags.has(LOADING_FLAGS.SAVE_IPBLOCK_ITEM),
    errors: nestStringProperties(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    ipBlockActions: bindActionCreators(ipBlockActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(IpBlockForm);
