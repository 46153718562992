// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

export default {
    title: 'solus.io',
    menu: {
        projects: 'Projects',
        account: 'Account',
        isoImages: 'ISO images',
        vpcNetworks: 'VPC Networks',
        documentation: 'API',
    },
    networkError: 'Service is on maintenance',
    gatewayTimeout: 'The server didn\'t respond in time. Please try again.',
    license: {
        topInfoPanel: {
            description: 'This installation is running unlicensed and is not authorized to be used in production.',
        },
    },
    projects: {
        statusUser: {
            active: 'Active',
            locked: 'Locked',
            suspended: 'Suspended',
            invited: 'Invited',
        },
        statusUserTooltip: {
            locked: 'Your account has been locked. This feature is not available.',
            suspended: 'Your account has been suspended. This feature is not available.',
        },
        title: 'Projects',
        accessDenied: 'You don\'t have an access to manage the projects',
        internalError: 'Service temporary unavailable',
        emailVerified: 'Congratulations, your account is now verified!',
        addProject: 'Create Project',
        editProject: 'Edit Project',
        confirmation: {
            text: 'Do you really want to remove the project?',
            button: 'Remove',
        },
        list: {
            colName: 'Name',
            colDescription: 'Description',
            colOwner: 'Owner',
            colServers: 'Servers',
            colMembers: 'Members',
        },
        form: {
            name: 'Name',
            description: 'Description',
            save: 'Save',
            createServer: 'Create Server',
            serverList: '%%count%% servers',
            membersList: '%%count%% members',
            cancel: 'Cancel',
            deleteConfirmationText: 'Do you really want to delete this project?',
            leaveConfirmationText: 'Do you really want to leave this project?',
            defaultProjectRemovalTooltip: 'Default project can\'t be deleted.',
            deleteTitle: 'Delete Project',
            deleteButton: 'Yes, delete',
            deleteTooltip: 'Delete',
            leaveButton: 'Leave',
        },
        tabs: {
            members: 'Members',
            servers: 'Servers',
            list: 'List',
        },
        members: {
            owner: 'Owner',
            user: 'User',
            active: 'Active',
            invited: 'Invited',
            addNewMember: 'Add new member',
            inviteMember: 'Invite Member',
            invite: 'Invite Member',
            resendInvite: 'Resend Invite',
            timeoutText: 'Next resend in ',
            list: {
                colMail: 'Mail',
                colStatus: 'Status',
                colRole: 'Role',
            },
            confirmationLeave: {
                text: 'Do you really want to leave the project?',
                button: 'Leave',
            },
            confirmationRemove: {
                text: 'Do you really want to remove this member?',
                button: 'Remove',
            },
        },
        server: {
            limitsExceed: {
                incoming: 'Incoming traffic',
                outgoing: 'Outgoing traffic',
                total: 'Total traffic',
            },
            description: '%%vcpu%% vCPU, %%ram%% MiB RAM, %%disk%% GiB Disk',
            tooltipForImported: 'This action is not supported yet for the imported server.',
            package: {
                title: 'Package',
                vcpu: 'vCPU',
                ram: 'RAM',
                disk: 'Disk',
                perMonth: 'per month',
                perHour: ' per hour',
            },
            overviewTab: {
                incoming: 'Incoming',
                outgoing: 'Outgoing',
            },
            backupTab: {
                title: 'Backup',
            },
            ipv4: 'IPv4',
            shutdown: 'Stop',
            confirmStop: 'Yes, stop!',
            stopTitle: 'Stop Server',
            shutdownConfirmation: 'Would you like to continue?',
            restart: 'Restart',
            restartTitle: 'Restart Server',
            confirmRestart: 'Yes, restart!',
            restartConfirmation: 'Would you like to continue?',
            powerOff: 'Power Off',
            powerOffTitle: 'Power Off Server',
            confirmPowerOff: 'Yes, Power Off!',
            powerOffConfirmation: `Powering Off a server removes the power instantly and could potentially cause data loss.
                Would you like to continue?
            `,
            remove: 'Delete',
            removeTitle: 'Delete Server',
            confirmRemove: 'Yes, delete!',
            removeServerText: 'Delete the %%name%% server? This will also delete its backups and snapshots.',
            removeServerLabel: 'Enter the server name to confirm its deletion',
            removeServersText: 'Delete %%count%% server(s)? This will also delete their backups and snapshots.',
            removeServersLabel: 'Type \'delete\' to confirm deletion of selected servers',
            tabs: {
                settings: 'Settings',
                overview: 'Overview',
                graphs: 'Graphs',
                snapshots: 'Snapshots',
                rescue: 'Boot & Rescue',
                backup: 'Backups',
                reinstall: 'Reinstall',
                networking: 'Networking',
                resize: 'Resize',
                disks: 'Disks',
            },
            editServer: 'Edit server',
            create: {
                accessDenied: 'Access denied',
                title: 'Create Server',
                serverName: 'Hostname',
                serverNameDescription: 'The hostname will change the next time the server is restarted.',
                serverDescription: 'Description',
                ipBlockTypes: 'IP address',
                password: 'Operation System Password',
                createButton: 'Create',
                createAndBuyButton: 'Create & Buy now',
                ssh: {
                    generate: 'Generate a new key pair',
                    tooltip: 'Your key pair will be saved and downloaded.',
                    addKey: 'Add SSH Key',
                    createBtn: 'Add SSH Key',
                    sectionTitle: 'SSH Keys',
                    addBtn: 'Add SSH Key',
                    showAll: 'Show all',
                },
                userData: {
                    sectionTitle: 'User Data',
                    drawerTitle: 'Set User Data',
                    title: 'User Data',
                    setBtn: 'Set',
                    clearBtn: 'Clear',
                    sampleBtn: 'Sample',
                },
            },
            form: {
                confirmationText: 'Delete the server? This will also delete its backups and snapshots.',
                deleteButton: 'Delete',
            },
            links: {
                vnc: 'Open VNC dialog',
                reboot: 'Restart',
                shutdown: 'Stop',
                destroy: 'Destroy',
                edit: 'Edit',
                start: 'Start',
                stop: 'Stop',
                tools: 'Tools',
                login: 'Log in to application',
            },
            list: {
                createServer: 'Create Server',
                colName: 'Hostname',
                colDescription: 'Description',
                colStatus: 'Status',
                colIp: 'IP address',
                colLocation: 'Location',
            },
            emptyView: {
                title: 'You don\'t have any servers in this project yet.',
                description: 'Go ahead and create your first Server now - it only takes a few seconds.',
                buttonText: 'Create Your First Server',
            },
            backups: {
                createBtn: {
                    title: 'Create Backup',
                    text: 'The new manual backup task will be added to the queue and will be processed as long as the backup node is ready to handle the backup.',
                    button: 'Create',
                    tooltip: 'Create Backup',
                    disabledInRescueModeTooltip: 'You can\'t back up a server in Rescue Mode.',
                },
            },
        },
    },
    servers: {
        form: {
            confirmationText: 'Delete the server? This will also delete its backups and snapshots.',
            deleteButton: 'Delete',
        },
    },
    account: {
        topInfoPanel:  {
            lockedOrSuspended: 'Your account has been %%status%%. Some features are not available.',
        },
        title: 'Account',
        demo: {
            title: 'Congratulations, you have successfully registered.',
            content: 'We have sent an email to %%email%% with a link to confirm your account. This link is valid for 24 hours. After confirmation, you will be provided full access!',
        },
        tabs: {
            settings: 'Settings',
            apiTokens: 'API Tokens',
            sshKeys: 'SSH keys',
            twoFactorAuth: 'Two-factor authentication',
        },
        settings: {
            emailLabel: 'Email',
            passwordLabel: 'Change Password',
            confirmPasswordLabel: 'Confirm Password',
            currentPasswordLabel: 'Current Password',
            languageLabel: 'Interface Language',
            passwordDescription: 'At least 8 characters, 1 upper case and 1 number',
            submit: 'Apply Settings',
            passwordRules: {
                length: '8 characters',
                UCLetters: '1 uppercase letter',
                numbers: '1 number',
            },
        },
        sshKeys: {
            add: 'Add',
            addNewSshKey: 'Add SSH Key',
            confirmationRemove: {
                text: 'Do you really want to remove this SSH Key?',
                button: 'Remove',
            },
            list: {
                colName: 'Name',
                colContent: 'Content',
            },
            emptyView: {
                title: 'You don\'t have any SSH keys yet.',
            },
        },
        tokens: {
            add: 'Generate',
            copyAndClose: 'Copy & Close',
            addNewToken: 'Generate API Token',
            addNewTokenText: 'To access the API you need a token which acts like a combined username and password. All API tokens have the same read/write access as your account.',
            tokenAdded: 'API Token was added',
            tokenAddedText: 'Copy and securely store your API token. It won\'t be shown again for security reasons.',
            name: 'Name',
            confirmationRemove: {
                text: 'Do you really want to remove this API Token?',
                button: 'Remove',
            },
            list: {
                colName: 'Name',
            },
            emptyView: {
                title: 'You don\'t have any API tokens yet.',
            },
        },
    },
    support: {
        apiDocumentationUrl: 'documentation link',
        apiDocSection: 'API integration',
        apiDocumentationDescription: 'You can integrate with the system via API http methods.',
    },
    plan: {
        price: {
            total: 'Price %%price%%/mo',
            perMonth: '%%price%%/mo',
            perHour: '%%price%%/h',
            server: 'Server',
            primaryDisk: 'Primary Disk',
            additionalDisks: 'Additional Disks',
            backup: 'Backups',
            additionalIps: 'Additional IPs',
            taxesInclusive: 'All prices incl. %%taxes%%',
            taxesExclusive: 'All prices excl. %%taxes%%',
            termsAndConditions: {
                text: 'Our %%link%% apply',
                link: 'terms and conditions',
            },
        },
    },
    computeResource: {
        servers: {
            toasts: {
                serverUpdated: 'Server successfully updated',
            },
            confirmationPopover: {
                cancel: 'Cancel',
                notConfirmed: 'Strings do not match.',
            },
        },
    },
    icon: {
        selectIcon: 'Select icon',
    },
};
