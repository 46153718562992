// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { OS_TYPES } from 'common/constants';
import {
    BootMode,
    IIpAddresses,
    IIpV4,
    IIpV6Range,
} from 'common/api/resources/ComputeResourceVm';
import {
    Action,
    List,
    Translate,
} from '@plesk/ui-library';
import { Paragraph } from 'common/components/ServerTabs/RescueTab/Styles';
import { ConfirmationDescription } from 'common/components/ConfirmationDialog/Styles';
import CopyText from 'common/containers/CopyText/CopyText';
import { IpIssuedFor } from 'common/api/resources/Ip';

export interface IIsoImageConfirmationProps {
    os?: OS_TYPES;
    serverBootMode: BootMode;
    selectedBootMode: BootMode;
    ips: IIpAddresses;
}

enum TableColumns {
    IP = 'colIp',
    NETMASK = 'colNetmask',
    GATEWAY = 'colGateway',
    PRIMARY_NAME_SERVER = 'colPrimaryNameServer',
    SECONDARY_NAME_SERVER = 'colSecondaryNameServer',
}

const columns = [{
    key: TableColumns.IP,
    title: <Translate content="servers.tabs.rescue.rescue.isoImageDialog.confirmation.ipList.ip" />,
}, {
    key: TableColumns.NETMASK,
    title: <Translate content="servers.tabs.rescue.rescue.isoImageDialog.confirmation.ipList.netmask" />,
}, {
    key: TableColumns.GATEWAY,
    title: <Translate content="servers.tabs.rescue.rescue.isoImageDialog.confirmation.ipList.gateway" />,
}, {
    key: TableColumns.PRIMARY_NAME_SERVER,
    title: <Translate content="servers.tabs.rescue.rescue.isoImageDialog.confirmation.ipList.ns_1" />,
}, {
    key: TableColumns.SECONDARY_NAME_SERVER,
    title: <Translate content="servers.tabs.rescue.rescue.isoImageDialog.confirmation.ipList.ns_2" />,
}];

const link = 'https://cloudbase.it/downloads/CloudbaseInitSetup_Stable_x64.msi';

export const IsoImageConfirmation: React.FC<IIsoImageConfirmationProps> = ({
    os,
    serverBootMode,
    selectedBootMode,
    ips,
}) => {
    const getListData = () => {
        const listDataIpv4 = ips.ipv4
            .filter((item: IIpV4) => item.issued_for === IpIssuedFor.VM)
            .map((item: IIpV4) => ({
                [TableColumns.IP]: <CopyText width='140px'>{item.ip}</CopyText>,
                [TableColumns.NETMASK]: <CopyText width='140px'>{item.netmask}</CopyText>,
                [TableColumns.GATEWAY]: <CopyText width='140px'>{item.gateway}</CopyText>,
                [TableColumns.PRIMARY_NAME_SERVER]: <CopyText width='140px'>{item.ns_1}</CopyText>,
                [TableColumns.SECONDARY_NAME_SERVER]: <CopyText width='140px'>{item.ns_2}</CopyText>,
                key: item.id.toString(),
            }));

        const listDataIpv6 = ips.ipv6
            .filter((item: IIpV6Range) => item.issued_for === IpIssuedFor.VM)
            .map((item: IIpV6Range) => ({
                [TableColumns.IP]: <CopyText width='140px'>{item.range}</CopyText>,
                [TableColumns.GATEWAY]: <CopyText width='140px'>{item.gateway}</CopyText>,
                [TableColumns.PRIMARY_NAME_SERVER]: <CopyText width='140px'>{item.ns_1}</CopyText>,
                [TableColumns.SECONDARY_NAME_SERVER]: <CopyText width='140px'>{item.ns_2}</CopyText>,
                key: item.id.toString(),
            }));

        return [...listDataIpv4, ...listDataIpv6];
    };

    return (
        <>
            {selectedBootMode === BootMode.DISK && serverBootMode === BootMode.ISO_IMAGE && os === OS_TYPES.LINUX && (
                <>
                    <Translate content="servers.tabs.rescue.rescue.isoImageDialog.confirmation.linuxGuestTools.header" />
                    <Paragraph>
                        <ConfirmationDescription>
                            Debian/Ubuntu:
                        </ConfirmationDescription>
                        <CopyText>
                            <ConfirmationDescription>
                                apt-get install -y --no-install-recommends qemu-guest-agent cloud-init cloud-guest-utils tuned
                            </ConfirmationDescription>
                            <ConfirmationDescription>
                                systemctl enable qemu-guest-agent --now
                            </ConfirmationDescription>
                            <ConfirmationDescription>
                                systemctl enable cloud-init.service
                            </ConfirmationDescription>
                            <ConfirmationDescription>
                                systemctl enable fstrim.timer
                            </ConfirmationDescription>
                            <ConfirmationDescription>
                                systemctl enable tuned --now
                            </ConfirmationDescription>
                            <ConfirmationDescription>
                                tuned-adm profile virtual-guest
                            </ConfirmationDescription>
                        </CopyText>
                    </Paragraph>
                    <Paragraph>
                        CentOS:
                        <CopyText>
                            <ConfirmationDescription>
                                dnf install -y qemu-guest-agent cloud-init cloud-utils-growpart
                            </ConfirmationDescription>
                            <ConfirmationDescription>
                                systemctl enable qemu-guest-agent --now
                            </ConfirmationDescription>
                            <ConfirmationDescription>
                                systemctl enable cloud-init.service
                            </ConfirmationDescription>
                            <ConfirmationDescription>
                                systemctl enable fstrim.timer
                            </ConfirmationDescription>
                        </CopyText>
                    </Paragraph>
                    <Paragraph>
                        Alpine:
                        <CopyText>
                            <ConfirmationDescription>
                                apk add --no-cache qemu-guest-agent cloud-init cloud-utils
                            </ConfirmationDescription>
                            <ConfirmationDescription>
                                systemctl enable qemu-guest-agent --now
                            </ConfirmationDescription>
                            <ConfirmationDescription>
                                systemctl enable cloud-init.service
                            </ConfirmationDescription>
                            <ConfirmationDescription>
                                systemctl enable fstrim.timer
                            </ConfirmationDescription>
                        </CopyText>
                    </Paragraph>
                    <Translate content="servers.tabs.rescue.rescue.isoImageDialog.confirmation.linuxGuestTools.footer" />
                </>
            )}
            {selectedBootMode === BootMode.DISK && serverBootMode === BootMode.ISO_IMAGE && os === OS_TYPES.WINDOWS && (
                <Translate content="servers.tabs.rescue.rescue.isoImageDialog.confirmation.windowsGuestTools" params={{
                    link: <Action target="_blank" href={link}>{link}</Action>,
                }}/>
            )}
            {selectedBootMode === BootMode.ISO_IMAGE && (
                <>
                    <Translate content="servers.tabs.rescue.rescue.isoImageDialog.confirmation.network" />
                    <List
                        emptyView={null}
                        columns={columns}
                        data={getListData()}
                    />
                    <Translate content="servers.tabs.rescue.rescue.isoImageDialog.confirmation.networkFooter" />
                </>
            )}
        </>
    );
};
