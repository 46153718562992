// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { VIEW_TYPE } from 'common/constants';
import { StyledSegmentedControl } from 'common/components/styles/SegmentedControl';
import {
    SegmentedControl,
    Translate,
} from '@plesk/ui-library';

export interface IViewSelectProps {
    selected: VIEW_TYPE;
    onChange: (value: string) => void;
}

const ViewSelect: React.FunctionComponent<IViewSelectProps> = (props) => (
    <StyledSegmentedControl>
        <SegmentedControl
            buttons={[
                {
                    value: VIEW_TYPE.LIST,
                    icon: 'list',
                    'data-cy': VIEW_TYPE.LIST as string,
                    tooltip: <Translate content="tooltips.listView" />,
                },
                {
                    value: VIEW_TYPE.CARD,
                    icon: 'four-squares',
                    'data-cy': VIEW_TYPE.CARD as string,
                    tooltip: <Translate content="tooltips.cardView" />,
                },
            ]}
            {...props}
        />
    </StyledSegmentedControl>
);

export default ViewSelect;
