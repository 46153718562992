// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Icon,
    Tooltip,
    Translate,
} from '@plesk/ui-library';
import { CutTitle } from 'common/components';
import {
    ICONS,
    INTENT_TYPE,
} from 'common/constants';
import {
    ContentName,
    DescriptionText,
    ItemContent,
    ItemParamLabel,
    ItemParams,
    ItemParamValue,
    LogoContainer,
    OfferItem,
} from 'common/modules/computeResourceVm/components/Styles';
import Action from 'common/components/Action/Action';
import { IOfferResponse } from 'common/api/resources/Offer';
import { IProjectTokenPricingResponse } from 'common/api/resources/Project';
import { IPlanResponse } from 'common/api/resources/Plan';
import { OfferPrice } from 'common/modules/computeResourceVm/components/OfferPrice';
import { dataCySelector } from 'common/tests/selectors';
import { REMOVE_DISK } from 'common/modules/offer/constants/tests';

export type IAdditionalDiskCardProps = {
    plan?: IPlanResponse;
    offer: IOfferResponse;
    tokenPricing?: IProjectTokenPricingResponse;
    diskName: string;
    sizeGib: number;
    icon: ICONS;
    isSelected: boolean;
    onItemClicked?: () => void;
    onRemoveClicked?: () => void;
    disabled?: boolean;
    showInternalTooltips?: boolean;
    'data-cy'?: string;
} & React.HTMLProps<HTMLDivElement>;

export const AdditionalDiskCard: React.FC<IAdditionalDiskCardProps> = ({
    plan,
    tokenPricing,
    offer,
    diskName,
    sizeGib,
    icon,
    isSelected,
    onItemClicked,
    onRemoveClicked,
    disabled,
    showInternalTooltips= true,
    'data-cy': dataCy,
}) => {
    const renderIcon = () => {
        if (isSelected) {
            return <Icon name={icon} />;
        }

        return (
            <Tooltip title={<Translate content="servers.additionalDisk.notice.incompatibleOffer" />}>
                <Icon
                    intent={INTENT_TYPE.DANGER}
                    name={ICONS.TRIANGLE_EXCLAMATION_MARK}
                />
            </Tooltip>
        );
    };

    const handleClick = () => {
        if (!disabled && onItemClicked) {
            onItemClicked();
        }
    };

    return (
        <OfferItem isSelected={isSelected} onClick={handleClick} isDisabled={disabled} data-cy={dataCy}>
            <div>
                <LogoContainer>
                    {renderIcon()}
                </LogoContainer>
            </div>
            <ItemContent>
                <ContentName>
                    <CutTitle title={offer.name} withTooltip={showInternalTooltips} />
                </ContentName>
                {offer.description && (
                    <DescriptionText>
                        <CutTitle title={offer.description} withTooltip={showInternalTooltips}/>
                    </DescriptionText>
                )}
                <ItemParams>
                    <ItemParamLabel>
                        <Translate content="servers.offer.card.diskNameLabel" />
                    </ItemParamLabel>
                    <ItemParamValue>
                        <CutTitle title={diskName} withTooltip={showInternalTooltips} />
                    </ItemParamValue>

                    <ItemParamLabel>
                        <Translate content="servers.offer.card.diskSizeLabel" />
                    </ItemParamLabel>
                    <ItemParamValue>
                        <Translate content="servers.offer.card.diskSizeValue" params={{ size: sizeGib }} />
                    </ItemParamValue>

                    {tokenPricing && (
                        <OfferPrice
                            plan={plan}
                            tokenPricing={tokenPricing}
                            offer={offer}
                            sizeGib={sizeGib}
                        />
                    )}
                </ItemParams>
            </ItemContent>
            {onRemoveClicked && (
                <Action
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                        e.stopPropagation();
                        onRemoveClicked();
                    }}
                    icon={
                        <Icon name={ICONS.CROSS_MARK} size={'18'} className="pul-form-field__full-description"/>
                    }
                    data-cy={dataCySelector(offer.id, REMOVE_DISK)}
                />
            )}
        </OfferItem>
    );
};
