// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    ComputeResourceVmStatus,
    IAdditionalDiskRequest,
    IAdditionalDiskResizeRequest,
    IAdditionalDiskUpdateRequest,
    IVmDiskEvent,
    IVmDiskRemovedEvent,
    IVmDiskResponse,
    IVmResponse,
} from 'common/api/resources/ComputeResourceVm';
import {
    Grid,
    Icon,
    Item,
    Translate,
} from '@plesk/ui-library';
import { GridCol } from 'common/components/ServerTabs/NetworkingTab/GridCol';
import { NetworkingHeader } from 'common/components/ServerTabs/NetworkingTab/Styles';
import { getActionColumnProps } from 'common/helpers/list';
import { DefaultTemplateTableColumns } from 'admin/defaultTemplate/containers/DefaultTemplates';
import { DisksActions } from 'common/components/ServerTabs/DisksTab/DisksActions';
import List from 'common/components/List/List';
import {
    ICONS,
    INTENT_TYPE,
    SIZE,
    SOCKET_CHANNELS,
    SOCKET_EVENTS,
} from 'common/constants';
import { Button } from 'admin/common/components/Button/Button';
import { Dialog } from 'common/components/Dialog/Dialog';
import AdditionalDiskForm,
{ FIELDS } from 'common/modules/computeResourceVm/containers/AdditionalDiskForm';
import { initEchoConnection } from 'common/services/EchoService';
import { IProjectTokenPricingResponse } from 'common/api/resources/Project';
import { canAddOrDeleteAdditionalDisk } from 'common/services/ServerCapabilities';
import { Loader } from 'common/components/Loader/Loader';

export interface IDisksTabProps {
    columns: DisksTableColumns[];
    server: IVmResponse;
    disks: IVmDiskResponse[];
    isLoadingList: boolean;
    echoCredentials: string;
    isAdditionalDiskAdding: boolean;
    isAdditionalDiskDeleting: boolean;
    isAdditionalDiskResizing: boolean;
    isAdditionalDiskUpdating: boolean;
    tokenPricing?: IProjectTokenPricingResponse;
    canManageStorage: boolean;
    canAddAdditionalDisk: boolean;
    canResizeAdditionalDisk: boolean;
    canDeleteAdditionalDisk: boolean;
    getDisks: (id: number) => void;
    addAdditionalDisk: (id: number, serverStatus: ComputeResourceVmStatus, data: IAdditionalDiskRequest) => void;
    updateAdditionalDisk: (serverId: number, diskId: number, data: IAdditionalDiskUpdateRequest) => void;
    resizeAdditionalDisk: (serverId: number, serverStatus: ComputeResourceVmStatus, diskId: number, data: IAdditionalDiskResizeRequest) => void;
    removeAdditionalDisk: (serverId: number, serverStatus: ComputeResourceVmStatus, diskId: number) => void;
    commitAdditionalDiskCreatingSuccess: (data: IVmDiskResponse) => void;
    commitAdditionalDiskCreatingFail: () => void;
    commitAdditionalDiskDeletingSuccess: (diskId: number) => void;
    commitAdditionalDiskDeletingFail: (diskId: number) => void;
    commitAdditionalDiskResizingSuccess: (data: IVmDiskResponse) => void;
    commitAdditionalDiskResizingFail: () => void;
}

export enum DisksTableColumns {
    ID = 'colId',
    NAME = 'colName',
    PATH = 'colPath',
    // eslint-disable-next-line @typescript-eslint/no-shadow
    SIZE = 'colSize',
    ACTUAL_SIZE = 'colActualSize',
    IS_PRIMARY = 'colIsPrimary',
    ACTIONS = 'colActions',
}

const columnDefinitions = {
    [DisksTableColumns.ID]: {
        width: '1%',
        key: DisksTableColumns.ID,
        title: <Translate content="servers.tabs.disks.list.id" />,
    },
    [DisksTableColumns.NAME]: {
        width: '15%',
        key: DisksTableColumns.NAME,
        title: <Translate content="servers.tabs.disks.list.name" />,
    },
    [DisksTableColumns.IS_PRIMARY]: {
        width: '1%',
        key: DisksTableColumns.IS_PRIMARY,
        title: <Translate content="servers.tabs.disks.list.isPrimary" />,
    },
    [DisksTableColumns.PATH]: {
        width: '15%',
        key: DisksTableColumns.PATH,
        title: <Translate content="servers.tabs.disks.list.path" />,
    },
    [DisksTableColumns.SIZE]: {
        width: '1%',
        key: DisksTableColumns.SIZE,
        title: <Translate content="servers.tabs.disks.list.size" />,
    },
    [DisksTableColumns.ACTUAL_SIZE]: {
        width: '1%',
        key: DisksTableColumns.ACTUAL_SIZE,
        title: <Translate content="servers.tabs.disks.list.actualSize" />,
    },
    [DisksTableColumns.ACTIONS]: getActionColumnProps(),
};

export enum DiskFormType {
    ADD,
    EDIT,
    RESIZE,
}

export const DisksTab: React.FC<IDisksTabProps> = ({
    server,
    columns,
    disks,
    isLoadingList,
    isAdditionalDiskAdding,
    isAdditionalDiskDeleting,
    isAdditionalDiskResizing,
    isAdditionalDiskUpdating,
    canManageStorage,
    canAddAdditionalDisk,
    canResizeAdditionalDisk,
    canDeleteAdditionalDisk,
    getDisks,
    echoCredentials,
    tokenPricing,
    addAdditionalDisk,
    updateAdditionalDisk,
    resizeAdditionalDisk,
    removeAdditionalDisk,
    commitAdditionalDiskCreatingSuccess,
    commitAdditionalDiskCreatingFail,
    commitAdditionalDiskDeletingSuccess,
    commitAdditionalDiskDeletingFail,
    commitAdditionalDiskResizingSuccess,
    commitAdditionalDiskResizingFail,
}) => {
    const [isDialogOpened, setIsDialogOpened] = React.useState(false);
    const [dialogType, setDialogType] = React.useState<DiskFormType>(DiskFormType.ADD);
    const [diskItem, setDiskItem] = React.useState<IVmDiskResponse|undefined>(undefined);
    const [fields, setFields] = React.useState<string[]>([]);

    isAdditionalDiskAdding = isAdditionalDiskAdding || server.status === ComputeResourceVmStatus.ADDITIONAL_DISK_ADDING;
    isAdditionalDiskDeleting = isAdditionalDiskDeleting || server.status === ComputeResourceVmStatus.ADDITIONAL_DISK_DELETING;
    isAdditionalDiskResizing = isAdditionalDiskResizing || server.status === ComputeResourceVmStatus.ADDITIONAL_DISK_RESIZING;
    const canAddDisk = !isAdditionalDiskAdding
        && !isAdditionalDiskDeleting
        && !isAdditionalDiskResizing
        && canAddOrDeleteAdditionalDisk(server);

    const addButtonTooltip = isAdditionalDiskAdding || isAdditionalDiskDeleting || isAdditionalDiskResizing
        ? <Translate content="servers.tabs.disks.button.tooltip.activeTasksRunning" />
        : !canAddOrDeleteAdditionalDisk(server)
            ? <Translate content="servers.guestToolsMissing" />
            : null;

    const getDialogHeader = () => {
        switch (dialogType) {
        case DiskFormType.ADD:
            return (
                <Translate content="servers.tabs.disks.dialog.title.add"/>
            );
        case DiskFormType.RESIZE:
            return (
                <Translate content="servers.tabs.disks.dialog.title.resize"/>
            );
        case DiskFormType.EDIT:
            return (
                <Translate content="servers.tabs.disks.dialog.title.edit"/>
            );
        }
    };

    // const showAddButton = true;
    React.useEffect(() => {
        if (server.id > 0) {
            getDisks(server.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [server.id]);

    React.useEffect(() => {
        const echo = initEchoConnection(echoCredentials);

        if (server.uuid) {
            const channel = echo.private(`${SOCKET_CHANNELS.VIRTUAL_SERVER}.${server.uuid}`);

            channel.listen(SOCKET_EVENTS.ADDITIONAL_DISK_ADDED, (data: IVmDiskEvent) => {
                commitAdditionalDiskCreatingSuccess(data.disk);
            });
            channel.listen(SOCKET_EVENTS.ADDITIONAL_DISK_ADD_FAILED, commitAdditionalDiskCreatingFail);

            channel.listen(SOCKET_EVENTS.ADDITIONAL_DISK_DELETED, (data: IVmDiskRemovedEvent) => {
                commitAdditionalDiskDeletingSuccess(data.diskId);
            });
            channel.listen(SOCKET_EVENTS.ADDITIONAL_DISK_DELETE_FAILED, (data: IVmDiskRemovedEvent) => {
                commitAdditionalDiskDeletingFail(data.diskId);
            });

            channel.listen(SOCKET_EVENTS.ADDITIONAL_DISK_RESIZED, (data: IVmDiskEvent) => {
                commitAdditionalDiskResizingSuccess(data.disk);
            });
            channel.listen(SOCKET_EVENTS.ADDITIONAL_DISK_RESIZE_FAILED, () => {
                commitAdditionalDiskResizingFail();
            });
        }
        return () => echo.disconnect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [server.uuid]);

    const handleRemove = (id: number) => () => {
        if (disks.filter(disk => disk.id === id && disk.is_primary).length) {
            return;
        }

        removeAdditionalDisk(server.id, server.status, id);
    };

    const handleAdd = () => {
        setDialogType(DiskFormType.ADD);
        setFields(Object.values(FIELDS));
        setDiskItem(undefined);
        setIsDialogOpened(true);
    };

    const handleEdit = (disk: IVmDiskResponse) => () => {
        setDialogType(DiskFormType.EDIT);
        setFields([FIELDS.NAME]);
        setDiskItem(disk);
        setIsDialogOpened(true);
    };

    const handleResize = (disk: IVmDiskResponse) => () => {
        setDialogType(DiskFormType.RESIZE);
        setFields([FIELDS.SIZE]);
        setDiskItem(disk);
        setIsDialogOpened(true);
    };

    const handleSubmit = (disk: IAdditionalDiskRequest) => {
        setIsDialogOpened(false);

        switch (dialogType) {
        case DiskFormType.ADD:
            addAdditionalDisk(server.id, server.status, disk);
            break;
        case DiskFormType.EDIT:
            if (diskItem !== undefined) {
                updateAdditionalDisk(server.id, diskItem.id, {
                    name: disk.name,
                });
            }
            break;
        case DiskFormType.RESIZE:
            if (diskItem !== undefined) {
                resizeAdditionalDisk(server.id, server.status, diskItem.id, {
                    size: disk.size,
                });
            }
            break;
        }
    };

    const getConfirmation = () => {
        switch (dialogType) {
        case DiskFormType.ADD:
            return (<Translate content="servers.tabs.disks.dialog.confirmation.add"/>);
        case DiskFormType.RESIZE:
            return (<Translate content="servers.tabs.disks.dialog.confirmation.resize"/>);
        default:
            return undefined;
        }
    };

    const handleClose = () => {
        setIsDialogOpened(false);
    };

    const data = disks.map((disk) => ({
        [DisksTableColumns.ID]: disk.id,
        [DisksTableColumns.NAME]: disk.name,
        [DisksTableColumns.IS_PRIMARY]: disk.is_primary && (
            <Icon name={ICONS.CHECK_MARK} />
        ),
        [DisksTableColumns.PATH]: canManageStorage && disk.full_path ? disk.full_path : disk.path,
        [DisksTableColumns.SIZE]: disk.size,
        [DisksTableColumns.ACTUAL_SIZE]: disk.actual_size,
        [DefaultTemplateTableColumns.ACTIONS]: (
            <DisksActions
                item={disk}
                hasResizePermission={canResizeAdditionalDisk}
                hasDeletePermission={canDeleteAdditionalDisk}
                canDelete={canAddOrDeleteAdditionalDisk(server)}
                isResizing={diskItem?.id === disk.id && isAdditionalDiskResizing}
                isUpdating={diskItem?.id === disk.id && isAdditionalDiskUpdating}
                isDeleting={diskItem?.id === disk.id && isAdditionalDiskDeleting}
                handleEdit={handleEdit(disk)}
                handleResize={handleResize(disk)}
                handleRemove={handleRemove(disk.id)}
            />
        ),
        key: disk.id.toString(),
    }));

    return (
        <Loader isLoading={isLoadingList}>
            <Grid gap="md">
                <GridCol>
                    <Item
                        title={
                            <NetworkingHeader>
                                <h3>
                                    <Translate content="servers.tabs.disks.title" />
                                </h3>
                                {canAddAdditionalDisk && (
                                    <Button
                                        size={SIZE.LG}
                                        intent={INTENT_TYPE.PRIMARY}
                                        icon={ICONS.PLUS}
                                        onClick={handleAdd}
                                        isLoading={isAdditionalDiskAdding}
                                        disabled={!canAddDisk}
                                        tooltip={addButtonTooltip}
                                    >
                                        <Translate content="servers.tabs.disks.button.title" />
                                    </Button>
                                )}
                            </NetworkingHeader>
                        }
                        view="card"
                    >
                        <List
                            emptyView={null}
                            columns={columns.map(column => columnDefinitions[column])}
                            data={data}
                            isFirstLoading={true}
                            isLoading={isLoadingList}
                        />
                    </Item>
                </GridCol>
                <Dialog
                    heading={getDialogHeader()}
                    closeHandler={handleClose}
                    isOpen={isDialogOpened}
                    size={SIZE.XS}
                >
                    <AdditionalDiskForm
                        onSubmit={handleSubmit}
                        tokenPricing={tokenPricing}
                        fields={fields}
                        disk={diskItem}
                        confirmation={getConfirmation()}
                    />
                </Dialog>
            </Grid>
        </Loader>
    );
};
