// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { MenuItem } from '@plesk/ui-library';
import { INTENT_TYPE } from 'common/constants';
import * as responseErrorActions from 'common/modules/app/responseError/actions';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { connect } from 'react-redux';
import { RootState } from 'client/core/store';
import ConfirmationPopover,
{ IConfirmationTranslation } from 'common/components/ConfirmationPopover';
import { TEST } from 'common/components/ButtonWithConfirmation';

interface IMenuItemWithConfirmationProps extends React.HTMLProps<HTMLDivElement> {
    onClose?: () => void;
    menuItemText?: string | React.ReactNode;
    disabled?: boolean;
    icon?: string | JSX.Element;
    isLoading?: boolean;
    handleConfirm: () => void;
    translations: IConfirmationTranslation;
    dataCy?: string;
    buttonColor?: string;
}

export type MenuItemWithConfirmationProps =
    IMenuItemWithConfirmationProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const MenuItemWithConfirmation: React.FC<MenuItemWithConfirmationProps> = ({
    responseErrorActions: { clearResponseError },
    menuItemText,
    onClose,
    error,
    icon,
    handleConfirm,
    isLoading,
    disabled,
    translations: {
        button,
        text,
        title,
    },
    dataCy,
    buttonColor,
}) => {
    const [isPopoverOpened, setPopoverOpened] = React.useState(false);

    const handleClose = () => {
        setPopoverOpened(false);
        clearResponseError();

        onClose?.();
    };

    const handleOpenPopover = () => {
        clearResponseError();
        setPopoverOpened(true);
    };

    const handleClickConfirm = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setPopoverOpened(false);

        await handleConfirm();

        if (!error.error) {
            setPopoverOpened(false);
        }
    };

    return (
        <ConfirmationPopover
            opened={isPopoverOpened}
            dataCy={dataCy}
            buttonDataCy={TEST.CONFIRM_POPOVER}
            translations={{
                button,
                text,
                title,
            }}
            disabled={isLoading}
            error={error.error ? error.error : undefined}
            intent={error.error ? INTENT_TYPE.DANGER : null}
            isLoading={isLoading}
            onButtonClick={handleClickConfirm}
            onClose={handleClose}
            buttonColor={buttonColor}
        >
            <MenuItem
                onClick={handleOpenPopover}
                icon={icon}
                disabled={disabled}
            >
                {menuItemText}
            </MenuItem>
        </ConfirmationPopover>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    responseErrorActions: bindActionCreators(responseErrorActions, dispatch),
});

const mapStateToProps = (state: RootState) => ({
    error: state.app.responseError,
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemWithConfirmation);
