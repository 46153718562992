// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as planActions from 'common/modules/plan/actions';
import { combineReducers } from 'redux';
import { addToPaginated } from 'common/reducers/list';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import {
    IPlanResponse,
    limitFactory,
    NetworkTrafficLimitTypes,
    ResetLimitPolicy,
    IPlanCreateRequest,
    LimitName,
} from 'common/api/resources/Plan';
import {
    ImageFormat,
    StorageType,
} from 'common/api/resources/StorageType';
import {
    BandwidthUnit,
    DataUnit,
    DiskBandwidthUnit,
    IOpsUnit,
    Unit,
} from 'common/helpers/units';
import { VirtualizationType } from 'common/api/resources/ComputeResource';
import { VZNetfilterStatus } from 'common/api/resources/ComputeResourceVm';

interface IPlans {
    list: IPaginateApiResponse<IPlanResponse[]>;
    item: IPlanResponse;
}

export type PlanAction = ActionType<typeof planActions>;
export type PlanState = Readonly<IPlans>;

export const initialPlanState: IPlanResponse = {
    id: 0,
    name: '',
    tokens_per_hour: 0,
    tokens_per_month: 0,
    params: {
        disk: 1,
        ram: 1,
        vcpu: 1,
        vcpu_units: 1000,
        vcpu_limit: 100,
        io_priority: 4,
        swap: 1,
    },
    virtualization_type: VirtualizationType.KVM,
    storage_type: StorageType.FB,
    image_format: ImageFormat.QCOW2,
    is_default: false,
    is_custom: false,
    is_loading: false,
    is_deleting: false,
    is_snapshot_available: false,
    is_snapshots_enabled: false,
    reset_limit_policy: ResetLimitPolicy.Never,
    network_traffic_limit_type: NetworkTrafficLimitTypes.Separate,
    limits: {
        [LimitName.NetworkTotalTraffic]: limitFactory(DataUnit.GiB),
        [LimitName.NetworkOutgoingTraffic]: limitFactory(DataUnit.GiB),
        [LimitName.NetworkIncomingTraffic]: limitFactory(DataUnit.GiB),
        [LimitName.NetworkReduceBandwidth]: limitFactory(BandwidthUnit.Kbps),
        [LimitName.NetworkOutgoingBandwidth]: limitFactory(BandwidthUnit.Mbps),
        [LimitName.NetworkIncomingBandwidth]: limitFactory(BandwidthUnit.Mbps),
        [LimitName.DiskIops]: limitFactory(IOpsUnit.iops),
        [LimitName.DiskBandwidth]: limitFactory(DiskBandwidthUnit.Bps),
        [LimitName.BackupsNumber]: limitFactory(Unit.UNITS, 7, true),
        [LimitName.SnapshotsCount]: limitFactory(Unit.UNITS),
        [LimitName.SnapshotsSize]: limitFactory(DataUnit.GiB),
        [LimitName.AdditionalDiskNumber]: limitFactory(Unit.UNITS, 7, true),
    },
    is_backup_available: false,
    backup_settings: {
        is_incremental_backup_enabled: false,
        incremental_backups_limit: 3,
    },
    backup_price: 0,
    is_visible: true,
    is_thin_provisioned: false,
    position: 0,
    is_additional_ips_available: false,
    ip_tokens_per_month: 0,
    ip_tokens_per_hour: 0,
    iso_image_tokens_per_month: 0,
    iso_image_tokens_per_hour: 0,
    available_locations: [],
    available_os_image_versions: [],
    available_applications: [],
};

export const initialKVMPlanRequest: IPlanCreateRequest = {
    name: '',
    tokens_per_hour: 0,
    tokens_per_month: 0,
    params: {
        disk: 1,
        ram: 1,
        vcpu: 1,
        vcpu_units: 1000,
        vcpu_limit: 100,
        io_priority: 4,
        swap: 1,
    },
    virtualization_type: VirtualizationType.KVM,
    storage_type: StorageType.FB,
    image_format: ImageFormat.QCOW2,
    is_snapshots_enabled: false,
    reset_limit_policy: ResetLimitPolicy.Never,
    network_traffic_limit_type: NetworkTrafficLimitTypes.Separate,
    limits: {
        [LimitName.NetworkTotalTraffic]: limitFactory(DataUnit.GiB),
        [LimitName.NetworkOutgoingTraffic]: limitFactory(DataUnit.GiB),
        [LimitName.NetworkIncomingTraffic]: limitFactory(DataUnit.GiB),
        [LimitName.NetworkReduceBandwidth]: limitFactory(BandwidthUnit.Kbps),
        [LimitName.NetworkOutgoingBandwidth]: limitFactory(BandwidthUnit.Mbps),
        [LimitName.NetworkIncomingBandwidth]: limitFactory(BandwidthUnit.Mbps),
        [LimitName.DiskIops]: limitFactory(IOpsUnit.iops),
        [LimitName.DiskBandwidth]: limitFactory(DiskBandwidthUnit.Bps),
        [LimitName.BackupsNumber]: limitFactory(Unit.UNITS, 7, true),
        [LimitName.SnapshotsCount]: limitFactory(Unit.UNITS),
        [LimitName.SnapshotsSize]: limitFactory(DataUnit.GiB),
        [LimitName.AdditionalDiskNumber]: limitFactory(Unit.UNITS, 7, true),
    },
    is_backup_available: false,
    backup_settings: {
        is_incremental_backup_enabled: false,
        incremental_backups_limit: 3,
    },
    backup_price: 0,
    is_visible: true,
    is_additional_ips_available: false,
    ip_tokens_per_hour: 0,
    ip_tokens_per_month: 0,
    iso_image_tokens_per_hour: 0,
    iso_image_tokens_per_month: 0,
    available_locations: [],
    available_os_image_versions: [],
    available_applications: [],
};

export const initialVZPlanRequest: IPlanCreateRequest = {
    ...initialKVMPlanRequest,
    virtualization_type: VirtualizationType.VZ,
    storage_type: StorageType.VZ,
    image_format: ImageFormat.PLOOP,
    netfilter: {
        value: VZNetfilterStatus.STATELESS,
        is_editable: false,
    },
    tun_tap: {
        value: false,
        is_editable: false,
    },
    ppp: {
        value: false,
        is_editable: false,
    },
};

export default combineReducers<PlanState, PlanAction>({
    list: (state = paginateInitialState, action: PlanAction) => {
        switch (action.type) {
        case getType(planActions.appendPlans):
            return {
                links: action.payload.links,
                data: [...state.data, ...action.payload.data],
                meta: action.payload.meta,
            };
        case getType(planActions.addPlanItem):
            return addToPaginated(state, action.payload);
        case getType(planActions.setPlanList):
            return action.payload;
        case getType(planActions.removePlanItem):
            return {
                ...state,
                data: state.data.filter(item => item.id !== action.payload),
            };
        case getType(planActions.removePlanItems):
            const plans = state.data.reduce((prev: IPlanResponse[], current: IPlanResponse) => {
                if (action.payload.includes(current.id)) {
                    return prev;
                }

                return [...prev, current];
            }, []);
            return {
                ...state,
                data: plans,
            };
        case getType(planActions.clearDefault):
            return {
                ...state,
                data: state.data.map(item => ({ ...item, is_default: false })),
            };
        case getType(planActions.setPlanItemIsLoading):
            return {
                ...state, data: [...state.data.map(item => {
                    if (item.id === action.payload) {
                        return { ...item, is_loading: true };
                    }

                    return item;
                })],
            };
        case getType(planActions.unsetPlanItemIsLoading):
            return {
                ...state, data: [...state.data.map(item => {
                    if (item.id === action.payload) {
                        return { ...item, is_loading: false };
                    }

                    return item;
                })],
            };
        case getType(planActions.setPlanItemIsDeleting):
            return {
                ...state, data: [...state.data.map(item => {
                    if (item.id === action.payload.id) {
                        return { ...item, is_deleting: action.payload.isDeleting };
                    }

                    return item;
                })],
            };
        case getType(planActions.updatePlanItem):
        case getType(planActions.updatePlanItemPosition):
            return {
                ...state,
                data: state.data.map(item => {
                    if (item.id === action.payload.id) {
                        return { ...item, ...action.payload };
                    }

                    return item;
                }),
            };
        default:
            return state;
        }
    },
    item: (state = { ...initialPlanState }, action) => {
        switch (action.type) {
        case getType(planActions.setPlanItem):
            return action.payload;
        case getType(planActions.unsetPlanItem):
            return { ...initialPlanState };
        default:
            return state;
        }
    },
});
