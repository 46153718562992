// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { IBackupSettings } from 'common/api/resources/ComputeResourceVm';
import {
    Form,
    Section,
    Translate,
} from '@plesk/ui-library';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { connect } from 'react-redux';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { Button } from 'admin/common/components/Button/Button';
import { Limit } from 'common/components/Limit/Limit';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import { hasPermission } from 'common/modules/permission/selectors';
import Schedule from 'common/containers/Schedule/Schedule';
import {
    ISchedule,
    ScheduleType,
} from 'common/api/resources/model';
import {
    requiredRule,
    validate,
} from 'common/validator';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';

export const TEST = {
    TYPE: 'backup-schedule-type',
};

interface IBackupSettingsFormProps {
    backupSettings: IBackupSettings;
    setBackupSettings: (newBackupSettings: IBackupSettings) => void;
    onSubmit: () => void;
}

export type BackupSettingsFormProps =
    IBackupSettingsFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const BackupSettingsForm: React.FC<BackupSettingsFormProps> = ({
    backupSettings,
    setBackupSettings,
    formErrors,
    isSaving,
    canManageBackups,
    onSubmit,
    formErrorsActions: { setFormErrors, clearFormErrors },
}) => {
    const [ values, setValues ] = React.useState<IBackupSettings>(backupSettings);

    const handleScheduleChange = (schedule: ISchedule) => {
        clearFormErrors();
        setValues({
            ...values,
            schedule,
        });
    };

    const handleChangeLimitEnabled = () => {
        if (values.limit) {
            setValues({
                ...values,
                limit: {
                    ...values.limit,
                    is_enabled: !values.limit.is_enabled,
                },
            });
        }
    };

    const handleChangeLimit = (limit: string) => {
        if (values.limit) {
            setValues({
                ...values,
                limit: {
                    ...values.limit,
                    limit: +limit,
                },
            });
        }
    };

    const handleSubmit = async (newBackupSettings: IBackupSettings) => {
        if (!validateSettings(newBackupSettings)) {
            return;
        }

        await setBackupSettings({
            ...newBackupSettings,
            schedule: newBackupSettings.schedule,
            limit: newBackupSettings.limit ? {
                ...newBackupSettings.limit,
                unit: newBackupSettings.limit.unit,
            } : undefined,
        });
        onSubmit();
    };

    const validateSettings = (newBackupSettings: IBackupSettings): boolean => {
        if (![ScheduleType.MONTHLY, ScheduleType.WEEKLY].includes(newBackupSettings.schedule.type)) {
            return true;
        }

        const rules = {};
        rules['schedule.days'] = requiredRule(<Translate content="validate.fieldRequired" />);

        const errors = validate(values, rules);

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return false;
        }

        clearFormErrors();
        return true;
    };

    React.useEffect(() => () => {
        clearFormErrors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Section>
                <Form
                    id="backupSettingsForm"
                    footerClassName="hidden"
                    onSubmit={handleSubmit}
                    values={values}
                    errors={formErrors}
                    hideRequiredLegend={true}
                    submitButton={false}
                    cancelButton={false}
                    applyButton={false}
                    vertical={true}
                >
                    <Schedule
                        schedule={values.schedule}
                        onChange={handleScheduleChange}
                        fieldName="schedule"
                        types={[ScheduleType.MONTHLY, ScheduleType.WEEKLY, ScheduleType.DAILY]}
                    />
                    {canManageBackups && values.limit && (
                        <Limit
                            limit={values.limit}
                            label={<Translate content="backupSettingsForm.limitLabel" />}
                            fieldName="limit[limit]"
                            onChangeIsEnabled={handleChangeLimitEnabled}
                            onLimitChange={handleChangeLimit}
                            min={1}
                        />
                    )}
                </Form>
            </Section>
            <Button
                type="submit"
                form="backupSettingsForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size={SIZE.LG}
                isLoading={isSaving}
            >
                <Translate content="backupSettingsForm.saveBtn" />
            </Button>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    formErrors: nestStringProperties(state)['backup_settings'],
    canManageBackups: hasPermission(state, PERMISSION_LIST.MANAGE_BACKUPS),
    isSaving: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_BACKUP_SETTINGS_UPDATE),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    formErrorsActions: bindActionCreators({
        ...formErrorsActions,
        setFormErrors: (errors: Record<string, unknown>) =>
            formErrorsActions.setFormErrors({ backup_settings: errors }),
    }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BackupSettingsForm);
