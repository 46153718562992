// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    requiredRule,
    validate,
} from 'common/validator';
import * as offerActions from 'common/modules/offer/actions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import {
    Column,
    Columns,
    Form,
    FormField,
    FormFieldCheckbox,
    FormFieldText,
    ISegmentedControlButton,
    Section,
    setIn,
    Translate,
} from '@plesk/ui-library';
import { FormFieldNumber } from 'common/components/Form/FormFieldNumber/FormFieldNumber';
import { SegmentedControl } from 'common/components/SegmentedControl/SegmentedControl';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { Button } from 'admin/common/components/Button/Button';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import {
    IOfferParametersAdditionalDisk,
    IOfferParametersPrimaryDisk,
    IOfferRequest,
    OfferPriceMode,
    OfferType,
} from 'common/api/resources/Offer/model';
import { IFormProps } from 'common/components/Form/types';
import * as storageTagActions from 'admin/storageTag/actions';
import * as planActions from 'common/modules/plan/actions';
import * as locationActions from 'common/modules/location/actions';
import { IShortStorageTagResponse } from 'common/api/resources/StorageTag';
import { ISelectOption } from 'common/components';
import { SelectWithDataLoader } from 'admin/common/components/SelectWithDataLoader/SelectWithDataLoader';
import { IShortPlanResponse } from 'common/api/resources/Plan';
import { IShortLocationResponse } from 'common/api/resources/Location';
import { Limit } from 'common/components/Limit/Limit';
import { ILimit } from 'common/api/resources/model';
import { createOptionsLoader } from 'common/components/Select/helpers';
import { FORM } from 'common/modules/offer/constants/tests';
import { VerticalIndent } from 'common/components/styles/VerticalIndent';
import { getInitialParameters } from 'common/modules/offer/reducer';
import { BillingType } from 'common/api/resources/Settings';
import {
    DataUnit,
    DiskBandwidthUnit,
    IOpsUnit,
} from 'common/helpers/units';

interface IOfferFormProps {
    onSubmit: () => void;
}

export type OfferFormProps =
    IOfferFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const typeButtons: Array<ISegmentedControlButton<OfferType>> = [
    {
        title: <Translate content="offer.type.primaryDisk" />,
        value: OfferType.PRIMARY_DISK,
    },
    {
        title: <Translate content="offer.type.additionalDisk" />,
        value: OfferType.ADDITIONAL_DISK,
    },
];

const tokensModeButtons: Array<ISegmentedControlButton<OfferPriceMode>> = [
    {
        title: <Translate content="offer.tokensMode.perUnit" />,
        value: OfferPriceMode.PER_UNIT,
        tooltip: <Translate content="offer.tokensMode.perUnitTooltip" />,
    },
    {
        title: <Translate content="offer.tokensMode.perGiB" />,
        value: OfferPriceMode.PER_GIB,
        tooltip: <Translate content="offer.tokensMode.perGiBTooltip" />,
    },
    {
        title: <Translate content="offer.tokensMode.percent" />,
        value: OfferPriceMode.PERCENT,
        tooltip: <Translate content="offer.tokensMode.percentTooltip" />,
    },
];

const storageTagToSelectOption = (tag: IShortStorageTagResponse) => ({
    label: tag.name,
    value: tag.id,
});

export const OfferForm: React.FC<OfferFormProps> = ({
    item,
    isItemSaving,
    isBillingIntegrationEnabled,
    formErrors,
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
    offerActions: {
        unsetOfferItem,
        createOffer,
        updateOffer,
    },
    storageTagActions: {
        getStorageTags,
    },
    planActions: {
        getPlans,
    },
    locationActions: {
        getLocations,
    },
    onSubmit,
}) => {
    const [submitValues, setSubmitValues] = React.useState<IOfferRequest>({
        ...item,
        available_plans: item.available_plans ? item.available_plans.map((plan) => plan.id) : [],
        available_locations: item.available_locations ? item.available_locations.map((location) => location.id) : [],
        parameters: item.parameters,
    });
    const [parameters, setParameters] = React.useState({
        [OfferType.PRIMARY_DISK]: getInitialParameters(OfferType.PRIMARY_DISK),
        [OfferType.ADDITIONAL_DISK]: getInitialParameters(OfferType.ADDITIONAL_DISK),
    });

    const [selectedStorageTag, setSelectedStorageTag] = React.useState<ISelectOption | undefined>(
        item.storage_tag ? storageTagToSelectOption(item.storage_tag) : undefined
    );

    React.useEffect(() => () => {
        clearFormErrors();
        unsetOfferItem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const storageTagsLoader = createOptionsLoader(
        getStorageTags,
        storageTagToSelectOption
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleStorageTagChange = (option: any) => {
        setSelectedStorageTag(option);
        setSubmitValues((prevValues) => ({
            ...prevValues,
            storage_tag_id: option.value,
        }));
    };

    const handleFieldChange = (field: string, value: string) => setSubmitValues(setIn(submitValues, field, value));

    const handleOfferTypeChange = (type: OfferType) => {
        setParameters({
            ...parameters,
            [submitValues.type]: submitValues.parameters,
        });
        setSubmitValues({
            ...submitValues,
            type,
            parameters: parameters[type],
        });
    };

    const handleToggleLimit = (limitFieldName: string) => () => {
        let limit: ILimit = submitValues.parameters![limitFieldName];

        setSubmitValues({
            ...submitValues,
            parameters: {
                ...submitValues.parameters,
                [limitFieldName]: {
                    ...limit,
                    is_enabled: !limit.is_enabled,
                },
            } as typeof submitValues.parameters,
        });
    };

    const handleSubmit = async (values: IOfferRequest) => {
        const errors = validate<IOfferRequest>(values, {
            name: requiredRule(<Translate content="validate.fieldRequired" />),
        });

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        if (!values.description) {
            values.description = undefined;
        }

        item.id
            ? await updateOffer(item.id, values)
            : await createOffer(values);
        onSubmit();
    };

    return (
        <>
            <Form
                id="offerForm"
                onSubmit={handleSubmit}
                values={submitValues}
                onFieldChange={handleFieldChange}
                footerClassName="hidden"
                errors={formErrors}
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <Section>
                    <FormFieldText
                        name="name"
                        size={SIZE.FILL}
                        label={<Translate content="offer.form.name" />}
                        required={true}
                    />
                    <FormFieldText
                        name="description"
                        multiline={true}
                        size={SIZE.FILL}
                        label={<Translate content="offer.form.description" />}
                    />
                    <FormFieldCheckbox name="is_visible" label={<Translate content="offer.form.visibility"/>} />
                    <FormField
                        name="type"
                        label={<Translate content="offer.form.type" />}
                    >
                        <SegmentedControl
                            buttons={typeButtons.map((button) => ({
                                ...button,
                                disabled: !!item.id,
                            }))}
                            selected={submitValues.type}
                            onChange={handleOfferTypeChange}
                        />
                    </FormField>
                    {isBillingIntegrationEnabled && (
                        <>
                            <FormField
                                name="price_mode"
                                label={<Translate content="offer.form.priceMode" />}
                            >
                                {({ setValue }: IFormProps<OfferPriceMode>) => (
                                    <SegmentedControl
                                        buttons={tokensModeButtons}
                                        selected={submitValues.price_mode}
                                        onChange={setValue}
                                    />
                                )}
                            </FormField>
                            <Section>
                                <Columns gap={SIZE.MD} vertical={false}>
                                    <Column width={219}>
                                        <FormFieldNumber
                                            name="per_hour"
                                            label={<Translate content="offer.form.tokensPerHour" />}
                                            vertical
                                            min={0}
                                            size={SIZE.MD}
                                            defaultValue={0}
                                        />
                                    </Column>
                                    <Column width={219}>
                                        <FormFieldNumber
                                            name="per_month"
                                            label={<Translate content="offer.form.tokensPerMonth" />}
                                            vertical
                                            min={0}
                                            size={SIZE.MD}
                                            defaultValue={0}
                                        />
                                    </Column>
                                </Columns>
                            </Section>
                        </>
                    )}
                    <SelectWithDataLoader
                        name="available_locations"
                        label="offer.form.availableLocations"
                        buttonLabel="offer.form.addAllLocations"
                        loadItems={getLocations}
                        mapper={(location: IShortLocationResponse) => ({
                            label: location.name,
                            value: location.id,
                        })}
                        onChange={(available_locations: number[]) => setSubmitValues(values => ({
                            ...values,
                            available_locations,
                        }))}
                        values={item.available_locations?.map((location) => ({
                            label: location.name,
                            value: location.id,
                        }))}
                    />
                    <SelectWithDataLoader
                        name="available_plans"
                        label="offer.form.availablePlans"
                        buttonLabel="offer.form.addAllPlans"
                        loadItems={getPlans}
                        mapper={(plan: IShortPlanResponse) => ({
                            label: plan.name,
                            value: plan.id,
                        })}
                        onChange={(available_plans: number[]) => setSubmitValues(values => ({
                            ...values,
                            available_plans,
                        }))}
                        values={item.available_plans?.map((location) => ({
                            label: location.name,
                            value: location.id,
                        }))}
                    />
                    {[OfferType.PRIMARY_DISK, OfferType.ADDITIONAL_DISK].includes(submitValues.type) && (
                        <FormField
                            name="storage_tag_id"
                            label={<Translate content="offer.form.storageTag" />}
                        >
                            {({ getId }: IFormProps<unknown>) => (
                                <AsyncSelectInput
                                    inputId={getId()}
                                    value={selectedStorageTag}
                                    loadOptions={storageTagsLoader}
                                    debounceTimeout={1000}
                                    additional={{ page: 1 }}
                                    onChange={handleStorageTagChange}
                                    placeholder={<Translate content="offer.form.storageTagEmpty" />}
                                />
                            )}
                        </FormField>
                    )}
                    {submitValues.type === OfferType.PRIMARY_DISK && (
                        <>
                            <VerticalIndent />
                            <Section
                                in
                                title={
                                    <Translate content="offer.form.limits" />
                                }
                            >
                                <Limit
                                    onChangeIsEnabled={handleToggleLimit('bandwidth')}
                                    limit={(submitValues.parameters as IOfferParametersPrimaryDisk).bandwidth}
                                    fieldName="parameters[bandwidth][limit]"
                                    units={DiskBandwidthUnit}
                                    unitFieldName="parameters[bandwidth][unit]"
                                    label={<Translate content="offer.form.limitDiskBandwidth" />}
                                    required={false}
                                    min={1}
                                    data-cy={FORM.IOPS}
                                />
                                <Limit
                                    onChangeIsEnabled={handleToggleLimit('iops')}
                                    limit={(submitValues.parameters as IOfferParametersPrimaryDisk).iops}
                                    fieldName="parameters[iops][limit]"
                                    units={IOpsUnit}
                                    unitFieldName="parameters[iops][unit]"
                                    label={<Translate content="offer.form.limitDiskIops" />}
                                    required={false}
                                    min={1}
                                    data-cy={FORM.IOPS}
                                />
                            </Section>
                        </>
                    )}
                    {submitValues.type === OfferType.ADDITIONAL_DISK && (
                        <>
                            <VerticalIndent />
                            <Section
                                title={
                                    <Translate content="offer.form.limits" />
                                }
                            >
                                <Limit
                                    onChangeIsEnabled={handleToggleLimit('max_size')}
                                    limit={(submitValues.parameters as IOfferParametersAdditionalDisk).max_size}
                                    fieldName="parameters[max_size][limit]"
                                    label={<Translate content="offer.form.limitMaxSize" />}
                                    units={{ [DataUnit.GiB]: DataUnit.GiB }}
                                    required={false}
                                    min={1}
                                    data-cy={FORM.MAX_SIZE}
                                />
                                <Limit
                                    onChangeIsEnabled={handleToggleLimit('bandwidth')}
                                    limit={(submitValues.parameters as IOfferParametersAdditionalDisk).bandwidth}
                                    fieldName="parameters[bandwidth][limit]"
                                    units={DiskBandwidthUnit}
                                    unitFieldName="parameters[bandwidth][unit]"
                                    label={<Translate content="offer.form.limitDiskBandwidth" />}
                                    required={false}
                                    min={1}
                                    data-cy={FORM.BANDWIDTH}
                                />
                                <Limit
                                    onChangeIsEnabled={handleToggleLimit('iops')}
                                    limit={(submitValues.parameters as IOfferParametersAdditionalDisk).iops}
                                    fieldName="parameters[iops][limit]"
                                    units={IOpsUnit}
                                    unitFieldName="parameters[iops][unit]"
                                    label={<Translate content="offer.form.limitDiskIops" />}
                                    required={false}
                                    min={1}
                                    data-cy={FORM.IOPS}
                                />
                            </Section>
                        </>
                    )}
                </Section>
            </Form>
            <Button
                type="submit"
                form="offerForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size={SIZE.LG}
                isLoading={isItemSaving}
            >
                <Translate content="offer.form.saveBtn" />
            </Button>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    item: state.offer.item,
    isItemSaving: state.app.loadingFlags.has(LOADING_FLAGS.OFFER_ITEM_SAVE),
    isBillingIntegrationEnabled: state.settings.billing_integration.type !== BillingType.NULL,
    formErrors: nestStringProperties(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    offerActions: bindActionCreators(offerActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
    storageTagActions: bindActionCreators(storageTagActions, dispatch),
    planActions: bindActionCreators(planActions, dispatch),
    locationActions: bindActionCreators(locationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OfferForm);
