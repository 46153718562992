// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { ILocationResponse } from 'common/api/resources/Location';
import { Icon } from '@plesk/ui-library';
import {
    ICONS,
    INTENT_TYPE,
} from 'common/constants';
import {
    IconWrapper,
    IconCellContainer,
} from 'common/components/IconCell/Styles';

export interface ILocationCellProps {
    location: ILocationResponse;
    color?: string;
}

const LocationCell: React.FC<ILocationCellProps> = ({
    location,
    color,
}) => {
    const renderIcon = () => {
        if (location.icon) {
            return (
                <IconWrapper color={color}>
                    <img width={16} src={location.icon.url} alt={location.icon.name}/>
                    {location.name}
                </IconWrapper>
            );
        }

        return (
            <IconWrapper color={color}>
                <Icon
                    intent={INTENT_TYPE.INACTIVE}
                    name={ICONS.GLOBE}
                />
                {location.name}
            </IconWrapper>
        );
    };

    return (
        <IconCellContainer>
            {renderIcon()}
        </IconCellContainer>
    );
};

export default LocationCell;
