// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { Button } from 'admin/common/components/Button/Button';
import { Dialog } from 'common/components/Dialog/Dialog';
import {
    Icon,
    Paragraph,
} from 'common/components/ServerTabs/RescueTab/Styles';
import {
    Grid,
    GridCol,
    Item,
    ItemList,
    Translate,
} from '@plesk/ui-library';
import {
    ICONS,
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import {
    BootMode,
    IVmResetPasswordRequest,
    IVmResponse,
} from 'common/api/resources/ComputeResourceVm';
import { Loader } from 'common/components';
import { VirtualizationType } from 'common/api/resources/ComputeResource';
import { canChangePassword } from 'common/services/ServerCapabilities';
import CopyText from 'common/containers/CopyText/CopyText';
import IsoImageDialog,
{ IIsoImageOption } from 'common/components/ServerTabs/RescueTab/components/IsoImageDialog';
import ConfirmationDialog from 'common/components/ConfirmationDialog/ConfirmationDialog';
import { IsoImageConfirmation } from 'common/components/ServerTabs/RescueTab/components/IsoImageConfirmation';

export interface IRescueTabProps {
    isSwitchingBootMode: boolean;
    isResettingPassword: boolean;
    server: IVmResponse;
    resetPassword: (id: number, data?: IVmResetPasswordRequest) => void;
    updateBootMode: (id: number, bootMode: BootMode, isoImageId?: number) => void;
    authId: number;
    canGetISOImage: boolean;
    canBootFromISOImage: boolean;
    canResetRootPassword: boolean;
    canChangeBootMode: boolean;
}

interface IBootOption {
    title: string;
    value: BootMode;
    icon: string;
}

const bootOptions: IBootOption[] = [
    {
        title: 'servers.tabs.rescue.rescue.disk.title',
        value: BootMode.DISK,
        icon: ICONS.HARD_DRIVE,
    },
    {
        title: 'servers.tabs.rescue.rescue.iso.title',
        value: BootMode.RESCUE,
        icon: ICONS.CD,
    },
];

export enum DIALOG {
    CONFIRM_CHANGE_PASSWORD,
    SHOW_NEW_PASSWORD,
    SELECT_ISO_IMAGE,
    CONFIRM_ISO_IMAGE,
    NONE,
}

export const RescueTab: React.FC<IRescueTabProps> = ({
    server,
    isResettingPassword,
    isSwitchingBootMode,
    resetPassword,
    updateBootMode,
    authId,
    canGetISOImage,
    canBootFromISOImage,
    canResetRootPassword,
    canChangeBootMode,
}) => {
    const [openedDialog, setOpenedDialog] = React.useState<DIALOG>(DIALOG.NONE);
    const [bootMode, setBootMode] = React.useState<BootMode>(server.boot_mode);
    const [isoImage, setIsoImage] = React.useState<IIsoImageOption|undefined>(server.settings.iso_image ? {
        id: 0,
        ...server.settings.iso_image,
    } : undefined);

    const isBillingEnabled = server.project.token_pricing !== undefined;
    const isResetPasswordDisabled = React.useMemo(
        () => !canChangePassword(server),
        [server]
    );

    const closeDialog = () => {
        setOpenedDialog(DIALOG.NONE);
    };

    const bootModeIsDirty = bootMode !== server.boot_mode || server.settings.iso_image?.url !== isoImage?.url;

    const handlePasswordReset = () => {
        resetPassword(server.id);
        closeDialog();
    };

    const handleSelectBootMode = (selected: BootMode) => {
        if (selected === BootMode.ISO_IMAGE) {
            setOpenedDialog(DIALOG.SELECT_ISO_IMAGE);
        }

        setBootMode(selected);
    };

    const handleCloseISOImageDialog = () => {
        if (!isoImage) {
            setBootMode(server.boot_mode);
        }
        closeDialog();
    };

    const handleSelectISOImage = (item: IIsoImageOption) => {
        setIsoImage(item);
        closeDialog();
    };

    const handleUpdateBootMode = (confirmed = false) => {
        if (!confirmed && (
            (bootMode === BootMode.ISO_IMAGE)
            || (server.boot_mode === BootMode.ISO_IMAGE && bootMode === BootMode.DISK)
        )) {
            setOpenedDialog(DIALOG.CONFIRM_ISO_IMAGE);
            return;
        }

        closeDialog();

        if (!bootModeIsDirty) {
            return;
        }

        if (bootMode !== BootMode.ISO_IMAGE) {
            setIsoImage(undefined);
        }

        updateBootMode(server.id, bootMode, isoImage?.id);
    };

    React.useEffect(() => {
        if (server.new_password) {
            setOpenedDialog(DIALOG.SHOW_NEW_PASSWORD);
        }
    }, [server.new_password]);

    const renderRescueSection = () => (
        <Grid gap="md">
            <GridCol xs={12} sm={12} md={12} xl={7} lg={7}>
                <Item
                    title={
                        <h3>
                            <Translate content="servers.tabs.rescue.rescue.title" />
                        </h3>
                    }
                    view="card"
                >
                    <Paragraph>
                        <Translate content="servers.tabs.rescue.rescue.description1" />
                    </Paragraph>
                    <ItemList
                        md="auto"
                        onSelect={handleSelectBootMode}
                        selectable={true}
                        gap="md"
                        value={bootMode}
                    >
                        {bootOptions
                            .map((item, key) => (
                                <Item
                                    key={key}
                                    disabled={isSwitchingBootMode}
                                    icon={
                                        <Loader
                                            isLoading={item.value === server.boot_mode && isSwitchingBootMode}
                                            center={false}
                                        >
                                            <Icon size={32} name={item.icon} />
                                        </Loader>
                                    }
                                    value={item.value}
                                    title={
                                        <h3>
                                            <Translate content={item.title} />
                                        </h3>
                                    }
                                />
                            ))}
                        {canGetISOImage && canBootFromISOImage && (
                            <Item
                                key={3}
                                disabled={isSwitchingBootMode}
                                icon={
                                    <Loader
                                        isLoading={BootMode.ISO_IMAGE === server.boot_mode && isSwitchingBootMode}
                                        center={false}
                                    >
                                        {isoImage && isoImage.icon ? (
                                            <img width="32px" src={isoImage.icon} alt=""/>
                                        ) : (
                                            <Icon size={32} name={ICONS.CD} />
                                        )}
                                    </Loader>
                                }
                                value={BootMode.ISO_IMAGE}
                                title={
                                    <h3>
                                        {isoImage ? (
                                            <Translate content="servers.tabs.rescue.rescue.isoImage.title" params={{
                                                name: isoImage.name,
                                            }} />
                                        ) : (
                                            <Translate content="servers.tabs.rescue.rescue.isoImage.emptyTitle" />
                                        )}
                                    </h3>
                                }
                            />
                        )}
                    </ItemList>
                    <Paragraph>
                        <Translate content="servers.tabs.rescue.rescue.description2" />
                    </Paragraph>
                    <Paragraph>
                        <Translate content="servers.tabs.rescue.rescue.description3" />
                    </Paragraph>
                    <Paragraph>
                        <Translate content="servers.tabs.rescue.rescue.description4" />
                    </Paragraph>
                    <Button
                        onClick={() => handleUpdateBootMode(false)}
                        size={SIZE.LG}
                        disabled={!bootModeIsDirty}
                        intent={INTENT_TYPE.PRIMARY}
                    >
                        {isBillingEnabled && bootMode === BootMode.ISO_IMAGE ? (
                            <Translate content="servers.tabs.rescue.rescue.buttonAndBuy" />
                        ) : (
                            <Translate content="servers.tabs.rescue.rescue.button" />
                        )}
                    </Button>
                </Item>
            </GridCol>
        </Grid>
    );

    return (
        <>
            {canChangeBootMode && server.virtualization_type === VirtualizationType.KVM && renderRescueSection()}
            {canResetRootPassword && (
                <Grid gap="md">
                    <GridCol xs={12} sm={12} md={12} xl={7} lg={7}>
                        <Item
                            title={
                                <h3>
                                    <Translate content="servers.tabs.rescue.resetPassword.title" />
                                </h3>
                            }
                            view="card"
                        >
                            <Paragraph>
                                <Translate content="servers.tabs.rescue.resetPassword.content" />
                            </Paragraph>
                            <Button
                                onClick={() => setOpenedDialog(DIALOG.CONFIRM_CHANGE_PASSWORD)}
                                size={SIZE.LG}
                                intent={INTENT_TYPE.PRIMARY}
                                isLoading={isResettingPassword}
                                disabled={isResetPasswordDisabled}
                                tooltip={isResetPasswordDisabled && (<Translate content="servers.guestToolsMissing" />)}
                            >
                                <Translate content="servers.tabs.rescue.resetPassword.button" />
                            </Button>
                        </Item>
                    </GridCol>
                </Grid>
            )}
            <Dialog
                heading={
                    <Translate content="servers.tabs.rescue.resetPassword.dialog.title" />
                }
                closeHandler={closeDialog}
                isOpen={openedDialog === DIALOG.CONFIRM_CHANGE_PASSWORD}
                size={SIZE.XS}
            >
                <Paragraph>
                    {server.user.id === authId
                        ? <Translate content="servers.tabs.rescue.resetPassword.dialog.content" />
                        : <Translate content="servers.tabs.rescue.resetPassword.dialog.contentForNotOwner" />
                    }
                </Paragraph>
                <Button
                    fill={true}
                    onClick={handlePasswordReset}
                    size={SIZE.LG}
                    disabled={isResettingPassword}
                    intent={INTENT_TYPE.PRIMARY}
                >
                    <Translate content="servers.tabs.rescue.resetPassword.dialog.button" />
                </Button>
            </Dialog>
            <Dialog
                heading={
                    <Translate content="servers.tabs.rescue.resetPassword.newPasswordDialog.title" />
                }
                closeHandler={closeDialog}
                isOpen={openedDialog === DIALOG.SHOW_NEW_PASSWORD}
                size={SIZE.XS}
            >
                <CopyText isInline={true}>{server.new_password}</CopyText>
            </Dialog>
            <Dialog
                heading={
                    <Translate content="servers.tabs.rescue.rescue.isoImageDialog.title" />
                }
                closeHandler={handleCloseISOImageDialog}
                isOpen={openedDialog === DIALOG.SELECT_ISO_IMAGE}
                size={SIZE.XS}
            >
                <IsoImageDialog
                    server={server}
                    handleSelect={handleSelectISOImage}
                    isoImageSetting={isoImage}
                />
            </Dialog>
            <ConfirmationDialog
                isOpen={openedDialog === DIALOG.CONFIRM_ISO_IMAGE}
                isLoading={false}
                handleClick={() => handleUpdateBootMode(true)}
                onClose={closeDialog}
                title={
                    <Translate content="servers.tabs.rescue.rescue.isoImageDialog.confirmation.header" />
                }
                size={SIZE.MD}
                innerText={
                    <IsoImageConfirmation
                        os={server.os_type}
                        serverBootMode={server.boot_mode}
                        selectedBootMode={bootMode}
                        ips={server.ip_addresses}
                    />
                }
            />
        </>
    );
};
