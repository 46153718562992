// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import React from 'react';
import {
    ITaskResponse,
    TASK_STATUS,
} from 'common/api/resources/Task';
import {
    Status,
    Translate,
} from '@plesk/ui-library';
import { INTENT_TYPE } from 'common/constants';
import {
    FailedTaskContainer,
    ErrorText,
} from 'admin/update/components/FailedTask/Styles';
import { Button } from 'admin/common/components/Button/Button';

export interface IFailedTaskProps {
    task: ITaskResponse;
    status?: JSX.Element | string | null;
    onRetry: () => void;
}

export const FailedTask: React.FC<IFailedTaskProps> = ({
    task,
    status,
    onRetry,
}) => (
    <FailedTaskContainer>
        {status && (
            <Status compact={true} intent={INTENT_TYPE.DANGER}>
                {status}
            </Status>
        )}
        <ErrorText>
            {task.output}
        </ErrorText>
        <Button
            ghost={true}
            intent={INTENT_TYPE.PRIMARY}
            icon="reload"
            state={task.status === TASK_STATUS.RUNNING ? 'loading' : null}
            onClick={onRetry}
        >
            <Translate content="update.retry" />
        </Button>
    </FailedTaskContainer>
);

export default FailedTask;
