// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import * as storageTypeActions from 'admin/storageType/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import * as locationActions from 'common/modules/location/actions';
import * as osImageActions from 'common/modules/osImage/actions';
import * as applicationActions from 'common/modules/application/actions';
import { Loader } from 'common/components';
import { SegmentedControl } from 'common/components/SegmentedControl/SegmentedControl';
import { Button } from 'admin/common/components/Button/Button';
import AdditionalOffersSection from 'common/components/plan/components/AdditionalOffersSection';
import PlanLimit from 'common/components/plan/components/PlanLimit';
import TokensColumns from 'common/components/plan/components/TokensColumns';
import { clearResponseError } from 'common/modules/app/responseError/actions';
import { initialKVMPlanRequest } from 'common/modules/plan/reducer';
import ParamsColumns from 'common/components/plan/components/ParamsColumns';
import NetworkLimitsSection from 'common/components/plan/components/NetworkLimitsSection';
import {
    IPlanCreateRequest,
    LimitName,
} from 'common/api/resources/Plan';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import {
    conditionRule,
    requiredRule,
    validate,
} from 'common/validator';
import {
    ImageFormat,
    isSnapshotsAvailableFor,
    STORAGE_TYPES_TRANSLATION_MAP,
    StorageType,
} from 'common/api/resources/StorageType';
import {
    Form,
    FormField,
    FormFieldCheckbox,
    FormFieldText,
    Section,
    setIn,
    Translate,
} from '@plesk/ui-library';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import {
    DataUnit,
    DiskBandwidthUnit,
    IOpsUnit,
    KiB,
    MiB,
    GiB,
    Unit,
} from 'common/helpers/units';
import { FORM } from 'admin/plan/constants/tests';
import {
    VIRTUALIZATION_TYPE_TRANSLATION_MAP,
    VirtualizationType,
} from 'common/api/resources/ComputeResource';
import { BillingType } from 'common/api/resources/Settings';
import { SelectWithDataLoader } from 'admin/common/components/SelectWithDataLoader/SelectWithDataLoader';
import {
    ILocationResponse,
    IShortLocationResponse,
} from 'common/api/resources/Location';
import {
    IOsImageListRequest,
    IOsImageResponse,
} from 'common/api/resources/OsImage';
import {
    IApplicationResponse,
    IShortApplicationResponse,
} from 'common/api/resources/Application';
import VZParametersSection from 'common/components/plan/components/VZParametersSection';
import {
    ComputeResourceVmCustomPlan,
    VZNetfilterStatus,
} from 'common/api/resources/ComputeResourceVm';
import { IShortOsImageVersionResponse } from 'common/api/resources/OsImageVersion';
import { SubInputs } from 'admin/common/styles/Styles';
import { MAX_ADDITIONAL_DISK_COUNT_ON_SERVER } from 'common/components/plan/constants';

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export type InitialPlan = Optional<
    Omit<IPlanCreateRequest, 'available_locations' | 'available_os_image_versions' | 'available_applications'>,
    'name'
    | 'is_visible'
    | 'tokens_per_hour'
    | 'tokens_per_month'
    | 'ip_tokens_per_hour'
    | 'ip_tokens_per_month'
    | 'iso_image_tokens_per_hour'
    | 'iso_image_tokens_per_month'
    | 'backup_price'
> & {
    available_locations?: IShortLocationResponse[];
    available_os_image_versions?: IShortOsImageVersionResponse[];
    available_applications?: IShortApplicationResponse[];
};

// Should be PlanUpdateRequest as well here, but it causes problems.
export type PlanRequest = IPlanCreateRequest | ComputeResourceVmCustomPlan;

interface IPlanFormProps {
    initialState: InitialPlan;
    onSubmit: (request: PlanRequest) => void;
    submitButton?: React.ReactNode;
    dataUnit?: DataUnit;
    minDisk?: number;
    fields: string[];
}

export type PlanFormProps =
    IPlanFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

/**
 * This is the list of plan fields which we can edit.
 * We use this list to specify list of plan fields in next lists:
 * frontend/src/common/components/ServerTabs/ResizeTab/ResizeTab.tsx - custom plan form for resizing
 * frontend/src/common/components/plan/PlanList.tsx - custom plan form (default values is used for server creation)
 * frontend/src/admin/plan/containers/Plan.tsx - create and edit plan form
 * Plan`s fields should be tested via method `testPlanFields` which finds field labels in the rendered form
 */
export const FIELDS = {
    NAME: 'name',
    IS_VISIBLE: 'is_visible',
    VIRTUALIZATION_TYPE: 'virtualization_type',
    STORAGE_TYPE: 'storage_type',
    IMAGE_FORMAT: 'image_format',
    IS_SNAPSHOTS_ENABLED: 'is_snapshots_enabled',
    SNAPSHOT_LIMITS: 'snapshot_limits',
    TOKENS_COLUMNS: 'tokens_columns',
    DISK: 'disk',
    RAM: 'ram',
    VCPU: 'vcpu',
    VCPU_UNITS: 'vcpu_units',
    VCPU_LIMIT: 'vcpu_limit',
    IO_PRIORITY: 'io_priority',
    SWAP: 'swap',
    AVAILABLE_LOCATIONS: 'available_locations',
    AVAILABLE_OS_IMAGE_VERSIONS: 'available_os_image_versions',
    AVAILABLE_APPLICATIONS: 'available_applications',
    IS_BACKUP_AVAILABLE: 'is_backup_available',
    BACKUP_PRICE: 'backup_price',
    BACKUP_LIMIT: 'backup_limit',
    INCREMENTAL_BACKUP: 'incremental_backup',
    IS_ADDITIONAL_IP_AVAILABLE: 'is_additional_ips_available',
    ADDITIONAL_IP_TOKENS: 'additional_ip_tokens',
    ISO_IMAGE_TOKENS: 'iso_image_tokens',
    DISK_LIMITS: 'disk_limits',
    NETWORK_LIMITS: 'network_limits',
    VZ_PARAMETERS: 'vz_parameters',
    ADDITIONAL_DISK_LIMIT: 'additional_disk_limit',
};

export const PlanForm: React.FC<PlanFormProps> = ({
    storageTypeOptions,
    isItemSaving,
    isLoadingStorageTypes,
    isLoadingLocations,
    isLoadingOSImages,
    isLoadingApplications,
    formErrors,
    storageTypes,
    isBillingIntegrationEnabled,
    onSubmit,
    formErrorsActions: {
        clearFormErrors,
        setFormErrors,
    },
    storageTypeActions: {
        getStorageTypes,
    },
    locationActions: {
        getLocations,
    },
    osImageActions: {
        getOsImages,
    },
    applicationActions: {
        getApplications,
    },
    initialState,
    minDisk,
    dataUnit = DataUnit.GiB,
    submitButton = <Translate content="plan.actionDialog.saveBtn" />,
    fields,
}) => {
    React.useEffect(() => {
        getStorageTypes();

        return () => {
            clearFormErrors();
            clearResponseError();
        };
    }, [clearFormErrors, getStorageTypes]);

    const [submitValues, setSubmitValues] = React.useState<PlanRequest>({
        ...initialState,
        // @ts-ignore
        available_locations: fields.includes(FIELDS.AVAILABLE_LOCATIONS)
            ? initialState.available_locations?.map(location => location.id)
            : undefined,
        // @ts-ignore
        available_os_image_versions: fields.includes(FIELDS.AVAILABLE_OS_IMAGE_VERSIONS)
            ? initialState.available_os_image_versions?.map(version => version.id)
            : undefined,
        // @ts-ignore
        available_applications: fields.includes(FIELDS.AVAILABLE_APPLICATIONS)
            ? initialState.available_applications?.map(application => application.id)
            : undefined,
    });
    const [minRamValue, setMinRamValue] = React.useState<number>(1);
    const [ramUnit, setRamUnit] = React.useState<string>(dataUnit);
    // Should be synced with 'StorageType::getAvailableByVirtualizationType()' in backend/api/v1/Storage/Enums/StorageType.php
    const MAP_VIRTUALIZATION_TYPE_TO_STORAGE_TYPE = {
        [VirtualizationType.KVM]: [StorageType.FB, StorageType.LVM, StorageType.THIN_LVM, StorageType.NFS],
        [VirtualizationType.VZ]: [StorageType.VZ],
    };

    const handleRamUnitChange = (unit: string) => {
        setRamUnit(unit);

        if (unit === DataUnit.MiB) {
            setSubmitValues(values => ({
                ...values,
                params: {
                    ...values.params,
                    ram: values.params['ram'] * KiB,
                    swap: values.params['swap'] !== undefined ? values.params['swap'] * KiB : undefined,
                },
            }));
            setMinRamValue(128);
        } else {
            setSubmitValues(values => ({
                ...values,
                params: {
                    ...values.params,
                    ram: Math.trunc(submitValues.params['ram'] / KiB),
                    swap: values.params['swap'] !== undefined
                        ? Math.trunc(values.params['swap'] / KiB)
                        : undefined,
                },
            }));
            setMinRamValue(1);
        }
    };

    const handleFieldChange = (field: string, value: string) => setSubmitValues(setIn(submitValues, field, value));
    const handleSubmit = async (values: PlanRequest) => {
        const rules = {};

        if (fields.includes(FIELDS.NAME)) {
            rules['name'] = requiredRule(<Translate content="validate.fieldRequired" />);
        }
        if (fields.includes(FIELDS.STORAGE_TYPE)) {
            rules['storage_type'] = requiredRule(<Translate content="validate.fieldRequired" />);
        }
        if (fields.includes(FIELDS.IMAGE_FORMAT)) {
            rules['image_format'] = requiredRule(<Translate content="validate.fieldRequired" />);
        }
        if (fields.includes(FIELDS.NETWORK_LIMITS)) {
            rules[`limits.${LimitName.NetworkReduceBandwidth}.is_enabled`] = conditionRule(
                <Translate content="plan.actionDialog.limits.network.reduceBandwidthError" />,
                (
                    !values.limits[LimitName.NetworkReduceBandwidth].is_enabled && (
                        values.limits[LimitName.NetworkIncomingTraffic].is_enabled
                        || values.limits[LimitName.NetworkOutgoingTraffic].is_enabled
                        || values.limits[LimitName.NetworkTotalTraffic].is_enabled
                    )
                )
            );
        }

        const errors = validate<IPlanCreateRequest | ComputeResourceVmCustomPlan>(values, rules);

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        const payload = {
            ...values,
            params: {
                ...values.params,
                ram: ramUnit === DataUnit.GiB
                    ? values.params.ram * GiB
                    : values.params.ram * MiB,
                swap: submitValues.params.swap !== undefined
                    ? ramUnit === DataUnit.GiB
                        ? submitValues.params.swap * GiB
                        : submitValues.params.swap * MiB
                    : undefined,
            },
        };

        try {
            await onSubmit(payload);
        } catch (e) {
            throw e;
        }
    };

    const handleChangeVirtualizationType = (option: VirtualizationType) => {
        setSubmitValues(prevState => ({
            ...prevState,
            virtualization_type: option,
            // Reset plan configuration to defaults
            is_backup_available: false,
            is_additional_ips_available: false,
            network_traffic_limit_type: initialKVMPlanRequest.network_traffic_limit_type,
            reset_limit_policy: initialKVMPlanRequest.reset_limit_policy,
            limits: {
                ...initialKVMPlanRequest.limits,
                [LimitName.DiskIops]: prevState.limits[LimitName.DiskIops],
                [LimitName.DiskBandwidth]: prevState.limits[LimitName.DiskBandwidth],
                [LimitName.BackupsNumber]: prevState.limits[LimitName.BackupsNumber],
            },
            available_os_image_versions: fields.includes(FIELDS.AVAILABLE_OS_IMAGE_VERSIONS) ? [] : undefined,
            available_applications: fields.includes(FIELDS.AVAILABLE_APPLICATIONS) ? [] : undefined,
            netfilter: option === VirtualizationType.VZ
                ? {
                    value: VZNetfilterStatus.STATELESS,
                    is_editable: false,
                }
                : undefined,
            tun_tap: option === VirtualizationType.VZ
                ? {
                    value: false,
                    is_editable: false,
                }
                : undefined,
            ppp: option === VirtualizationType.VZ
                ? {
                    value: false,
                    is_editable: false,
                }
                : undefined,
        }));

        handleChangeStorageType(MAP_VIRTUALIZATION_TYPE_TO_STORAGE_TYPE[option][0]);
    };

    const imageFormats = (storageType: StorageType) => {
        const storage = storageTypes.find(item => item.name === storageType);

        if (!storage) {
            return [];
        }

        return storage.formats;
    };

    const isSnapshotsEnabled = (isEnabled: boolean, storageType: StorageType, imageFormat: ImageFormat) =>
        isEnabled && isSnapshotsAvailableFor(storageType, imageFormat);

    const handleChangeStorageType = (option: StorageType) => {
        const formats = imageFormats(option);
        const format = formats.length > 0 ? formats[0] : ImageFormat.RAW;

        setSubmitValues(prevState => ({
            ...prevState,
            is_snapshots_enabled: isSnapshotsEnabled(prevState.is_snapshots_enabled, option, format),
            storage_type: option,
            image_format: format,
        }));
    };

    const handleChangeImageFormat = (option: ImageFormat) => {
        setSubmitValues(prevState => ({
            ...prevState,
            is_snapshots_enabled: isSnapshotsEnabled(prevState.is_snapshots_enabled, prevState.storage_type, option),
            image_format: option,
        }));
    };

    return (
        <>
            <Form
                id="planForm"
                footerClassName="hidden"
                onSubmit={handleSubmit}
                onFieldChange={handleFieldChange}
                values={submitValues}
                errors={formErrors}
                hideRequiredLegend={true}
                vertical={true}
                style={{ maxWidth: '420px' }}
            >
                <Section>
                    {fields.includes(FIELDS.NAME) && (
                        <FormFieldText
                            name="name"
                            size={SIZE.FILL}
                            label={
                                <Translate content="plan.actionDialog.planName" />
                            }
                            required={true}
                        />
                    )}
                    {fields.includes(FIELDS.IS_VISIBLE) && (
                        <FormFieldCheckbox
                            name="is_visible"
                            label={
                                <Translate content="plan.actionDialog.visibility" />
                            }
                        />
                    )}
                    {fields.includes(FIELDS.VIRTUALIZATION_TYPE) && (
                        <FormField
                            value={submitValues.virtualization_type}
                            label={
                                <Translate content="plan.actionDialog.planVirtualizationType" />
                            }
                        >
                            <SegmentedControl
                                buttons={Object.keys(VIRTUALIZATION_TYPE_TRANSLATION_MAP).map(type => ({
                                    title: VIRTUALIZATION_TYPE_TRANSLATION_MAP[type],
                                    value: type as VirtualizationType,
                                }))}
                                selected={submitValues.virtualization_type}
                                data-cy={FORM.VIRTUALIZATION_TYPE}
                                onChange={handleChangeVirtualizationType}
                            />
                        </FormField>
                    )}
                    {fields.includes(FIELDS.STORAGE_TYPE) && (
                        <FormField
                            value={submitValues.storage_type}
                            label={
                                <Translate content="plan.actionDialog.planStorageType" />
                            }
                        >
                            <Loader isLoading={isLoadingStorageTypes} center={false}>
                                <SegmentedControl
                                    buttons={storageTypeOptions.map(option => ({
                                        ...option,
                                        disabled: !MAP_VIRTUALIZATION_TYPE_TO_STORAGE_TYPE[submitValues.virtualization_type].includes(option.value),
                                    }))}
                                    selected={submitValues.storage_type}
                                    data-cy={FORM.STORAGE_TYPE}
                                    onChange={handleChangeStorageType}
                                />
                            </Loader>
                        </FormField>
                    )}
                    {fields.includes(FIELDS.IMAGE_FORMAT) && (
                        <FormField
                            label={
                                <Translate content="plan.actionDialog.planImageFormat" />
                            }
                        >
                            <Loader isLoading={isLoadingStorageTypes} center={false}>
                                <SegmentedControl
                                    buttons={Object.values(ImageFormat).map((format) => ({
                                        title: format,
                                        value: format,
                                        disabled: !imageFormats(submitValues.storage_type).includes(format),
                                    }))}
                                    selected={submitValues.image_format}
                                    onChange={handleChangeImageFormat}
                                    data-cy={FORM.IMAGE_FORMAT}
                                />
                            </Loader>
                        </FormField>
                    )}
                    {isBillingIntegrationEnabled && fields.includes(FIELDS.TOKENS_COLUMNS) && <TokensColumns />}
                    <ParamsColumns
                        virtualizationType={submitValues.virtualization_type}
                        minRam={minRamValue}
                        ramUnit={ramUnit}
                        setRamUnit={handleRamUnitChange}
                        minDisk={minDisk}
                        fields={fields}
                    />
                </Section>
                {fields.includes(FIELDS.AVAILABLE_LOCATIONS) && (
                    <SelectWithDataLoader
                        name="available_locations"
                        label="plan.actionDialog.availableEntities.locations"
                        buttonLabel="plan.actionDialog.availableEntities.addAllLocations"
                        loadItems={getLocations}
                        mapper={(location: ILocationResponse) => ({
                            label: location.name,
                            value: location.id,
                        })}
                        onChange={(available_locations: number[]) => setSubmitValues(values => ({
                            ...values,
                            available_locations,
                        }))}
                        values={
                            initialState.available_locations?.map(location => ({
                                label: location.name,
                                value: location.id,
                            }))
                        }
                    />
                )}
                {fields.includes(FIELDS.AVAILABLE_OS_IMAGE_VERSIONS) && (
                    <SelectWithDataLoader
                        name="available_os_image_versions"
                        label="plan.actionDialog.availableEntities.osImageVersions"
                        buttonLabel="plan.actionDialog.availableEntities.addAllOsImageVersions"
                        loadItems={(params?: IOsImageListRequest) => getOsImages({
                            ...params,
                            filters: {
                                ...params?.filters,
                                virtualization_type: submitValues.virtualization_type,
                            },
                        })}
                        mapper={(image: IOsImageResponse) => ({
                            label: image.name,
                            options: image.versions.map((version) => ({
                                label: version.version,
                                value: version.id,
                            })),
                        })}
                        onChange={(available_os_image_versions: number[]) => setSubmitValues(values => ({
                            ...values,
                            available_os_image_versions,
                        }))}
                        resetItems={submitValues.virtualization_type !== initialKVMPlanRequest.virtualization_type}
                        values={
                            initialState.available_os_image_versions?.map(osImageVersion => ({
                                label: osImageVersion.name,
                                value: osImageVersion.id,
                            }))
                        }
                    />
                )}
                {fields.includes(FIELDS.AVAILABLE_APPLICATIONS) && (
                    <SelectWithDataLoader
                        name="available_applications"
                        label="plan.actionDialog.availableEntities.applications"
                        buttonLabel="plan.actionDialog.availableEntities.addAllApplications"
                        loadItems={getApplications}
                        mapper={(application: IApplicationResponse) => ({
                            label: application.name,
                            value: application.id,
                        })}
                        onChange={(available_applications: number[]) => setSubmitValues(values => ({
                            ...values,
                            available_applications,
                        }))}
                        resetItems={submitValues.virtualization_type !== initialKVMPlanRequest.virtualization_type}
                        disabled={submitValues.virtualization_type === VirtualizationType.VZ}
                        values={
                            initialState.available_applications?.map(application => ({
                                label: application.name,
                                value: application.id,
                            }))
                        }
                    />
                )}
                <AdditionalOffersSection
                    is_backup_available={submitValues.is_backup_available}
                    is_additional_ips_available={submitValues.is_additional_ips_available}
                    isIncrementalBackupsAvailable={true}
                    storageType={submitValues.storage_type}
                    backupsNumber={submitValues.limits[LimitName.BackupsNumber]}
                    backupSettings={submitValues.backup_settings}
                    isBillingIntegrationEnabled={isBillingIntegrationEnabled}
                    fields={fields}
                />
                {fields.includes(FIELDS.IS_SNAPSHOTS_ENABLED) && (
                    <Section>
                        <FormFieldCheckbox
                            disabled={!isSnapshotsAvailableFor(submitValues.storage_type, submitValues.image_format)}
                            label={<Translate content="plan.actionDialog.snapshots" />}
                            name="is_snapshots_enabled"
                        />
                        {fields.includes(FIELDS.SNAPSHOT_LIMITS) && (
                            <SubInputs>
                                <PlanLimit
                                    data-cy={FORM.SNAPSHOT_LIMITS.COUNT}
                                    disabled={!submitValues.is_snapshots_enabled}
                                    label={<Translate content="plans.actionDialog.limits.snapshots.count.label" />}
                                    limit={submitValues.limits[LimitName.SnapshotsCount]}
                                    limitName={LimitName.SnapshotsCount}
                                    units={Unit}
                                />
                                <PlanLimit
                                    data-cy={FORM.SNAPSHOT_LIMITS.SIZE}
                                    disabled={!submitValues.is_snapshots_enabled}
                                    fullDescription={<Translate content="plans.actionDialog.limits.snapshots.size.description" />}
                                    label={<Translate content="plans.actionDialog.limits.snapshots.size.label" />}
                                    limit={submitValues.limits[LimitName.SnapshotsSize]}
                                    limitName={LimitName.SnapshotsSize}
                                    units={DataUnit}
                                />
                            </SubInputs>
                        )}
                        {fields.includes(FIELDS.SNAPSHOT_LIMITS) && (
                            <PlanLimit
                                data-cy={FORM.ADDITIONAL_DISK_LIMIT}
                                disabled={submitValues.virtualization_type === VirtualizationType.VZ}
                                limit={submitValues.limits[LimitName.AdditionalDiskNumber]}
                                limitName={LimitName.AdditionalDiskNumber}
                                limitOnly={true}
                                units={Unit}
                                max={MAX_ADDITIONAL_DISK_COUNT_ON_SERVER}
                                label={
                                    <Translate content="plan.actionDialog.limits.additionalDiskNumber" />
                                }
                            />
                        )}
                    </Section>
                )}
                {fields.includes(FIELDS.DISK_LIMITS) && (
                    <Section
                        title={
                            <Translate content="plan.actionDialog.diskLimits" />
                        }
                        collapsible={true}
                        collapsed={true}
                        data-cy={FORM.DISK_LIMITS_SECTION}
                    >
                        <PlanLimit
                            data-cy={FORM.DISK_LIMITS.BANDWIDTH}
                            limit={submitValues.limits[LimitName.DiskBandwidth]}
                            limitName={LimitName.DiskBandwidth}
                            units={DiskBandwidthUnit}
                            label={
                                <Translate content="plan.actionDialog.limits.disk.bandwidth" />
                            }
                        />
                        <PlanLimit
                            data-cy={FORM.DISK_LIMITS.IOPS}
                            limit={submitValues.limits[LimitName.DiskIops]}
                            limitName={LimitName.DiskIops}
                            units={IOpsUnit}
                            label={
                                <Translate content="plan.actionDialog.limits.disk.iops" />
                            }
                        />
                    </Section>
                )}
                {fields.includes(FIELDS.NETWORK_LIMITS) && (
                    <NetworkLimitsSection
                        limits={submitValues.limits}
                        limitType={submitValues.network_traffic_limit_type}
                    />
                )}
                {fields.includes(FIELDS.VZ_PARAMETERS) && (
                    <VZParametersSection
                        disabled={submitValues.virtualization_type === VirtualizationType.KVM}
                    />
                )}
            </Form>
            <Button
                type="submit"
                form="planForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size={SIZE.LG}
                disabled={isLoadingLocations || isLoadingApplications || isLoadingOSImages}
                isLoading={isItemSaving}
            >
                {submitButton}
            </Button>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    formErrors: nestStringProperties(state),
    storageTypes: state.storageType,
    storageTypeOptions: state.storageType.map(storage => ({
        title: STORAGE_TYPES_TRANSLATION_MAP[storage.name],
        value: storage.name,
    })),
    isLoadingLocations: state.app.loadingFlags.has(LOADING_FLAGS.LOCATION_LIST),
    isLoadingOSImages: state.app.loadingFlags.has(LOADING_FLAGS.OS_IMAGE_LIST),
    isLoadingApplications: state.app.loadingFlags.has(LOADING_FLAGS.APPLICATION_LIST),
    isItemSaving: state.app.loadingFlags.has(LOADING_FLAGS.SAVE_PLAN_ITEM),
    isLoadingStorageTypes: state.app.loadingFlags.has(LOADING_FLAGS.STORAGE_TYPE_LIST),
    isBillingIntegrationEnabled: state.settings.billing_integration.type !== BillingType.NULL,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
    storageTypeActions: bindActionCreators(storageTypeActions, dispatch),
    locationActions: bindActionCreators(locationActions, dispatch),
    osImageActions: bindActionCreators(osImageActions, dispatch),
    applicationActions: bindActionCreators(applicationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanForm);
