// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { Dispatch } from 'redux';
import { bakeForegroundToast } from 'common/modules/app/toaster/actions';
import { INTENT_TYPE } from 'common/constants';
import { CancelTokenSource } from 'axios';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { createCustomAction } from 'typesafe-actions';
import * as types from 'common/modules/computeResourceVm/constants/types';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import { HTTP_CODES } from 'common/api/constants';
import { getLocations } from 'common/modules/location/actions';
import { getOsImages } from 'common/modules/osImage/actions';
import { getApplications } from 'common/modules/application/actions';
import { getSshKeys } from 'common/modules/sshKey/actions';
import { clearFormErrors } from 'common/modules/app/formErrors/actions';
import { appendTask } from 'admin/task/actions';
import { getProjects } from 'admin/user/actions/projects';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import {
    get,
    nestedList,
    removeBatch,
} from 'common/actions/actionsWrapper';
import {
    BootMode,
    computeResourceVms,
    ComputeResourceVmStatus,
    IAdditionalDiskRequest,
    IAdditionalDiskResizeRequest,
    IAdditionalDiskUpdateRequest,
    IAdditionalIpRequest,
    IBackupSettings,
    IComputeResourceVmBackupListRequest,
    IComputeResourceVmListRequest,
    IIpV4,
    IMoveComputeResourceVm,
    IServerLimitResponse,
    IVmActionRequest,
    IVmCreateRequest,
    IVmDiskResponse,
    IVmReinstallRequest,
    IVmResizeRequest,
    IVmResponse,
    IVmUpdateRequest,
    VirtualServerSettingsRequest,
} from 'common/api/resources/ComputeResourceVm';
import {
    IReverseDnsCreateRequest,
    IReverseDnsPatchRequest,
    IReverseDnsResponse,
    reverseDns,
} from 'common/api/resources/ReverseDns';
import {
    addItem,
    setList,
} from 'common/modules/backup/actions';
import {
    getPlans,
    getProjectPlans,
} from 'common/modules/plan/actions';
import {
    IProjectCreateVmRequest,
    projects,
} from 'common/api/resources/Project';
import {
    getProject,
    getProjectSshKeys,
    getProjectTokenPricing,
} from 'common/modules/project/actions';
import { IAppState } from 'admin/core/store';
import { getComputeResources } from 'admin/computeResource/actions';
import { TASK_STATUS } from 'common/api/resources/Task';
import { getOffers } from 'common/modules/offer/actions';
import { ICommonState } from 'common/store';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';

export const setVms = createCustomAction(
    types.SET_COMPUTE_RESOURCE_VMS,
    (data: IPaginateApiResponse<IVmResponse[]>) => ({ payload: data })
);
export const setVm = createCustomAction(
    types.SET_COMPUTE_RESOURCE_VM,
    (data: IVmResponse) => ({ payload: data })
);
export const unsetVm = createCustomAction(types.UNSET_COMPUTE_RESOURCE_VM);
export const updateVm = createCustomAction(
    types.UPDATE_COMPUTE_RESOURCE_VM,
    (data: Partial<IVmResponse>) => ({ payload: data })
);
// todo refactor https://webpros.atlassian.net/browse/SIO-2993
export const updateVmDiskUsage = createCustomAction(
    types.UPDATE_COMPUTE_RESOURCE_VM_DISK_USAGE,
    (data: {id: number; size: number}) => ({ payload: data })
);

export const moveVm = createCustomAction(
    types.MOVE_COMPUTE_RESOURCE_VM,
    (data: IMoveComputeResourceVm) => ({ payload: data })
);
export const addVm = createCustomAction(
    types.ADD_COMPUTE_RESOURCE_VM,
    (data: IVmResponse) => ({ payload: data })
);
export const deleteVm = createCustomAction(
    types.DELETE_COMPUTE_RESOURCE_VM,
    (id: number) => ({ payload: id })
);
export const deleteVmItems = createCustomAction(
    types.DELETE_COMPUTE_RESOURCE_VMS,
    (ids: number[]) => ({ payload: ids })
);
export const setVmIsLoading = createCustomAction(
    types.SET_VM_IS_LOADING,
    (id: number) => ({ payload: id })
);
export const unsetVmIsLoading = createCustomAction(
    types.UNSET_VM_IS_LOADING,
    (id: number) => ({ payload: id })
);
export const setVmItemIsDeleting = createCustomAction(
    types.SET_COMPUTE_RESOURCE_VM_ITEM_IS_DELETING,
    (id: number, isDeleting: boolean) => ({ payload: { id, isDeleting } })
);
export const updateVmProgress = createCustomAction(
    types.UPDATE_COMPUTE_RESOURCE_VM_PROGRESS,
    (id: number, progress: number) => ({ payload: { id, progress } })
);

export const addVmIpV6ReverseDns = createCustomAction(
    types.ADD_COMPUTE_RESOURCE_VM_IPV6_REVERSE_DNS,
    (data: IReverseDnsResponse) => ({ payload: data })
);
export const deleteVmIpV6ReverseDns = createCustomAction(
    types.DELETE_COMPUTE_RESOURCE_VM_IPV6_REVERSE_DNS,
    (id: number) => ({ payload: id })
);
export const updateVmReverseDns = createCustomAction(
    types.UPDATE_COMPUTE_RESOURCE_VM_REVERSE_DNS,
    (id: number, domain: Partial<IReverseDnsResponse>) => ({ payload: { id, domain } })
);
export const createVmAdditionalIp = createCustomAction(
    types.ADD_COMPUTE_RESOURCE_VM_ADDITIONAL_IP,
    (data: IIpV4) => ({ payload: data })
);
export const deleteVmAdditionalIp = createCustomAction(
    types.DELETE_COMPUTE_RESOURCE_VM_ADDITIONAL_IP,
    (id: number) => ({ payload: id })
);
export const updatePrimaryIp = createCustomAction(
    types.COMPUTE_RESOURCE_VM_CHANGE_PRIMARY_IP,
    (id: number) => ({ payload: id })
);
export const setNewPassword = createCustomAction(
    types.SET_NEW_PASSWORD,
    (id: number, new_password: string) => ({ payload: { id, new_password } })
);
export const setServerLimits = createCustomAction(
    types.SET_COMPUTE_RESOURCE_VM_LIMITS,
    (data: IServerLimitResponse[]) => ({ payload: data })
);

export const setVmDisks = createCustomAction(
    types.SET_COMPUTE_RESOURCE_VM_DISKS,
    (data: IVmDiskResponse[]) => ({ payload: data })
);
export const createVmAdditionalDisk = createCustomAction(
    types.ADD_COMPUTE_RESOURCE_VM_DISK,
    (data: IVmDiskResponse) => ({ payload: data })
);
export const updateVmAdditionalDisk = createCustomAction(
    types.UPDATE_COMPUTE_RESOURCE_VM_DISK,
    (data: IVmDiskResponse) => ({ payload: data })
);
export const deleteVmAdditionalDisk = createCustomAction(
    types.DELETE_COMPUTE_RESOURCE_VM_DISK,
    (id: number) => ({ payload: id })
);
export const setVmAdditionalDiskIsDeleting = createCustomAction(
    types.SET_COMPUTE_RESOURCE_VM_DISK_ITEM_IS_DELETING,
    (id: number, isDeleting: boolean) => ({ payload: { id, isDeleting } })
);

export const getComputeResourceVms = (request?: IComputeResourceVmListRequest, cancelToken?: CancelTokenSource) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_LIST));

    try {
        const result = await computeResourceVms.list(request, cancelToken);
        if (result.status === HTTP_CODES.OK) {
            dispatch(setVms(result.data));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_LIST));
    }
};

export const createProjectServer = (id: number, data: IProjectCreateVmRequest) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.CREATE_COMPUTE_RESOURCE_VM));
    try {
        await projects.createVm(id, data);
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.CREATE_COMPUTE_RESOURCE_VM));
    }
};

export const createServer = (data: IVmCreateRequest) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.CREATE_COMPUTE_RESOURCE_VM));
    try {
        const result = await computeResourceVms.create(data);
        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'computeResource.servers.itemCreateSuccess')(dispatch);
        dispatch(clearFormErrors());
        dispatch(addVm(result.data.data));

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.CREATE_COMPUTE_RESOURCE_VM));
    }
};

export const updateComputeResourceVm = (id: number, data: IVmUpdateRequest) => async(dispatch: Dispatch) => {
    dispatch(setVmIsLoading(id));
    dispatch(setIsLoading(LOADING_FLAGS.PROJECT_VM_ITEM));

    try {
        const result = await computeResourceVms.update(id, data);

        if (result.status === HTTP_CODES.OK) {
            dispatch(updateVm(result.data.data));
            bakeForegroundToast(INTENT_TYPE.SUCCESS, 'computeResource.servers.toasts.serverUpdated')(dispatch);
        }
    } finally {
        dispatch(unsetVmIsLoading(id));
        dispatch(unsetIsLoading(LOADING_FLAGS.PROJECT_VM_ITEM));
    }
};

export const updateBootMode = (id: number, bootMode: BootMode, isoImageId?: number) => async(dispatch: Dispatch, getState: () => IAppState) => {
    const state = getState();

    try {
        dispatch(updateVm({ id, boot_mode: bootMode }));
        dispatch(setIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_SWITCH_BOOT_MODE));

        const result = await computeResourceVms.update(id, {
            boot_mode: bootMode,
            iso_image_id: isoImageId,
        });

        dispatch(updateVm(result.data.data));
    } catch (e) {
        dispatch(updateVm({
            id: state.computeResourceVm.item.id,
            boot_mode: state.computeResourceVm.item.boot_mode,
        }));
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_SWITCH_BOOT_MODE));
    }
};

export const restartComputeResourceVm = (
    vmId: number,
    data: IVmActionRequest
) => async(dispatch: Dispatch) => {
    const result = await computeResourceVms.restartVm(vmId, data);
    dispatch(appendTask(result.data.data));
    dispatch(updateVm({
        id: vmId,
        is_processing: true,
        status: ComputeResourceVmStatus.RESTARTING,
    }));
};

export const startComputeResourceVm = (vmId: number) => async(dispatch: Dispatch) => {
    const result = await computeResourceVms.startVm(vmId);
    dispatch(appendTask(result.data.data));
    dispatch(updateVm({
        id: vmId,
        is_processing: true,
        status: ComputeResourceVmStatus.STARTING,
    }));
};

export const stopComputeResourceVm = (
    vmId: number,
    data: IVmActionRequest
) => async(dispatch: Dispatch) => {
    const result = await computeResourceVms.stopVm(vmId, data);
    dispatch(appendTask(result.data.data));
    dispatch(updateVm({
        id: vmId,
        is_processing: true,
        status: ComputeResourceVmStatus.STOPPING,
    }));
};

export const resizeComputeResourceVm = (
    vmId: number,
    data: IVmResizeRequest
) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_RESIZE));

    try {
        const result = await computeResourceVms.resizeVm(vmId, data);
        dispatch(appendTask(result.data.data));
        if (result.data.data.status === TASK_STATUS.QUEUED
            || result.data.data.status === TASK_STATUS.PENDING
            || result.data.data.status === TASK_STATUS.RUNNING
        ) {
            dispatch(updateVm({
                id: vmId,
                is_processing: true,
                status: ComputeResourceVmStatus.RESIZING,
            }));
        }
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_RESIZE));
    }
};

export const resetComputeResourceVmUsage = (vmId: number) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_RESET_USAGE));

    try {
        const result = await computeResourceVms.resetUsage(vmId);
        dispatch(appendTask(result.data.data));
        dispatch(updateVm({
            id: vmId,
            is_processing: true,
            status: ComputeResourceVmStatus.USAGE_RESETTING,
        }));
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_RESET_USAGE));
    }
};

export const deleteComputeResourceVm = (id: number, removeFromList = true) => async(dispatch: Dispatch) => {
    dispatch(setVmItemIsDeleting(id, true));
    try {
        await computeResourceVms.remove(id);

        if (removeFromList) {
            dispatch(deleteVm(id));
        }
    } finally {
        dispatch(setVmItemIsDeleting(id, false));
    }
};

// sendPasswordToCurrentUser is always true in UI, because we need to send an email to current user.
export const resetPassword = (id: number, sendPasswordToCurrentUser: boolean = true) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.RESET_COMPUTE_RESOURCE_VM_PASSWORD));
    try {
        const result = await computeResourceVms.resetPassword(id, {
            send_password_to_current_user: sendPasswordToCurrentUser,
        });

        dispatch(updateVm({
            id,
            status: ComputeResourceVmStatus.CHANGING_PASSWORD,
            is_processing: true,
        }));

        return result.data.url;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.RESET_COMPUTE_RESOURCE_VM_PASSWORD));
    }
};

export const installGuestTools = (id: number) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_INSTALL_GUEST_TOOLS));

    try {
        const result = await computeResourceVms.installGuestTools(id);

        dispatch(appendTask(result.data.data));
        dispatch(updateVm({
            id,
            status: ComputeResourceVmStatus.INSTALLING_GUEST_TOOLS,
            is_processing: true,
        }));
    } finally { // TODO: later change to socket.io listener https://webpros.atlassian.net/projects/SIO/issues/SIO-4955
        dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_INSTALL_GUEST_TOOLS));
    }
};

export const batchInstallGuestTools = (ids: number[]) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_BATCH_INSTALL_GUEST_TOOLS));

    try {
        const result = await computeResourceVms.installGuestToolsBatch(ids);

        if (result.status === HTTP_CODES.OK) {
            bakeForegroundToast(
                INTENT_TYPE.SUCCESS,
                'computeResource.servers.batchActions.installGuestTools.done',
                { count: result.data.length }
            )(dispatch);
        }
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_BATCH_INSTALL_GUEST_TOOLS));
    }
};

export const getComputeResourceVm = (id: number) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM));
    dispatch(setVmIsLoading(id));

    try {
        const result = await computeResourceVms.item(id);
        if (result.status === HTTP_CODES.OK) {
            dispatch(setVm(result.data.data));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM));
        dispatch(unsetVmIsLoading(id));
    }
};

export const startComputeResourceVms = (ids: number[]) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_BATCH_START));
    try {
        const result = await computeResourceVms.startBatch(ids);
        if (result.status === HTTP_CODES.OK) {
            bakeForegroundToast(
                INTENT_TYPE.SUCCESS,
                'computeResource.servers.batchActions.started',
                { count: result.data.length }
            )(dispatch);
        }
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_BATCH_START));
    }
};

export const restartComputeResourceVms = (
    ids: number[],
    data: IVmActionRequest
) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_BATCH_RESTART));
    try {
        const result = await computeResourceVms.restartBatch(ids, data);
        if (result.status === HTTP_CODES.OK) {
            bakeForegroundToast(
                INTENT_TYPE.SUCCESS,
                'computeResource.servers.batchActions.restarted',
                { count: result.data.length }
            )(dispatch);
        }
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_BATCH_RESTART));
    }
};

export const stopComputeResourceVms = (
    ids: number[],
    data: IVmActionRequest
) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_BATCH_STOP));
    try {
        const result = await computeResourceVms.stopBatch(ids, data);
        if (result.status === HTTP_CODES.OK) {
            bakeForegroundToast(
                INTENT_TYPE.SUCCESS,
                'computeResource.servers.batchActions.stopped',
                { count: result.data.length }
            )(dispatch);
        }
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_BATCH_STOP));
    }
};

export const deleteComputeResourceVms = (ids: number[]) => async(dispatch: Dispatch) => await removeBatch(ids, {
    dispatch,
    apiCall: computeResourceVms.removeBatch,
    action: deleteVmItems,
    loadingFlag: LOADING_FLAGS.COMPUTE_RESOURCE_VM_BATCH_DELETE,
    translations: {
        success: 'computeResource.servers.batchActions.deleted',
    },
});

export const reinstallComputeResourceVm = (id: number, data: IVmReinstallRequest) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.REINSTALL_COMPUTE_RESOURCE_VM));
    try {
        const result = await computeResourceVms.reinstall(id, data);

        if (result.status === HTTP_CODES.OK) {
            dispatch(updateVm(result.data.data));
        }

        dispatch(clearFormErrors());

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.REINSTALL_COMPUTE_RESOURCE_VM));
    }
};

export const loadComputeResourceVmReinstallPageData = () => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_REINSTALL));

    try {
        await Promise.all([
            getOsImages()(dispatch),
            getApplications()(dispatch),
            getSshKeys()(dispatch),
        ]);
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_REINSTALL));
    }
};

export const getProjectVms = (id: number, params?: IComputeResourceVmListRequest, cancelToken?: CancelTokenSource) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.PROJECT_VM_LIST));

    try {
        const result = await projects.vmList(id, params, cancelToken);
        if (result.status === HTTP_CODES.OK) {
            dispatch(setVms(result.data));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.PROJECT_VM_LIST));
    }
};

export const loadReinstallServerPageData = (projectId: number) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.PROJECT_REINSTALL_SERVER));

    try {
        await Promise.all([
            getOsImages()(dispatch),
            getApplications()(dispatch),
        ]);

        await getProjectSshKeys(projectId)(dispatch);
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.PROJECT_REINSTALL_SERVER));
    }
};

export const updateBackupSettings = (id: number, settings: IBackupSettings) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_BACKUP_SETTINGS_UPDATE));
    try {
        const result = await computeResourceVms.update(id, {
            backup_settings: settings,
        });
        dispatch(updateVm(result.data.data));
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_BACKUP_SETTINGS_UPDATE));
    }
};

export const getBackups = (
    id: number,
    request?: IComputeResourceVmBackupListRequest,
    cancelToken?: CancelTokenSource
) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.BACKUP_LIST));

    try {
        const result = await computeResourceVms.backups.list(id, request, cancelToken);
        if (result.status === HTTP_CODES.OK) {
            dispatch(setList(result.data));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.BACKUP_LIST));
    }
};

export const createBackup = (id: number) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_BACKUP_CREATE));

    try {
        const result = await computeResourceVms.backups.create(id);

        if ([HTTP_CODES.CREATED, HTTP_CODES.OK].includes(result.status)) {
            dispatch(addItem(result.data.data));
            dispatch(updateVm({
                id,
                is_processing: true,
                progress: 0,
                status: ComputeResourceVmStatus.BACKUP_CREATING,
            }));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_BACKUP_CREATE));
    }
};

export const createIpV6ReverseDns = (domain: IReverseDnsCreateRequest) => async(dispatch: Dispatch) =>  {
    try {
        const result = await reverseDns.create(domain);

        dispatch(addVmIpV6ReverseDns(result.data.data));

        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'servers.tabs.networking.reverseDns.toasts.created')(dispatch);

        return result;
    } catch (e) {
        throw e;
    }
};

export const updateReverseDns = (id: number, domain: Partial<IReverseDnsPatchRequest>) => async(dispatch: Dispatch) => {
    try {
        const result = await reverseDns.patch(id, domain);

        dispatch(updateVmReverseDns(id, result.data.data));

        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'servers.tabs.networking.reverseDns.toasts.updated')(dispatch);

        return result;
    } catch (e) {
        throw e;
    }
};

export const deleteIpV6ReverseDns = (id: number) => async(dispatch: Dispatch) => {
    try {
        await reverseDns.remove(id);

        dispatch(deleteVmIpV6ReverseDns(id));

        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'servers.tabs.networking.reverseDns.toasts.removed')(dispatch);
    } catch (e) {
        throw e;
    }
};

export const createAdditionalIp = (
    id: number,
    status: ComputeResourceVmStatus,
    request: IAdditionalIpRequest = {}
) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_ADDITIONAL_IP_ADDING));
    dispatch(updateVm({
        id,
        is_processing: true,
        status: ComputeResourceVmStatus.ADDITIONAL_IP_ADDING,
    }));

    try {
        const result = await computeResourceVms.ips.create(id, request);
        if (result.status === HTTP_CODES.CREATED) {
            dispatch(appendTask(result.data.data));
            dispatch(clearFormErrors());
        }
    } catch (e) {
        dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_ADDITIONAL_IP_ADDING));
        dispatch(updateVm({
            id,
            is_processing: false,
            status,
        }));
        throw e;
    }
};

export const commitAdditionalIpCreatingSuccess = (ip: IIpV4) => (dispatch: Dispatch) => {
    bakeForegroundToast(INTENT_TYPE.SUCCESS, 'servers.tabs.networking.additionalIps.toasts.created')(dispatch);
    dispatch(createVmAdditionalIp(ip));
    dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_ADDITIONAL_IP_ADDING));
};

export const commitAdditionalIpCreatingFail = (ipId: number) => (dispatch: Dispatch) => {
    bakeForegroundToast(INTENT_TYPE.DANGER, 'servers.tabs.networking.additionalIps.toasts.creatingFails')(dispatch);
    dispatch(deleteVmAdditionalIp(ipId));
    dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_ADDITIONAL_IP_ADDING));
};

export const deleteAdditionalIp = (
    serverId: number,
    serverStatus: ComputeResourceVmStatus,
    ipId: number
) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_ADDITIONAL_IP_DELETING));
    dispatch(updateVm({
        id: serverId,
        is_processing: true,
        status: ComputeResourceVmStatus.ADDITIONAL_IP_DELETING,
    }));

    try {
        const result = await computeResourceVms.ips.remove(serverId, ipId);
        if (result.status === HTTP_CODES.OK) {
            dispatch(appendTask(result.data.data));
        }
    } catch (e) {
        dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_ADDITIONAL_IP_DELETING));
        dispatch(updateVm({
            id: serverId,
            is_processing: false,
            status: serverStatus,
        }));
        throw e;
    }
};

export const commitAdditionalIpDeletingSuccess = (ipId: number) => (dispatch: Dispatch) => {
    bakeForegroundToast(INTENT_TYPE.SUCCESS, 'servers.tabs.networking.additionalIps.toasts.removed')(dispatch);
    dispatch(deleteVmAdditionalIp(ipId));
    dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_ADDITIONAL_IP_DELETING));
};

export const commitAdditionalIpDeletingFail = () => (dispatch: Dispatch) => {
    bakeForegroundToast(INTENT_TYPE.DANGER, 'servers.tabs.networking.additionalIps.toasts.removingFails')(dispatch);
    dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_ADDITIONAL_IP_DELETING));
};

export const changePrimaryIp = (
    serverId: number,
    serverStatus: ComputeResourceVmStatus,
    newPrimaryIpId: number
) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_CHANGE_PRIMARY_IP));
    dispatch(updateVm({
        id: serverId,
        is_processing: true,
        status: ComputeResourceVmStatus.PRIMARY_IP_CHANGING,
    }));

    try {
        const result = await computeResourceVms.ips.updateIps(serverId, {
            primary_id: newPrimaryIpId,
        });
        dispatch(appendTask(result.data.data));
    } catch (e) {
        dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_CHANGE_PRIMARY_IP));
        dispatch(updateVm({
            id: serverId,
            is_processing: false,
            status: serverStatus,
        }));
        throw e;
    }
};

export const commitPrimaryIpChangingSuccess = (ipId: number) => (dispatch: Dispatch) => {
    bakeForegroundToast(INTENT_TYPE.SUCCESS, 'servers.tabs.networking.additionalIps.toasts.primaryIpChanged')(dispatch);
    dispatch(updatePrimaryIp(ipId));
    dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_CHANGE_PRIMARY_IP));
};

export const commitPrimaryIpChangingFail = () => (dispatch: Dispatch) => {
    bakeForegroundToast(INTENT_TYPE.DANGER, 'servers.tabs.networking.additionalIps.toasts.primaryIpChangingFails')(dispatch);
    dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_CHANGE_PRIMARY_IP));
};

export const updateComputeResourceVmSettings = (id: number, data: VirtualServerSettingsRequest) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_UPDATE_SETTINGS));

    try {
        const result = await computeResourceVms.settings.patch(id, data);

        if (result.status === HTTP_CODES.OK) {
            dispatch(updateVm({
                id,
                status: ComputeResourceVmStatus.UPDATING,
                is_processing: true,
            }));
            dispatch(appendTask(result.data.data));
        }
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_UPDATE_SETTINGS));
    }
};

export const loadCreateServerPageData = (id: number) => async(dispatch: Dispatch, getState: () => ICommonState) => {
    dispatch(setIsLoading(LOADING_FLAGS.PROJECT_CREATE_SERVER));

    try {
        const initialRequests: Array<Promise<unknown>> = [
            getProject(id)(dispatch),
            getProjectPlans(id)(dispatch),
            getLocations()(dispatch),
            getOsImages()(dispatch),
            getApplications()(dispatch),
        ];

        if (hasPermission(getState(), PERMISSION_LIST.GET_OFFERS) || hasPermission(getState(), PERMISSION_LIST.MANAGE_OFFERS)) {
            initialRequests.push(getOffers()(dispatch));
        }

        await Promise.all(initialRequests);

        await Promise.all([
            getProjectSshKeys(id)(dispatch),
            getProjectTokenPricing(id)(dispatch),
        ]);
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.PROJECT_CREATE_SERVER));
    }
};

export const loadComputeResourceVmCreatePageData = (userId: number) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_CREATE));

    try {
        await Promise.all([
            getPlans()(dispatch),
            getOffers()(dispatch),
            getLocations()(dispatch),
            getProjects(userId)(dispatch),
            getOsImages()(dispatch),
            getApplications()(dispatch),
            getSshKeys()(dispatch),
            getComputeResources()(dispatch),
        ]);
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_CREATE));
    }
};

export const suspend = (id: number, status: ComputeResourceVmStatus) => async (dispatch: Dispatch) => {
    dispatch(updateVm({
        id,
        is_processing: true,
        status: ComputeResourceVmStatus.SUSPENDING,
    }));

    try {
        const result = await computeResourceVms.suspend(id);
        dispatch(appendTask(result.data.data));
    } catch (e) {
        dispatch(updateVm({
            id,
            is_processing: false,
            status,
        }));
        throw e;
    }
};

export const resume = (id: number, status: ComputeResourceVmStatus) => async (dispatch: Dispatch) => {
    dispatch(updateVm({
        id,
        is_processing: true,
        status: ComputeResourceVmStatus.RESUMING,
    }));

    try {
        const result = await computeResourceVms.resume(id);
        dispatch(appendTask(result.data.data));
    } catch (e) {
        dispatch(updateVm({
            id,
            is_processing: false,
            status,
        }));
        throw e;
    }
};

export const getServerLimits = (id: number) => async (dispatch: Dispatch) => await nestedList(id, {
    dispatch,
    apiCall: computeResourceVms.limits.list,
    action: setServerLimits,
    loadingFlag: LOADING_FLAGS.COMPUTE_RESOURCE_VM_LIMIT_LIST,
});

export const getDisks = (id: number) => async (dispatch: Dispatch) => await get(id, {
    dispatch,
    loadingFlag: LOADING_FLAGS.COMPUTE_RESOURCE_VM_DISK_LIST,
    action: setVmDisks,
    apiCall: computeResourceVms.disks.list,
});

export const addAdditionalDisk = (serverId: number, serverStatus: ComputeResourceVmStatus, data: IAdditionalDiskRequest) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_DISK_ADDING));
    dispatch(updateVm({
        id: serverId,
        is_processing: true,
        status: ComputeResourceVmStatus.ADDITIONAL_DISK_ADDING,
    }));

    try {
        const result = await computeResourceVms.disks.create(serverId, data);
        if (result.status === HTTP_CODES.CREATED) {
            dispatch(appendTask(result.data.data));
            dispatch(clearFormErrors());
        }

        return result;
    } catch (e) {
        dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_DISK_ADDING));
        dispatch(updateVm({
            id: serverId,
            is_processing: false,
            status: serverStatus,
        }));
        throw e;
    }
};

export const commitAdditionalDiskCreatingSuccess = (disk: IVmDiskResponse) => (dispatch: Dispatch) => {
    bakeForegroundToast(INTENT_TYPE.SUCCESS, 'servers.tabs.disks.toasts.added')(dispatch);
    dispatch(createVmAdditionalDisk(disk));
    dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_DISK_ADDING));
};

export const commitAdditionalDiskCreatingFail = () => (dispatch: Dispatch) => {
    bakeForegroundToast(INTENT_TYPE.DANGER, 'servers.tabs.disks.toasts.addFails')(dispatch);
    dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_DISK_ADDING));
};

export const removeAdditionalDisk = (serverId: number, serverStatus: ComputeResourceVmStatus, diskId: number) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_DISK_DELETING));
    dispatch(setVmAdditionalDiskIsDeleting(diskId, true));
    dispatch(updateVm({
        id: serverId,
        is_processing: true,
        status: ComputeResourceVmStatus.ADDITIONAL_DISK_DELETING,
    }));

    try {
        const result = await computeResourceVms.disks.remove(serverId, diskId);
        if (result.status === HTTP_CODES.OK) {
            dispatch(appendTask(result.data.data));
        }
    } catch (e) {
        dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_DISK_DELETING));
        dispatch(updateVm({
            id: serverId,
            is_processing: false,
            status: serverStatus,
        }));
        throw e;
    }
};

export const commitAdditionalDiskDeletingSuccess = (diskId: number) => (dispatch: Dispatch) => {
    bakeForegroundToast(INTENT_TYPE.SUCCESS, 'servers.tabs.disks.toasts.deleted')(dispatch);
    dispatch(deleteVmAdditionalDisk(diskId));
    dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_DISK_DELETING));
};

export const commitAdditionalDiskDeletingFail = (diskId: number) => (dispatch: Dispatch) => {
    bakeForegroundToast(INTENT_TYPE.DANGER, 'servers.tabs.disks.toasts.deleteFails')(dispatch);
    dispatch(setVmAdditionalDiskIsDeleting(diskId, false));
    dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_DISK_DELETING));
};

export const updateAdditionalDisk = (serverId: number, diskId: number, data: IAdditionalDiskUpdateRequest) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_DISK_UPDATING));

    try {
        const result = await computeResourceVms.disks.update(serverId, diskId, data);
        if (result.status === HTTP_CODES.OK) {
            dispatch(clearFormErrors());
            bakeForegroundToast(INTENT_TYPE.SUCCESS, 'servers.tabs.disks.toasts.updated')(dispatch);
            dispatch(updateVmAdditionalDisk(result.data.data));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_DISK_UPDATING));
    }
};

export const resizeAdditionalDisk = (serverId: number, serverStatus: ComputeResourceVmStatus, diskId: number, data: IAdditionalDiskResizeRequest) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_DISK_RESIZING));
    dispatch(updateVm({
        id: serverId,
        is_processing: true,
        status: ComputeResourceVmStatus.ADDITIONAL_DISK_RESIZING,
    }));

    try {
        const result = await computeResourceVms.disks.resize(serverId, diskId, data);
        if (result.status === HTTP_CODES.CREATED) {
            dispatch(appendTask(result.data.data));
            dispatch(clearFormErrors());
        }

        return result;
    } catch (e) {
        dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_DISK_RESIZING));
        dispatch(updateVm({
            id: serverId,
            is_processing: false,
            status: serverStatus,
        }));
        throw e;
    }
};

export const commitAdditionalDiskResizingSuccess = (disk: IVmDiskResponse) => (dispatch: Dispatch) => {
    bakeForegroundToast(INTENT_TYPE.SUCCESS, 'servers.tabs.disks.toasts.resized')(dispatch);
    dispatch(updateVmAdditionalDisk(disk));
    dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_DISK_RESIZING));
};

export const commitAdditionalDiskResizingFail = () => (dispatch: Dispatch) => {
    bakeForegroundToast(INTENT_TYPE.DANGER, 'servers.tabs.disks.toasts.resizeFails')(dispatch);
    dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_VM_DISK_RESIZING));
};
