// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { ISettingsResponse } from 'common/api/resources/Settings';
import {
    Form,
    FormFieldCheckbox,
    FormFieldText,
    setIn,
    Text,
    Translate,
} from '@plesk/ui-library';
import * as settingsActions from 'common/modules/settings/actions';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { initialLanguageState } from 'common/modules/language/reducer';
import { DEFAULT_LOCALE } from 'common/components/LocaleProvider';
import {
    LanguageSelectorContainer,
    SettingsDialogToolbar,
    SettingsSubmitToolbar,
} from 'admin/settings/containers/Settings/Styles';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { Button } from 'admin/common/components/Button/Button';
import {
    IVariable,
    SystemVariablesPopover,
} from 'admin/common/components/SystemVariablesPopover/SystemVariablesPopover';
import LanguageSelector from 'common/containers/LanguageSelector/LanguageSelector';
import {
    IEmailNotification,
    notifications,
} from 'common/api/resources/Notification';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { Loader } from 'common/components';
import { NOTIFICATION_EDIT_DIALOG } from 'admin/settings/constants/tests';

const variables: Record<string, IVariable> = {
    name: {
        variable: 'name',
        description: <Translate content="settings.notifications.variables.hostname" />,
    },
    ipv4: {
        variable: 'ipv4',
        description: <Translate content="settings.notifications.variables.ipv4" />,
    },
    ipv6: {
        variable: 'ipv6',
        description: <Translate content="settings.notifications.variables.ipv6" />,
    },
    user: {
        variable: 'user',
        description: <Translate content="settings.notifications.variables.username" />,
    },
    email: {
        variable: 'email',
        description: <Translate content="settings.notifications.variables.email" />,
    },
    password: {
        variable: 'password',
        description: <Translate content="settings.notifications.variables.password" />,
    },
    passwordResetUrl: {
        variable: 'reset_url',
        description: <Translate content="settings.notifications.variables.passwordResetUrl" />,
    },
    urlExpiresIn: {
        variable: 'count',
        description: <Translate content="settings.notifications.variables.urlExpiresIn" />,
    },
    verifyEmailUrl: {
        variable: 'verify_email_url',
        description: <Translate content="settings.notifications.variables.verifyEmailUrl" />,
    },
    project: {
        variable: 'project',
        description: <Translate content="settings.notifications.variables.project" />,
    },
    projectInviteUrl: {
        variable: 'project_invite_url',
        description: <Translate content="settings.notifications.variables.projectInviteUrl" />,
    },
    recoveryCode: {
        variable: 'recovery_code',
        description: <Translate content="settings.notifications.variables.recoveryCode" />,
    },
};

// Should be synced with $variables in following files for each event
const eventVariablesMap: Record<string, IVariable[]> = {
    // backend/api/v1/ComputeResourceVm/Notifications/VmCreated.php
    server_create: [
        variables.name,
        variables.ipv4,
        variables.ipv6,
        variables.user,
        variables.password,
    ],
    //  backend/api/v1/ComputeResourceVm/Notifications/VmPasswordReset.php
    server_reset_password: [
        variables.name,
        variables.password,
    ],
    //  backend/api/v1/Auth/Notifications/ResetPassword.php
    user_reset_password: [
        variables.passwordResetUrl,
        variables.urlExpiresIn,
    ],
    //  backend/api/v1/Auth/Notifications/VerifyUserEmail.php
    user_verify_email: [
        variables.verifyEmailUrl,
        variables.urlExpiresIn,
    ],
    //  backend/api/v1/Auth/Notifications/TwoFactorAuthRecoveryCode.php
    two_factor_auth_recovery_code: [
        variables.recoveryCode,
    ],
    //  backend/api/v1/Project/Notifications/UserInvitedToProject.php
    project_user_invite: [
        variables.project,
        variables.projectInviteUrl,
    ],
    //  backend/api/v1/Project/Notifications/UserLeftProject.php
    project_user_left: [
        variables.project,
        variables.email,
    ],
    //  backend/api/v1/ComputeResourceVm/Notifications/VmIncomingTrafficExceeded.php
    server_incoming_traffic_exceeded: [
        variables.name,
    ],
    //  backend/api/v1/ComputeResourceVm/Notifications/VmOutgoingTrafficExceeded.php
    server_outgoing_traffic_exceeded: [
        variables.name,
    ],
};

export interface INotificationSettingsEditDialogProps {
    event: string;
}
export type NotificationsSettingsProps =
    INotificationSettingsEditDialogProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const NotificationSettingsEditDialog: React.FC<NotificationsSettingsProps> = ({
    event,
    languages,
    isSavingSettings,
    settings,
    settingsActions: {
        saveSettings,
        setSettings,
    },
}) => {
    const [settingsData, setSettingsData] = React.useState({ notifications: { ...settings.notifications } });
    const [language, setLanguage] = React.useState({ ...initialLanguageState, locale: DEFAULT_LOCALE });
    const [isLoading, setIsLoading] = React.useState(false);
    const [defaultTemplates, setDefaultTemplates] = React.useState<IEmailNotification>({
        default_subject: '',
        default_body: '',
    });

    React.useEffect(() => {
        const fetchDefaults = async () => {
            try {
                setIsLoading(true);
                const result = await notifications.defaults(event);

                setDefaultTemplates(result.data.data);
            } finally {
                setIsLoading(false);
            }
        };

        // Load default templates for en_US
        fetchDefaults();
    }, [event]);

    React.useEffect(() => {
        const lang = languages.find((item) => item.locale === DEFAULT_LOCALE);

        if (lang) {
            setLanguage(languages[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languages]);

    const handleSubmit = async (values: ISettingsResponse) => {
        saveSettings({
            notifications: {
                ...values.notifications,
            },
        });
    };

    const handleLanguageChange = (id: number) => {
        const lang = languages.find((item) => item.id === id);

        if (lang) {
            setLanguage(lang);
        }
    };

    const onFieldChange = (key: string, value: string) => setSettingsData(setIn(settingsData, key, value));

    const handleResetToDefault = () => {
        setSettingsData({
            notifications: {
                ...settingsData.notifications,
                [event]: {
                    ...settingsData.notifications[event],
                    subject_templates: {
                        ...settingsData.notifications[event].subject_templates,
                        [language.locale]: defaultTemplates.default_subject,
                    },
                    body_templates: {
                        ...settingsData.notifications[event].body_templates,
                        [language.locale]: defaultTemplates.default_body,
                    },
                },
            },
        });
    };

    return (
        <Loader isLoading={isLoading} center={false}>
            <Form
                style={{ marginTop: '16px' }}
                onSubmit={handleSubmit}
                values={settingsData}
                footerClassName="hidden"
                hideRequiredLegend={true}
                onFieldChange={onFieldChange}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <SettingsDialogToolbar>
                    <LanguageSelectorContainer>
                        <LanguageSelector
                            languages={languages}
                            selected={language}
                            onChange={handleLanguageChange}
                            checked={settingsData.notifications[event].override_templates}
                        />
                        <FormFieldCheckbox
                            name={`notifications[${event}][override_templates][${language.locale}]`}
                            label={<Translate content="settings.notifications.overrideTemplate" />}
                            fullDescription={<Translate content="settings.notifications.useCustomTemplateDescription" />}
                        />
                        <ButtonWithConfirmation
                            translations={{
                                button: (
                                    <Translate content="settings.notifications.default" />
                                ),
                                title: (
                                    <Translate content="settings.notifications.resetToDefault" />
                                ),
                            }}
                            confirmationButtonText={<Translate content="settings.notifications.default" />}
                            buttonIntent={INTENT_TYPE.INFO}
                            buttonSize={SIZE.MD}
                            data-cy={NOTIFICATION_EDIT_DIALOG.DEFAULT_BUTTON}
                            handleConfirm={handleResetToDefault}
                        />
                    </LanguageSelectorContainer>
                    <SystemVariablesPopover variables={eventVariablesMap[event]} />
                </SettingsDialogToolbar>
                <FormFieldText
                    name={`notifications[${event}][subject_templates][${language.locale}]`}
                    label={<Translate content="settings.notifications.subjectTemplate" />}
                    size={SIZE.FILL}
                />
                <FormFieldText
                    name={`notifications[${event}][body_templates][${language.locale}]`}
                    label={<Translate content="settings.notifications.bodyTemplate" />}
                    multiline
                    rows={12}
                    size={SIZE.FILL}
                    description={(
                        <Translate
                            content="settings.notifications.templatesDescription"
                            params={{ twig: <a rel="noreferrer" href="https://twig.symfony.com/doc/3.x/" target="_blank">Twig</a> }}
                        />
                    )}
                />
                <SettingsSubmitToolbar>
                    <Button
                        type="submit"
                        intent={INTENT_TYPE.PRIMARY}
                        isLoading={isSavingSettings}
                        size={SIZE.LG}
                    >
                        <Translate content="settings.save" />
                    </Button>
                </SettingsSubmitToolbar>
                <Text
                    fontSize={SIZE.SM}
                    intent={INTENT_TYPE.MUTED}
                >
                    <Translate content="settings.notifications.templatesSaveDescription" />
                </Text>
            </Form>
        </Loader>
    );
};

const mapStateToProps = (state: RootState) => ({
    settings: state.settings,
    languages: state.language.list.data,
    isSavingSettings: state.app.loadingFlags.has(LOADING_FLAGS.SAVE_APP_SETTINGS),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    settingsActions: bindActionCreators(settingsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettingsEditDialog);
