// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Grid,
    GridCol,
} from '@plesk/ui-library';
import { LocationCard } from 'common/components/location/LocationCard';
import { PageSubHeader } from 'client/common/components';
import InfiniteScroll from 'common/components/InfinityScroll/InfinityScroll';
import { RootState } from 'client/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { loadLocationsOnScroll } from 'common/modules/location/actions';
import { connect } from 'react-redux';
import { Tooltip } from 'common/components/Tooltip/Tooltip';
import { SIZE } from 'common/constants';

interface ILocationListProps {
    selectedId: number;
    onItemClicked: (id: number) => void;
    disabledLocationIds?: number[];
    tooltips?: {[id: number]:  React.ReactNode};
}

export type LocationListProps =
    ILocationListProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const LocationList: React.FC<LocationListProps> = ({
    locations,
    selectedId,
    onItemClicked,
    loadOnScroll,
    disabledLocationIds,
    tooltips = {},
}) => React.useMemo(() => (
    <>
        <PageSubHeader title="servers.create.location" />
        <InfiniteScroll
            loadMore={loadOnScroll}
            hasMore={!!locations.links.next}
        >
            <Grid style={{ marginBottom: '-24px' }} minColumnWidth={190} gap={SIZE.LG}>
                {locations.data.map((location, key) => (
                    <Tooltip
                        title={tooltips[location.id]}
                        shown={!!tooltips[location.id]}
                        key={key}
                    >
                        <GridCol>
                            <LocationCard
                                onItemClicked={onItemClicked}
                                location={location}
                                isSelected={location.id === selectedId}
                                disabled={!!disabledLocationIds?.includes(location.id)}
                                showInternalTooltips={tooltips[location.id] === undefined}
                            />
                        </GridCol>
                    </Tooltip>
                ))}
            </Grid>
        </InfiniteScroll>
    </>
    // eslint-disable-next-line react-hooks/exhaustive-deps
), [locations.data, selectedId, disabledLocationIds]);

const mapStateToProps = (state: RootState) => ({
    locations: state.location.list,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadOnScroll: bindActionCreators(loadLocationsOnScroll, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationList);
