// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    FormField,
    Switch,
} from '@plesk/ui-library';
import { IFormProps } from 'common/components/Form/types';

export interface IFormFieldSwitchProps {
    name: string;
    label: JSX.Element;
    disabled?: boolean;
    loading?: boolean;
    onChange?: () => void;
    intent?: 'warning' | 'danger';
}

export const FormFieldSwitch: React.FC<IFormFieldSwitchProps> = ({
    name,
    label,
    disabled,
    loading,
    onChange,
    intent,
    ...props
}) => (
    <FormField name={name} {...props}>
        {({ setValue, getValue }: IFormProps<boolean>) => {
            const handleChange = () => {
                setValue(!getValue());
                onChange?.();
            };

            return (
                <Switch
                    intent={intent}
                    checked={getValue()}
                    onChange={handleChange}
                    disabled={disabled}
                    loading={loading}
                >
                    {label}
                </Switch>
            );
        }}
    </FormField>
);
