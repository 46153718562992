// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Button,
    Icon,
    Popover,
    Translate,
} from '@plesk/ui-library';
import { ICONS } from 'common/constants';
import { dataCySelector } from 'common/tests/selectors';
import { IVpcNetworkResponse } from 'common/api/resources/VpcNetwork';
import { TABLE_ACTIONS } from 'common/modules/vpcNetwork/constants/tests';
import VpcNetworkForm from 'common/modules/vpcNetwork/components/VpcNetworkForm';

export interface IVpcNetworkEditProps {
    item: IVpcNetworkResponse;
    setVpcNetworkItem: (item: IVpcNetworkResponse) => void;
    disabled?: boolean;
}

export const VpcNetworkEdit: React.FC<IVpcNetworkEditProps> = ({
    item,
    setVpcNetworkItem,
    disabled,
}) => {
    const [isPopoverOpened, setPopoverOpened] = React.useState(false);

    const handleOpen = () => {
        setVpcNetworkItem(item);
        setPopoverOpened(true);
    };

    return (
        <Popover
            onClose={() => setPopoverOpened(false)}
            visible={isPopoverOpened}
            target={(
                <Button
                    ghost={true}
                    disabled={disabled}
                    icon={<Icon name={ICONS.PENCIL} size={18} />}
                    tooltip={<Translate content="vpcNetwork.tooltip.edit"/>}
                    onClick={handleOpen}
                    data-cy={dataCySelector(item.id, TABLE_ACTIONS.EDIT)}
                />
            )}
        >
            <VpcNetworkForm
                onSubmit={() => setPopoverOpened(false)}
            />
        </Popover>
    );
};

export default VpcNetworkEdit;