// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { Wrapper } from 'common/components/TopBar/Styles';
import { Button } from '@plesk/ui-library';
import { INTENT_TYPE } from 'common/constants';
import { Link } from 'react-router-dom';

export interface ITopBarProps extends React.HTMLProps<HTMLElement> {
    linkText?: React.ReactNode;
    onLinkClick?: () => void;
    linkTo?: string;
    intent?: INTENT_TYPE;
    color?: string;
    backgroundColor?: string;
    dataCy?: string;
}

export const TopBar: React.FC<ITopBarProps> = ({
    children,
    onLinkClick,
    linkTo,
    linkText,
    color,
    backgroundColor,
    dataCy,
}) => {
    const buttonProps = linkTo ? {
        component: Link,
        to: linkTo,
    } : {
        onClick: onLinkClick,
    };

    return (
        <Wrapper color={color} backgroundColor={backgroundColor} data-cy={dataCy}>
            {children}
            {linkText && (
                <Button ghost={true} intent="primary" {...buttonProps}>
                    {linkText}
                </Button>
            )}
        </Wrapper>
    );
};

export default TopBar;
