// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { ValueType } from 'react-select';
import {
    ILoadOptions,
    ISelectRequiredOption,
} from 'common/components';
import * as React from 'react';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import { FILTERS } from 'admin/common/constants/tests';
import { FixedFormField } from 'admin/common/components/Filters/Styles';
import { computeResourceVms } from 'common/api/resources/ComputeResourceVm';
import { Translate } from '@plesk/ui-library';

export interface IComputeResourceVmFilterProps {
    onChange?: (option: ValueType<ISelectRequiredOption>) => void;
}

export const ComputeResourceVmFilter: React.FC<IComputeResourceVmFilterProps> = ({
    onChange,
}) => {
    const loadComputeResourceVms: ILoadOptions = async (search, loadedOptions, { page }) => {
        const response = await computeResourceVms.list({
            page,
            filters: {
                search,
            },
        });

        return {
            options: response.data.data.map(computeResourceVm => ({
                label: computeResourceVm.name,
                value: computeResourceVm.id.toString(),
            })),
            hasMore: !!response.data.links.next,
            additional: {
                page: page + 1,
            },
        };
    };

    return (
        <FixedFormField data-cy={FILTERS.COMPUTE_RESOURCE_VM}>
            <AsyncSelectInput
                isClearable={true}
                placeholder={<Translate content="filters.computeResourceVm"/>}
                loadOptions={loadComputeResourceVms}
                onChange={onChange}
                debounceTimeout={1000}
                additional={{ page: 1 }}
            />
        </FixedFormField>
    );
};
