// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as userActions from 'admin/user/actions';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import {
    addToPaginated,
    updateInPaginated,
} from 'common/reducers/list';
import {
    IUserResponse,
    UserStatus,
} from 'common/api/resources/User';
import { initialLanguageState } from 'common/modules/language/reducer';

interface IUsers {
    list: IPaginateApiResponse<IUserResponse[]>;
    item: IUserResponse;
}

export type UserAction = ActionType<typeof userActions>;
export type UserState = Readonly<IUsers>;

export const initialUserState: IUserResponse = {
    id: 0,
    email: '',
    created_at: '',
    email_verified_at: null,
    has_verified_email: false,
    limit_usage: {
        servers: 0,
        running_servers: 0,
        additional_ips: 0,
        additional_ipv6: 0,
        iso_images: 0,
        iso_images_size: 0,
    },
    roles: [],
    status: UserStatus.ACTIVE,
    language: { ...initialLanguageState },
    allowed_ips: null,
    is_two_factor_auth_enabled: false,
    two_factor_auth_recovery_code_count: 0,
    limit_group: null,
    billing_token: null,
    billing_user_id: null,

    is_two_factor_auth_recovery_code_sending: false,
    is_deleting: false,
};

export default combineReducers<UserState, UserAction>({
    list: (state = paginateInitialState, action: UserAction) => {
        switch (action.type) {
        case getType(userActions.appendUsers):
            return {
                links: action.payload.links,
                data: [...state.data, ...action.payload.data],
                meta: action.payload.meta,
            };
        case getType(userActions.addUserItem):
            return addToPaginated(state, action.payload);
        case getType(userActions.setUserList):
            return action.payload;
        case getType(userActions.updateUserItem):
            return {
                ...state,
                data: state.data.map(item => {
                    if (item.id === action.payload.id) {
                        return action.payload;
                    }

                    return item;
                }),
            };
        case getType(userActions.setUserItemIsDeleting):
            return updateInPaginated(state, action.payload);
        case getType(userActions.setUserItemIsTwoFactorAuthRecoveryCodeSending):
            return updateInPaginated(state, action.payload);
        default:
            return state;
        }
    },
    item: (state = initialUserState, action) => {
        switch (action.type) {
        case getType(userActions.setUserItem):
            return action.payload;
        case getType(userActions.unsetUserItem):
            return { ...initialUserState };
        default:
            return state;
        }
    },
});
