// Copyright 1999-2024. WebPros International GmbH. All rights reserved.


import * as vpcNetworkActions from 'common/modules/vpcNetwork/actions';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import {
    addItemsToPaginated,
    addToPaginated,
    deleteFromPaginated,
    updateInPaginated,
} from 'common/reducers/list';
import {
    IVpcNetworkIpResponse,
    IVpcNetworkResponse,
} from 'common/api/resources/VpcNetwork';
import { IpListType } from 'common/api/resources/IpBlock';
import { initialLocationState } from 'common/modules/location/reducer';

interface IVpcNetwork {
    list: IPaginateApiResponse<IVpcNetworkResponse[]>;
    item: IVpcNetworkResponse;
    ipList: IPaginateApiResponse<IVpcNetworkIpResponse[]>;
}

export type VpcNetworkAction = ActionType<typeof vpcNetworkActions>;
export type VpcNetworkState = IVpcNetwork;

export const initialVpcNetworkState: IVpcNetworkResponse = {
    id: 0,
    name: '',
    list_type: IpListType.RANGE,
    from: undefined,
    to: undefined,
    netmask: '',
    location: initialLocationState,
    is_deleting: false,
};

export default combineReducers<VpcNetworkState, VpcNetworkAction>({
    list: (state = paginateInitialState, action: VpcNetworkAction) => {
        switch (action.type) {
        case getType(vpcNetworkActions.addVpcNetworkItem):
            return addToPaginated(state, action.payload);
        case getType(vpcNetworkActions.setVpcNetworkList):
            return action.payload;
        case getType(vpcNetworkActions.removeVpcNetworkItem):
            return deleteFromPaginated(state, action.payload);
        case getType(vpcNetworkActions.updateVpcNetworkItem):
            return updateInPaginated(state, action.payload);
        case getType(vpcNetworkActions.setVpcNetworkItemIsDeleting):
            return updateInPaginated(state, action.payload);
        default:
            return state;
        }
    },
    item: (state = initialVpcNetworkState, action) => {
        switch (action.type) {
        case getType(vpcNetworkActions.setVpcNetworkItem):
            return action.payload;
        case getType(vpcNetworkActions.unsetVpcNetworkItem):
            return { ...initialVpcNetworkState };
        default:
            return state;
        }
    },
    ipList: (state = paginateInitialState, action: VpcNetworkAction) => {
        switch (action.type) {
        case getType(vpcNetworkActions.setVpcNetworkIps):
            return action.payload;
        case getType(vpcNetworkActions.appendVpcNetworkIps):
            return {
                links: action.payload.links,
                data: [...state.data, ...action.payload.data],
                meta: action.payload.meta,
            };
        case getType(vpcNetworkActions.addVpcNetworkIp):
            return addToPaginated(state, action.payload);
        case getType(vpcNetworkActions.addVpcNetworkIps):
            return addItemsToPaginated(state, action.payload);
        case getType(vpcNetworkActions.removeVpcNetworkIp):
            return deleteFromPaginated(state, action.payload);
        case getType(vpcNetworkActions.removeVpcNetworkIps):
            return {
                ...state,
                data: state.data.filter(item => !action.payload.includes(item.id)),
            };
        default:
            return state;
        }
    },
});
