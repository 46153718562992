// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import LocationCell from 'common/components/IconCell/LocationCell';
import { primaryIps } from 'common/api/resources/Ip';
import CopyText from 'common/containers/CopyText/CopyText';
import { IVmResponse } from 'common/api/resources/ComputeResourceVm';
import NetworkLimitExceedDetails from 'client/project/containers/projectItem/tabs/ProjectServerTab/ProjectServerCard/NetworkLimitExceedDetails/NetworkLimitExceedDetails';
import { CutTitle } from 'common/components';
import { ICONS } from 'common/constants';
import {
    Icon,
    Translate,
} from '@plesk/ui-library';
import {
    DetailsIp,
    IP,
    ItemInfoDescription,
    ItemInfoDetails,
} from 'client/project/containers/projectItem/tabs/ProjectServerTab/ProjectServerCard/Item/Styles';
import {
    LimitName,
    NetworkTrafficLimitTypes,
} from 'common/api/resources/Plan';

export interface IItemProps {
    vm: IVmResponse;
}

export const Item: React.FC<IItemProps> = ({ vm }) => {
    const renderDetails = () => {
        if (vm.plan.network_traffic_limit_type === NetworkTrafficLimitTypes.Separate
            && vm.usage.network.outgoing.is_exceeded
        ) {
            return (
                <NetworkLimitExceedDetails
                    usage={vm.usage.network.outgoing.value}
                    limit={vm.plan.limits[LimitName.NetworkOutgoingTraffic]}
                    text={
                        <Translate content="projects.server.limitsExceed.outgoing"/>
                    }
                />
            );
        }

        if (vm.plan.network_traffic_limit_type === NetworkTrafficLimitTypes.Separate
            && vm.usage.network.incoming.is_exceeded
        ) {
            return (
                <NetworkLimitExceedDetails
                    usage={vm.usage.network.incoming.value}
                    limit={vm.plan.limits[LimitName.NetworkIncomingTraffic]}
                    text={
                        <Translate content="projects.server.limitsExceed.incoming"/>
                    }
                />
            );
        }

        if (vm.plan.network_traffic_limit_type === NetworkTrafficLimitTypes.Total
            && vm.usage.network.outgoing.is_exceeded
            && vm.usage.network.incoming.is_exceeded
        ) {
            return (
                <NetworkLimitExceedDetails
                    usage={vm.usage.network.outgoing.value + vm.usage.network.incoming.value}
                    limit={vm.plan.limits[LimitName.NetworkTotalTraffic]}
                    text={
                        <Translate content="projects.server.limitsExceed.total"/>
                    }
                />
            );
        }

        return (
            <ItemInfoDetails>
                {vm.location && (
                    <LocationCell location={vm.location}/>
                )}
                <DetailsIp>
                    <span>
                        <Icon name={ICONS.LOCATION}/>
                    </span>
                    {primaryIps(vm.ips).map(ip => (
                        <IP key={ip.id}>
                            <CopyText>{ip.ip}</CopyText>
                        </IP>
                    ))}
                </DetailsIp>
            </ItemInfoDetails>
        );
    };

    return (
        <>
            <ItemInfoDescription>
                <CutTitle title={vm.description}/>
            </ItemInfoDescription>
            {renderDetails()}
        </>
    );
};

export default Item;
