// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as offerActions from 'common/modules/offer/actions';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import {
    Switch,
    Translate,
} from '@plesk/ui-library';
import { Loader } from 'common/components';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import { Dialog } from 'common/components/Dialog/Dialog';
import {
    ICONS,
    SIZE,
} from 'common/constants';
import {
    getActionColumnProps,
    reloadListData,
} from 'common/helpers/list';
import { useIsFirstLoading } from 'common/hooks/useIsFirstLoading';
import List from 'common/components/List/List';
import OfferForm from 'admin/offer/containers/OfferForm';
import { OfferActions } from 'admin/offer/components/OfferActions';
import {
    IOfferResponse,
    OfferType,
} from 'common/api/resources/Offer';
import { IListReorderedItem } from 'common/reducers/list';
import { getNewPosition } from 'common/helpers/position';

export type OffersProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export enum OfferTableColumns {
    ID = 'colId',
    NAME = 'colName',
    DESCRIPTION = 'colDescription',
    TYPE = 'colType',
    VISIBILITY = 'colVisibility',
    ACTIONS = 'colActions',
}

const columns = [{
    width: '1%',
    key: OfferTableColumns.ID,
    title: <Translate content="offer.list.id" />,
}, {
    width: '15%',
    key: OfferTableColumns.NAME,
    title: <Translate content="offer.list.name" />,
    cellProps: {
        className: 'cell-bold cell-default',
    },
}, {
    width: '15%',
    key: OfferTableColumns.TYPE,
    title: <Translate content="offer.list.type" />,
}, {
    key: OfferTableColumns.DESCRIPTION,
    title: <Translate content="offer.list.description" />,
}, {
    width: '1%',
    key: OfferTableColumns.VISIBILITY,
    title: <Translate content="offer.list.visibility" />,
    cellProps: {
        style: { textAlign: 'center' },
    },
}, getActionColumnProps(),
];

const typeLabels: { [k in OfferType]: React.ReactNode } = {
    [OfferType.PRIMARY_DISK]: <Translate content="offer.type.primaryDisk" />,
    [OfferType.ADDITIONAL_DISK]: <Translate content="offer.type.additionalDisk" />,
};

export const Offers: React.FC<OffersProps> = ({
    item,
    list,
    isLoadingList,
    isLoadingItem,
    offerActions: {
        getOffers,
        getOffer,
        removeOffer,
        patchOffer,
    },
}) => {
    const [isDialogOpen, setDialogOpen] = React.useState(false);
    const [visibilityToggleDisabled, setVisibilityToggleDisabled] = React.useState(false);

    React.useEffect(() => {
        getOffers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadPaginated = (page: number) => getOffers({ page });

    const isFirstLoading = useIsFirstLoading(isLoadingList);

    const handleRemove = (id: number) => async () => {
        await removeOffer(id);
        reloadListData(list, loadPaginated);
    };

    const handleEdit = (id: number) => () => {
        getOffer(id);
        setDialogOpen(true);
    };

    const handleToggleVisibility = (offer: IOfferResponse) => async () => {
        if (visibilityToggleDisabled && isLoadingItem) {
            return;
        }

        setVisibilityToggleDisabled(true);

        try {
            await patchOffer(offer.id, { is_visible: !offer.is_visible });
        } finally {
            setVisibilityToggleDisabled(false);
        }
    };

    const handleReorder = async (data: IListReorderedItem) => {
        if (list.data[data.oldIndex] !== undefined) {
            const newPos = getNewPosition(data.oldIndex, data.newIndex, list.data);
            await patchOffer(list.data[data.oldIndex].id, { position: newPos });
        }
    };

    const data = list.data.map((offer) => ({
        [OfferTableColumns.ID]: offer.id,
        [OfferTableColumns.NAME]: offer.name,
        [OfferTableColumns.TYPE]: typeLabels[offer.type],
        [OfferTableColumns.DESCRIPTION]: offer.description,
        [OfferTableColumns.VISIBILITY]: (
            <Switch
                checked={offer.is_visible}
                onChange={handleToggleVisibility(offer)}
                loading={isLoadingItem && visibilityToggleDisabled}
            />
        ),
        [OfferTableColumns.ACTIONS]: (
            <OfferActions
                item={offer}
                handleEdit={handleEdit(offer.id)}
                handleRemove={handleRemove(offer.id)}
            />
        ),
        key: offer.id.toString(),
    }));

    return (
        <>
            <PageHeader
                isButtonShown={list.data.length > 0}
                title={<Translate content="offer.title"/>}
                buttonText="offer.addButton"
                buttonIcon={ICONS.PLUS}
                onButtonClick={() => setDialogOpen(true)}
            />
            <Loader isLoading={isFirstLoading}>
                <List
                    emptyView={
                        <EmptyView
                            title="offer.emptyView.title"
                            description="offer.emptyView.description"
                            buttonText="offer.emptyView.buttonText"
                            onButtonClick={() => setDialogOpen(true)}
                            icon={ICONS.PLUS}
                        />
                    }
                    reorderable={true}
                    onReorderEnd={handleReorder}
                    columns={columns}
                    data={data}
                    loadItems={loadPaginated}
                    meta={list.meta}
                    isLoading={isLoadingList}
                    isFirstLoading={isFirstLoading}
                />
            </Loader>
            <Dialog
                heading={<Translate content={item.id
                    ? 'offer.dialog.editTitle'
                    : 'offer.dialog.createTitle'
                } />}
                closeHandler={() => setDialogOpen(false)}
                isOpen={isDialogOpen}
                size={SIZE.XS}
            >
                <Loader isLoading={isLoadingItem} center={false}>
                    <OfferForm onSubmit={() => setDialogOpen(false)}/>
                </Loader>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    item: state.offer.item,
    list: {
        ...state.offer.list,
        data: state.offer.list.data.sort((a, b) => a.position - b.position),
    },
    isLoadingList: state.app.loadingFlags.has(LOADING_FLAGS.OFFER_LIST),
    isLoadingItem: state.app.loadingFlags.has(LOADING_FLAGS.OFFER_ITEM),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    offerActions: bindActionCreators(offerActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Offers);
