// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as storageTagActions from 'admin/storageTag/actions';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import { Translate } from '@plesk/ui-library';
import { Loader } from 'common/components';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import { Dialog } from 'common/components/Dialog/Dialog';
import {
    ICONS,
    SIZE,
} from 'common/constants';
import {
    getActionColumnProps,
    reloadListData,
} from 'common/helpers/list';
import { useIsFirstLoading } from 'common/hooks/useIsFirstLoading';
import List from 'common/components/List/List';
import StorageTagForm from 'admin/storageTag/containers/StorageTagForm';
import { StorageTagActions } from 'admin/storageTag/components/StorageTagActions';
import { IListReorderedItem } from 'common/reducers/list';
import { getNewPosition } from 'common/helpers/position';

export type StorageTagsProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export enum StorageTagTableColumns {
    ID = 'colId',
    NAME = 'colName',
    DESCRIPTION = 'colDescription',
    TIER = 'colTier',
    ACTIONS = 'colActions',
}

const columns = [{
    width: '1%',
    key: StorageTagTableColumns.ID,
    title: <Translate content="storageTag.list.id" />,
}, {
    width: '15%',
    key: StorageTagTableColumns.NAME,
    title: <Translate content="storageTag.list.name" />,
    cellProps: {
        className: 'cell-bold cell-default',
    },
}, {
    width: '15%',
    key: StorageTagTableColumns.TIER,
    title: <Translate content="storageTag.list.tier" />,
}, {
    key: StorageTagTableColumns.DESCRIPTION,
    title: <Translate content="offer.list.description" />,
}, getActionColumnProps(),
];

export const StorageTags: React.FC<StorageTagsProps> = ({
    item,
    list,
    isLoadingList,
    isLoadingItem,
    storageTagActions: {
        getStorageTags,
        getStorageTag,
        updateStorageTagPosition,
        removeStorageTag,
    },
}) => {
    const [isDialogOpen, setDialogOpen] = React.useState(false);

    React.useEffect(() => {
        getStorageTags();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadPaginated = (page: number) => getStorageTags({ page });

    const isFirstLoading = useIsFirstLoading(isLoadingList);

    const handleRemove = (id: number) => async () => {
        await removeStorageTag(id);
        reloadListData(list, loadPaginated);
    };

    const handleEdit = (id: number) => () => {
        getStorageTag(id);
        setDialogOpen(true);
    };

    const handleReorder = async (data: IListReorderedItem) => {
        if (list.data[data.oldIndex] !== undefined) {
            const newPos = getNewPosition(data.oldIndex, data.newIndex, list.data);
            await updateStorageTagPosition(list.data[data.oldIndex].id, { position: newPos });
        }

        await getStorageTags();
    };

    const data = list.data.map((storageTag) => ({
        [StorageTagTableColumns.ID]: storageTag.id,
        [StorageTagTableColumns.NAME]: storageTag.name,
        [StorageTagTableColumns.DESCRIPTION]: storageTag.description,
        [StorageTagTableColumns.TIER]: storageTag.tier,
        [StorageTagTableColumns.ACTIONS]: (
            <StorageTagActions
                item={storageTag}
                handleEdit={handleEdit(storageTag.id)}
                handleRemove={handleRemove(storageTag.id)}
            />
        ),
        key: storageTag.id.toString(),
    }));

    return (
        <>
            <PageHeader
                isButtonShown={list.data.length > 0}
                title={<Translate content="storageTag.title"/>}
                buttonText="storageTag.addButton"
                buttonIcon={ICONS.PLUS}
                onButtonClick={() => setDialogOpen(true)}
            />
            <Loader isLoading={isFirstLoading}>
                <List
                    emptyView={
                        <EmptyView
                            title="storageTag.emptyView.title"
                            description="storageTag.emptyView.description"
                            buttonText="storageTag.emptyView.buttonText"
                            onButtonClick={() => setDialogOpen(true)}
                            icon={ICONS.PLUS}
                        />
                    }
                    reorderable={true}
                    onReorderEnd={handleReorder}
                    columns={columns}
                    data={data}
                    loadItems={loadPaginated}
                    meta={list.meta}
                    isLoading={isLoadingList}
                    isFirstLoading={isFirstLoading}
                />
            </Loader>
            <Dialog
                heading={<Translate content={item.id
                    ? 'storageTag.dialog.editTitle'
                    : 'storageTag.dialog.createTitle'
                } />}
                closeHandler={() => setDialogOpen(false)}
                isOpen={isDialogOpen}
                size={SIZE.XS}
            >
                <Loader isLoading={isLoadingItem} center={false}>
                    <StorageTagForm
                        onSubmit={() => {
                            setDialogOpen(false);
                            getStorageTags();
                        }}
                    />
                </Loader>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    item: state.storageTag.item,
    list: {
        ...state.storageTag.list,
        data: state.storageTag.list.data.sort((a, b) => a.position - b.position),
    },
    isLoadingList: state.app.loadingFlags.has(LOADING_FLAGS.STORAGE_TAG_LIST),
    isLoadingItem: state.app.loadingFlags.has(LOADING_FLAGS.STORAGE_TAG_ITEM),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    storageTagActions: bindActionCreators(storageTagActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(StorageTags);
