// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { ValueType } from 'react-select';
import { ISelectRequiredOption } from 'common/components';
import * as React from 'react';
import {
    computeResources,
    IComputeResourceResponse,
} from 'common/api/resources/ComputeResource';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import { FILTERS } from 'admin/common/constants/tests';
import { FixedFormField } from 'admin/common/components/Filters/Styles';
import { Translate } from '@plesk/ui-library';
import { createOptionsLoader } from 'common/components/Select/helpers';

export interface IComputeResourceFilterProps {
    onChange?: (option: ValueType<ISelectRequiredOption>) => void;
    value?: ValueType<ISelectRequiredOption>;
}

export const ComputeResourceFilter: React.FC<IComputeResourceFilterProps> = ({
    onChange,
    value,
}) => {
    const loadComputeResources = createOptionsLoader(
        computeResources.list,
        (item: IComputeResourceResponse) => ({
            label: item.host,
            value: item.id,
        })
    );

    return (
        <FixedFormField data-cy={FILTERS.COMPUTE_RESOURCE}>
            <AsyncSelectInput
                inputId="compute-resource-filter"
                isClearable={true}
                placeholder={<Translate content="filters.computeResource"/>}
                loadOptions={loadComputeResources}
                onChange={onChange}
                debounceTimeout={1000}
                additional={{ page: 1 }}
                value={value}
            />
        </FixedFormField>
    );
};
