// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import {
    IAdditionalDiskRequest,
    IAdditionalIpRequest,
    IComputeResourceVmBackupListRequest,
    IVmDiskResponse,
    IComputeResourceVmListRequest,
    IServerLimitResponse,
    IUpdateServerIpsRequest,
    IVmActionRequest,
    IVmReinstallRequest,
    IVmResetPasswordRequest,
    IVmResizeRequest,
    IVmResponse,
    IVmUpdateRequest,
    VirtualServerSettingsRequest,
    IAdditionalDiskUpdateRequest,
    IAdditionalDiskResizeRequest,
} from 'common/api/resources/ComputeResourceVm/model';
import {
    ISnapshotRequest,
    ISnapshotResponse,
} from 'common/api/resources/Snapshot';
import { IBackupResponse } from 'common/api/resources/Backup';
import { QueryBuilder } from 'common/api/resources/Request/QueryBuilder';
import { CancelTokenSource } from 'axios';
import { ITaskResponse } from 'common/api/resources/Task';
import { IVmCreateRequest } from 'common/api/resources/ComputeResourceVm';

export const computeResourceVms = {
    list: (params?: IComputeResourceVmListRequest, cancelToken?: CancelTokenSource) => api.get<IPaginateApiResponse<IVmResponse[]>>('servers', {
        params: new QueryBuilder(params).build(),
        cancelToken: cancelToken?.token,
    }),
    create: (data: IVmCreateRequest) => api.post<IApiResponse<IVmResponse>>('servers', data),
    item: (id: number) => api.get<IApiResponse<IVmResponse>>(`servers/${id}`),
    update: (id: number, data: IVmUpdateRequest) => api.patch<IApiResponse<IVmResponse>>(`servers/${id}`, data),
    remove: (id: number) => api.delete(`servers/${id}`),
    restartVm: (id: number, data: IVmActionRequest) => api.post(`servers/${id}/restart`, data),
    startVm: (id: number) => api.post(`servers/${id}/start`),
    stopVm: (id: number, data: IVmActionRequest) => api.post(`servers/${id}/stop`, data),
    resizeVm: (id: number, data: IVmResizeRequest) => api.post(`servers/${id}/resize`, data),
    resetUsage: (id: number) => api.post<IApiResponse<ITaskResponse>>(`servers/${id}/reset_usage`),
    resetPassword: (id: number, data?: IVmResetPasswordRequest) => api.post(`servers/${id}/reset_password`, data),
    startBatch: (ids: number[]) => api.post('servers/start', { ids }),
    restartBatch: (ids: number[], data: IVmActionRequest) => api.post('servers/restart', { ...data, ids } ),
    stopBatch: (ids: number[], data: IVmActionRequest) => api.post('servers/stop', { ...data, ids }),
    removeBatch: (ids: number[]) => api.delete('servers/delete', { data: { ids } }),
    reinstall: (id: number, data: IVmReinstallRequest) => api.post(`servers/${id}/reinstall`, data),
    suspend: (id: number) => api.post(`servers/${id}/suspend`),
    resume: (id: number) => api.post(`servers/${id}/resume`),

    limits: {
        list: (id: number) => api.get<IApiResponse<IServerLimitResponse[]>>(`servers/${id}/limits`),
    },

    snapshots: {
        list: (id: number) => api.get<IPaginateApiResponse<ISnapshotResponse[]>>(`servers/${id}/snapshots`),
        create: (id: number, data: ISnapshotRequest) => api.post<IApiResponse<ISnapshotResponse>>(`servers/${id}/snapshots`, data),
    },

    backups: {
        list: (id: number, params?: IComputeResourceVmBackupListRequest, cancelToken?: CancelTokenSource) =>
            api.get<IPaginateApiResponse<IBackupResponse[]>>(`servers/${id}/backups`, {
                params: new QueryBuilder(params).build(),
                cancelToken: cancelToken?.token,
            }),
        create: (id: number) => api.post<IApiResponse<IBackupResponse>>(`servers/${id}/backups`),
    },

    ips: {
        create: (id: number, data: IAdditionalIpRequest = {}) => api.post(`servers/${id}/ips`, data),
        updateIps: (id: number, data: IUpdateServerIpsRequest) => api.put<IApiResponse<ITaskResponse>>(`servers/${id}/ips`, data),
        remove: (id: number, ipId: number) => api.delete<IApiResponse<ITaskResponse>>(`servers/${id}/ips/${ipId}`),
    },

    settings: {
        patch: (id: number, data: VirtualServerSettingsRequest) => api.patch(`servers/${id}/settings`, data),
    },

    installGuestTools: (id: number) => api.post(`servers/${id}/install_guest_tools`),
    installGuestToolsBatch: (ids: number[]) => api.post('servers/install_guest_tools', { ids }),

    disks: {
        list: (serverId: number) => api.get<IApiResponse<IVmDiskResponse[]>>(`servers/${serverId}/disks`),
        create: (serverId: number, data: IAdditionalDiskRequest) => api.post(`servers/${serverId}/disks`, data),
        resize: (serverId: number, diskId: number, data: IAdditionalDiskResizeRequest) => api.post(`servers/${serverId}/disks/${diskId}/resize`, data),
        update: (serverId: number, diskId: number, data: IAdditionalDiskUpdateRequest) => api.put(`servers/${serverId}/disks/${diskId}`, data),
        remove: (serverId: number, diskId: number) => api.delete(`servers/${serverId}/disks/${diskId}`),
    },
};
