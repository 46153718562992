// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    FormField,
    IFormField,
    InputNumber,
} from '@plesk/ui-library';
import { IFormProps } from 'common/components/Form/types';
import { SIZE } from 'common/constants';

export interface IFormFieldNumberProps extends IFormField {
    name: string;
    min: number;
    max?: number;
    step?: number;
    defaultValue?: number;
    disabled?: boolean;
}

export const FormFieldNumber: React.FC<IFormFieldNumberProps> = ({
    name,
    min,
    max,
    step,
    defaultValue,
    disabled,
    size = SIZE.SM,
    value,
    ...props
}) => (
    <FormField name={name} {...props}>
        {({ setValue, getValue, getName, getId }: IFormProps<number>) => (
            <InputNumber
                size={size}
                id={getId()}
                name={getName()}
                min={min}
                max={max}
                step={step}
                defaultValue={defaultValue}
                onChange={setValue}
                value={value !== undefined ? value : getValue()}
                disabled={disabled}
            />
        )}
    </FormField>
);