// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'client/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as projectMemberActions from 'client/project/actions/member';
import { Loader } from 'common/components';
import { IProjectMemberResponse } from 'common/api/resources/Project';
import ProjectMembersCard from 'client/project/containers/projectItem/tabs/ProjectMembersTab/ProjectMembersCard';
import { PaginatedCardList } from 'client/common/components/Card';
import {
    INTENT_TYPE,
    VIEW_TYPE,
} from 'common/constants';
import {
    Action,
    Icon,
    Status,
    Translate,
} from '@plesk/ui-library';
import { StyledList } from 'client/common/styles/List';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import TimeoutLink from 'client/project/containers/projectItem/tabs/ProjectMembersTab/TimeoutLink';
import {
    STATUS_ACTIVE,
    STATUS_INVITED,
} from 'common/modules/project/constants';
import { getActionColumnProps } from 'common/helpers/list';
import { useRequestCancellationEffect } from 'common/hooks/useRequestCancellationEffect';
import { CancelTokenSource } from 'axios';
import List from 'common/components/List/List';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';

interface IProjectMembersListProps {
    projectId: number;
}

export type ProjectMembersListProps =
    IProjectMembersListProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const columns = [{
    width: '20%',
    key: 'colMail',
    title: <Translate content="projects.members.list.colMail" />,
    cellProps: {
        className: 'cell-bold',
    },
}, {
    width: '15%',
    key: 'colStatus',
    title: <Translate content="projects.members.list.colStatus" />,
    className: 'header-status',
    cellProps: {
        className: 'cell-status',
    },
}, {
    width: '15%',
    key: 'colRole',
    title: <Translate content="projects.members.list.colRole" />,
    className: 'header-role',
}, getActionColumnProps(),
];

const intentMap = {
    [STATUS_ACTIVE]: INTENT_TYPE.SUCCESS,
    [STATUS_INVITED]: INTENT_TYPE.WARNING,
};

export const ProjectMembersList: React.FC<ProjectMembersListProps> = ({
    projectMembersActions: {
        getProjectMembers,
        removeMemberItem,
        removeProjectMember,
        resendInvite,
    },
    view,
    isLoading,
    projectId,
    members,
}) => {
    const isFirstLoading = useRequestCancellationEffect(
        token => loadPaginated(1, token),
        []
    );
    const handleRemove = (item: IProjectMemberResponse) => async () => {
        if (!item.id) {
            removeMemberItem(item.id);
            return;
        }

        await removeProjectMember(projectId, item);
    };
    const handleResendInvite = (item: IProjectMemberResponse) => () => resendInvite(projectId, item);

    const loadPaginated = React.useCallback(
        (page: number, cancelToken?: CancelTokenSource) => getProjectMembers(projectId, { page }, cancelToken),
        [ getProjectMembers, projectId ]
    );

    const data = members.data.map((item) => {
        const actionsEl = (
            <ButtonWithConfirmation
                disabled={item.is_owner}
                isLoading={item.isLoading}
                translations={{
                    text: (
                        <Translate content="projects.members.confirmationRemove.text" />
                    ),
                    button: (
                        <Translate content="projects.members.confirmationRemove.button" />
                    ),
                    tooltip: (
                        <Translate content="projects.members.confirmationRemove.button" />
                    ),
                }}
                handleConfirm={handleRemove(item)}
                icon="recycle"
            />
        );

        return {
            colMail: <div style={{ fontWeight: 600, paddingLeft: '7px' }}>{item.email}</div>,
            colStatus: (
                <>
                    <Status intent={intentMap[item.status]} compact={true}>
                        {item.status}
                    </Status>
                    {item.status === STATUS_INVITED && (
                        <TimeoutLink
                            from={item.invite_sent_at}
                            style={{ display: 'inline-block', marginLeft: '24px', minWidth: '135px' }}
                            text={<Translate content="projects.members.timeoutText" />}
                        >
                            <Action
                                onClick={handleResendInvite(item)}
                                style={{ fontWeight: 600 }}
                            >
                                {!item.isLoading && <Translate content="projects.members.resendInvite" />}
                            </Action>
                        </TimeoutLink>
                    )}
                </>
            ),
            colRole: (
                <>
                    {item.is_owner && (
                        <>
                            <Icon style={{ paddingRight: '6px' }} size="16" name="star-circle-filled" intent="info" />
                            <Translate content="projects.members.owner" />
                        </>
                    )}
                    {!item.is_owner && (
                        <div style={{ paddingLeft: '22px' }}>
                            <Translate content="projects.members.user" />
                        </div>
                    )}
                </>
            ),
            colActions: actionsEl,
            key: item.id.toString(),
        };
    });

    return (
        <Loader isLoading={isFirstLoading}>
            {view === VIEW_TYPE.CARD && (
                <PaginatedCardList
                    items={members.data}
                    meta={members.meta}
                    loadPaginated={loadPaginated}
                    isLoading={isLoading}
                    component={member => <ProjectMembersCard key={member.id} member={member} projectId={projectId} />}
                />
            )}
            {view === VIEW_TYPE.LIST && (
                <StyledList>
                    <List
                        isLoading={isLoading}
                        emptyView={null}
                        meta={members.meta}
                        loadItems={loadPaginated}
                        columns={columns}
                        data={data}
                    />
                </StyledList>
            )}
        </Loader>
    );
};

const mapStateToProps = (state: RootState) => ({
    view: state.app.viewType,
    isOwner: state.project.projects.item.is_owner,
    members: state.project.members.list,
    isLoading: state.app.loadingFlags.has(LOADING_FLAGS.PROJECT_MEMBERS_LIST),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    projectMembersActions: bindActionCreators(projectMemberActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectMembersList);
