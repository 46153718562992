// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { HeaderButtonContainer } from 'client/common/components/HeaderButton/Styles';
import { Button } from '@plesk/ui-library';
import { Link } from 'react-router-dom';

export interface IHeaderButtonProps extends React.HTMLProps<HTMLButtonElement> {
    tooltip?: React.ReactNode | string;
    icon?: string;
    isMobile?: boolean;
    isLoading?: boolean;
    to?: React.ComponentProps<typeof Link>['to'];
}

export const HeaderButton: React.FC<IHeaderButtonProps> = ({
    isMobile,
    children,
    icon,
    tooltip,
    isLoading,
    to,
    ...rest
}) => {
    const buttonProps = to ? {
        component: Link,
        to,
    } : {};

    return (
        <HeaderButtonContainer isMobile={isMobile}>
            <Button
                fill={true}
                intent="primary"
                icon={icon}
                tooltip={tooltip}
                state={isLoading ? 'loading' : undefined}
                {...buttonProps}
                {...rest}
            >
                {children}
            </Button>
        </HeaderButtonContainer>
    );
};
