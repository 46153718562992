// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

export enum VM_TABS {
    SETTINGS_TAB = '#settings',
    RESCUE_TAB = '#rescue',
    BACKUPS_TAB = '#backups',
    RESIZE_TAB = '#resize',
    TASKS_TAB = '#reinstall',
    REINSTALL_TAB = '#tasks',
    SNAPSHOT_TAB = '#snapshots',
    GRAPH_TAB = '#graphs',
    NETWORKING_TAB = '#networking',
    DISKS_TAB = '#disks',
    ACTIVITY_LOG_TAB = '#activity-log'
}

export enum VMS_TABS {
    SERVERS_TAB = '#servers',
    MIGRATIONS_TAB = '#migrations',
}

export const TEST = {
    VM_CREATE: {
        COMPUTE_RESOURCE_SELECT: 'compute-resource-select',
        SSH_SELECT: 'ssh-select',
        PASSWORD: 'password',
        USER: 'user',
        PROJECT: 'project',
        PRIMARY_IP: 'primary-ip',
        CREATE_BUTTON: 'create-button',
    },

    SERVERS_MIGRATION_FORM: {
        METRICS: {
            VM: 'migration-form-metrics-vm',
            MEMORY: 'migration-form-metrics-memory',
            DISK: 'migration-form-metrics-disk',
        },
        DESTINATION: 'migration-form-destination',
    },

    DESCRIPTION: {
        IPS: 'vm-description-ips',
    },

    ROW: {
        IP_BLOCKS: 'ip-blocks',
    },
};
