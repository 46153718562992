// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { RootState } from 'client/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as echoActions from 'client/common/containers/EchoProvider/actions';
import { connect } from 'react-redux';
import { initEchoConnection } from 'common/services/EchoService';
import {
    SOCKET_CHANNELS,
    SOCKET_EVENTS,
} from 'common/constants';
import {
    IUpdateSnapshot,
    IUpdateUser,
} from 'common/api/resources/ComputeResource';
import {
    IUpdateComputeResourceVm,
    IUpdatePasswordComputeResourceVm,
    IUpdateServerLimits,
} from 'common/api/resources/ComputeResourceVm';
import { IUpdateBackupResource } from 'common/api/resources/Backup';
import {
    IUpdateTask,
    IUpdateUserTask,
} from 'common/api/resources/Task';
import { updateUserTaskAction } from 'common/modules/task/actions';

export type EchoProviderProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const EchoProvider: React.FC<React.PropsWithChildren<EchoProviderProps>> = ({
    isAuthenticated,
    authData,
    userId,
    serverUuid,
    echoActions: {
        updateProjectServerItemAction,
        updateProjectServerPasswordAction,
        updateUserAction,
        updateTaskAction,
        updateBackupItemAction,
        updateServerLimitsAction,
        updateSnapshotItemAction,
    },
    echoUpdateUserTaskAction,
    children,
}) => {
    React.useEffect(() => {
        if (!isAuthenticated) {
            return;
        }

        const credentials = `${authData.token_type} ${authData.access_token}`;
        const echoConnection = initEchoConnection(credentials);
        const userChannel = echoConnection.private(`${SOCKET_CHANNELS.USER}.${userId}`);
        const projectChannel = echoConnection.private(`${SOCKET_CHANNELS.PROJECT}.${userId}`);
        const backupChannel = echoConnection.private(SOCKET_CHANNELS.BACKUP);
        const userTaskChannel = echoConnection.private(`${SOCKET_CHANNELS.USER_TASK}.${userId}`);
        const taskChannel = echoConnection.private(SOCKET_CHANNELS.TASK);

        // register listeners
        projectChannel.listen(SOCKET_EVENTS.PROJECT_SERVER_UPDATED, (e: IUpdateComputeResourceVm) => updateProjectServerItemAction(e));
        projectChannel.listen(SOCKET_EVENTS.PROJECT_SERVER_PASSWORD_UPDATED, (e: IUpdatePasswordComputeResourceVm) =>
            updateProjectServerPasswordAction(e.id, e.password));
        backupChannel.listen(SOCKET_EVENTS.BACKUP_UPDATED, (e: IUpdateBackupResource) => updateBackupItemAction(e));
        userChannel.listen(SOCKET_EVENTS.UPDATE_USER, (e: IUpdateUser) => updateUserAction(e));
        userTaskChannel.listen(SOCKET_EVENTS.USER_TASK_UPDATED, (e: IUpdateUserTask) => echoUpdateUserTaskAction(e));
        taskChannel.listen(SOCKET_EVENTS.TASK_UPDATED, (e: IUpdateTask) => updateTaskAction(e));

        if (serverUuid) {
            echoConnection.private(`${SOCKET_CHANNELS.COMPUTE_RESOURCE_VM}.${serverUuid}`)
                .listen(SOCKET_EVENTS.UPDATE_SERVER_LIMITS, (e: IUpdateServerLimits) => updateServerLimitsAction(e.limits))
                .listen(SOCKET_EVENTS.SNAPSHOT_UPDATED, (e: IUpdateSnapshot) => updateSnapshotItemAction(e.snapshot));
        }

        return () => {
            echoConnection.disconnect();
        };
    });

    return <>{children}</>;
};

const mapStateToProps = (state: RootState) => ({
    isAuthenticated: state.auth.isAuth,
    authData: state.auth.authData,
    userId: state.auth.user.id,
    serverUuid: state.project.servers.item?.uuid,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    echoActions: bindActionCreators(echoActions, dispatch),
    echoUpdateUserTaskAction: bindActionCreators(updateUserTaskAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EchoProvider);
