// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { IPaginateApiResponse } from 'common/api/resources/Response';
import { CLASS_COLUMN_FIXED_RIGHT } from 'common/styles/variables';
import { IKeyValueMap } from 'mobx';

export const reloadListData = <T>(item: IPaginateApiResponse<T[]>, loadPaginated: (page: number) => void, numberOfDeletedItems: number = 1) => {
    if (!numberOfDeletedItems) {
        return;
    }

    const shouldLoadPreviousPage = item.data.length === numberOfDeletedItems
        && item.meta.current_page === item.meta.last_page
        && item.meta.current_page > 1;

    loadPaginated(shouldLoadPreviousPage ? item.meta.current_page - 1 : item.meta.current_page);
};

export const getActionColumnProps = (props: IKeyValueMap = {}) => ({
    width: '1%',
    key: 'colActions',
    type: 'actions',
    className: `cell-icons ${CLASS_COLUMN_FIXED_RIGHT}`,
    title: '',
    ...props,
});
