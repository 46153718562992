// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import ProjectServerCard from 'client/project/containers/projectItem/tabs/ProjectServerTab/ProjectServerCard/ProjectServerCard';
import { RootState } from 'client/core/store';
import { connect } from 'react-redux';
import {
    CutTitle,
    Loader,
} from 'common/components';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    INTENT_TYPE,
    VIEW_TYPE,
} from 'common/constants';
import { StyledList } from 'client/common/styles/List';
import {
    Action,
    Label,
    Translate,
} from '@plesk/ui-library';
import { VmStatus } from 'common/components/VmStatus';
import {
    HostnameActionContainer,
    HostnameCell,
} from 'client/project/containers/projectItem/tabs/ProjectServerTab/ProjectServersList/Styles';
import CopyText from 'common/containers/CopyText/CopyText';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import LocationCell from 'common/components/IconCell/LocationCell';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import ProjectServerActions from 'client/project/containers/projectServer/ProjectServerItem/ProjectServerHeader/ProjectServerActions';
import { primaryIps } from 'common/api/resources/Ip';
import { getActionColumnProps } from 'common/helpers/list';
import List from 'common/components/List/List';
import { useRequestCancellationEffect } from 'common/hooks/useRequestCancellationEffect';
import { CancelTokenSource } from 'axios';
import * as computeResourceVmActions from 'common/modules/computeResourceVm/actions';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { PaginatedCardList } from 'client/common/components/Card';
import { Link } from 'react-router-dom';
import { pathTo } from 'common/helpers/core';

export type ProjectServersListProps =
    RouteComponentProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const columns = [{
    width: '15%',
    key: 'colName',
    title: <Translate content="projects.server.list.colName" />,
    cellProps: {
        className: 'cell-os',
    },
}, {
    key: 'colDescription',
    className: 'header-default',
    title: <Translate content="projects.server.list.colDescription" />,
    cellProps: {
        className: 'cell-description',
    },
}, {
    width: '15%',
    key: 'colStatus',
    title: <Translate content="projects.server.list.colStatus" />,
    className: 'header-status',
    cellProps: {
        className: 'cell-status',
    },
}, {
    width: '15%',
    key: 'colIp',
    className: 'header-default',
    title: <Translate content="projects.server.list.colIp" />,
}, {
    width: '15%',
    key: 'colLocation',
    title: <Translate content="projects.server.list.colLocation" />,
    className: 'header-location',
}, getActionColumnProps(),
];

export const ProjectServersList: React.FC<ProjectServersListProps> = ({
    view,
    isLoading,
    history,
    location: { pathname },
    servers,
    project,
    computeResourceVmActions: { getProjectVms },
}) => {
    const isFirstLoading = useRequestCancellationEffect(
        token => loadPaginated(1, token),
        []
    );

    const loadPaginated = React.useCallback(
        (page: number, cancelToken?: CancelTokenSource) => getProjectVms(project.id, { page }, cancelToken),
        [ getProjectVms, project ]
    );

    const data = servers.data.map((item) => ({
        colName: (
            <HostnameCell>
                {item.settings.os_image.icon && (
                    <img src={item.settings.os_image.icon} alt={item.settings.os_image.name} />
                )}
                <HostnameActionContainer>
                    <Action
                        component={Link}
                        to={pathTo(`servers/${item.id}#overview`)}
                    >
                        {item.name}
                    </Action>
                    {item.is_suspended && (
                        <>
                                &nbsp;
                            <Label intent={INTENT_TYPE.WARNING}><Translate content="servers.status.suspended" /></Label>
                        </>
                    )}
                </HostnameActionContainer>
            </HostnameCell >
        ),
        colDescription: <CutTitle style={{ color: '#737373' }} title={item.description} />,
        colStatus: (
            <VmStatus
                status={item.status}
                isProcessing={item.is_processing}
                progress={item.progress}
                compact={true}
            />
        ),
        colIp: primaryIps(item.ips).map(ip => (
            <CopyText key={ip.id}>{ip.ip}</CopyText>
        )),
        colLocation: item.location && (
            <LocationCell location={item.location} />
        ),
        colActions: <ProjectServerActions server={item} iconSize="16" />,
        key: item.id.toString(),
    }));

    return (
        <Loader isLoading={isFirstLoading}>
            {view === VIEW_TYPE.CARD && (
                <PaginatedCardList
                    items={servers.data}
                    meta={servers.meta}
                    loadPaginated={loadPaginated}
                    isLoading={isLoading}
                    component={server => <ProjectServerCard key={server.id} isDisabled={isLoading} vm={server} />}
                />
            )}
            {view === VIEW_TYPE.LIST && (
                <StyledList>
                    <List
                        isLoading={isLoading}
                        meta={servers.meta}
                        loadItems={loadPaginated}
                        columns={columns}
                        data={data}
                        emptyView={null}
                    />
                </StyledList>
            )}
            {!data.length && (
                <EmptyView
                    title="projects.server.emptyView.title"
                    icon="card-row"
                />
            )}
        </Loader>
    );
};

const mapStateToProps = (state: RootState) => ({
    view: state.app.viewType,
    servers: state.project.servers.list,
    project: state.project.projects.item,
    isLoading: state.app.loadingFlags.has(LOADING_FLAGS.PROJECT_VM_LIST),
    isSaving: state.app.loadingFlags.has(LOADING_FLAGS.PROJECT_VM_ITEM),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    computeResourceVmActions: bindActionCreators(computeResourceVmActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectServersList));
