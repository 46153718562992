// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    IMenuItem,
    menuItems,
} from 'client/menu/menuItems';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as authActions from 'common/modules/auth/actions';
import { RootState } from 'client/core/store';
import { connect } from 'react-redux';
import { CLIENT_MENU } from 'client/menu/constants/test';
import { ICONS } from 'common/constants';
import {
    MenuItem,
    MenuItemLink,
    MobileMenu,
    NavContainer,
    NavMenu,
    StyledClientMenu,
} from 'client/menu/containers/Menu/Styles';
import {
    Translate,
    Icon,
} from '@plesk/ui-library';
import { hasPermissionInRoles } from 'common/modules/permission/selectors';

interface IMenuProps {
    isActive: (path: string) => boolean;
    handleClick: (path: string) => () => void;
}

export type MenuProps =
    IMenuProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const Menu: React.FC<MenuProps> = ({
    handleClick,
    authActions: {
        logout,
    },
    isActive,
    auth: { user },
    settings,
    featureFlags,
}) => {
    const [isOpen, setOpen] = React.useState<boolean>(false);

    const toggleMenu = (e: React.MouseEvent<HTMLElement>) => {
        setOpen(!isOpen);

        e.preventDefault();
    };

    const renderMenuItems = () =>
        menuItems
            .filter((item: IMenuItem): boolean => !item.permission || hasPermissionInRoles(user.roles, ...item.permission))
            .filter((item: IMenuItem): boolean => !item.settingFeature || !settings[item.settingFeature])
            .filter((item: IMenuItem) => item.feature === undefined || featureFlags.has(item.feature))
            .map((item, key) => {
                const Wrapper = item.path ? 'li' : React.Fragment;
                const Component = item.path ? MenuItemLink : MenuItem;
                return (
                    <Wrapper key={key}>
                        <Component to={item.path} $isActive={isActive(item.path)}>
                            <Icon
                                className="icon"
                                name={item.icon}
                                size={32}
                            />
                            <Translate content={item.value} />
                        </Component>
                    </Wrapper>
                );
            });

    return (
        <StyledClientMenu data-cy={CLIENT_MENU}>
            <NavContainer>
                <MobileMenu onClick={toggleMenu}>
                    <Icon name={isOpen ? ICONS.CLOSE : ICONS.MENU}/>
                    <span>Projects</span>
                </MobileMenu>
                <NavMenu isOpen={isOpen}>
                    {renderMenuItems()}
                    <li>
                        <MenuItem onClick={logout} $isActive={false}>
                            <Icon
                                className="icon"
                                name={ICONS.ARROW_RIGHT_IN}
                                size={32}
                            />
                            <Translate content="auth.logout"/>
                        </MenuItem>
                    </li>
                </NavMenu>
            </NavContainer>
        </StyledClientMenu>
    );
};

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
    settings: state.settings.features,
    featureFlags: state.app.features,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    authActions: bindActionCreators(authActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
