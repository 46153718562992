// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    ButtonGroup,
    Form,
    FormField,
    FormFieldCheckbox,
    setIn,
    Translate,
} from '@plesk/ui-library';
import { IVmResponse } from 'common/api/resources/ComputeResourceVm';
import MigrationFormMetric from 'admin/computeResourceVm/containers/MigrationForm/MigrationFormMetric';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as serversMigrationActions from 'admin/serversMigration/actions';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { IServerMigrationRequest } from 'common/api/resources/ServersMigration';
import { Button } from 'admin/common/components/Button/Button';
import { INTENT_TYPE } from 'common/constants';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import {
    createOptionsLoader,
    filteredOptionsLoader,
} from 'common/components/Select/helpers';
import {
    computeResources,
    IComputeResourceResponse,
    VirtualizationType,
} from 'common/api/resources/ComputeResource';
import {
    ISelectOption,
    Tooltip,
} from 'common/components';
import {
    requiredRule,
    validate,
} from 'common/validator';
import FormErrors from 'common/components/Form/FormErrors/FormErrors';
import { TEST } from 'admin/computeResourceVm/constants';
import isInt from 'validator/lib/isInt';
import {
    convertToDataUnit,
    DataUnit,
} from 'common/helpers/units';
import { IFormProps } from 'common/components/Form/types';
import {
    ImageFormat,
    IStorageTypeResponse,
    KVMStorageTypes,
    STORAGE_TYPES_TRANSLATION_MAP,
    StorageType,
} from 'common/api/resources/StorageType';
import { SubInputs } from 'admin/common/styles/Styles';
import { StyledSegmentedControlBlock } from 'common/components/SegmentedControl/Styles';

interface IMigrationFormProps {
    servers: IVmResponse[];
    onSubmit?: () => void;
}

interface IFormErrors {
    migration: string[];
}

export type MigrationFormProps =
    IMigrationFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const MigrationForm: React.FC<MigrationFormProps> = ({
    onSubmit,
    servers,
    isCreating,
    formErrors,
    serversMigrationActions: {
        createServersMigration,
    },
    formErrorsActions: {
        clearFormErrors,
        setFormErrors,
    },
}) => {
    const sourceIds = React.useMemo(() => servers.map((vm: IVmResponse) => vm.compute_resource!.id), [servers]);
    const isKVMOnly = React.useMemo(
        () => servers.every((vm: IVmResponse) => vm.virtualization_type === VirtualizationType.KVM),
        [servers]
    );
    const [submitValues, setSubmitValues] = React.useState<IServerMigrationRequest>({
        is_live: true,
        preserve_ips: true,
        destination_compute_resource_id: 0,
        servers: servers.map(vm => vm.id),
    });
    const [selectedDst, setSelectedDst] = React.useState<ISelectOption|null>();
    const [storageTypes, setStorageTypes] = React.useState<IStorageTypeResponse[]>([]);

    React.useEffect(() => () => { clearFormErrors(); }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = async (values: IServerMigrationRequest) => {
        const rules = {
            destination_compute_resource_id: ({
                validator: (value: string) => isInt(value, { gt: 0 }),
                message: <Translate content="validate.fieldRequired" />,
                comparison: false,
            }),
        };

        if (values.convert_disks) {
            rules['storage_type'] = requiredRule(<Translate content="validate.fieldRequired" />);
            rules['image_format'] = requiredRule(<Translate content="validate.fieldRequired" />);
        }

        const errors = validate(values, rules);

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        await createServersMigration(values);
        onSubmit?.();
    };

    const handleFieldChange = (field: string, value: string|boolean) => {
        if (field === 'is_live' && value) {
            setSubmitValues({
                ...submitValues,
                is_live: true,
                preserve_ips: true,
                convert_disks: undefined,
                storage_type: undefined,
                image_format: undefined,
            });
            return;
        }

        setSubmitValues(setIn(submitValues, field, value));
    };

    const handleDestinationChange = (opt: ISelectOption) => {
        setStorageTypes(opt.storageTypes);

        const hasStorageType = submitValues.storage_type && opt.storageTypes
            .find((item: IStorageTypeResponse) => item.name === submitValues.storage_type);
        setSubmitValues(state => ({
            ...state,
            destination_compute_resource_id: parseInt(opt.value as string, 10),
            storage_type: hasStorageType ? submitValues.storage_type : undefined,
            image_format: hasStorageType ? submitValues.image_format : undefined,
        }));
        setSelectedDst(opt);
    };

    const loadComputeResourceOptions = filteredOptionsLoader(
        createOptionsLoader(
            computeResources.list,
            (cr: IComputeResourceResponse) => ({
                label: cr.name,
                value: cr.id.toString(),
                storageTypes: cr.storages
                    .filter(storage => storage.is_available_for_balancing)
                    .map(storage => storage.type),
            })
        ),
        (item) => !sourceIds.includes(parseInt(item.value as string, 10))
    );

    const totalMemory = servers.reduce((acc, vm) => acc + vm.specifications.ram, 0);
    const totalDisk = servers.reduce((acc, vm) => acc + (
        vm.usage.disk === undefined ? 0 : vm.usage.disk.actual_size
    ), 0);

    const handleChangeStorageType = (option?: StorageType) => {
        setSubmitValues(prevState => ({
            ...prevState,
            storage_type: option,
            image_format: submitValues.image_format && option && ![StorageType.FB, StorageType.NFS].includes(option)
                ? ImageFormat.RAW
                : prevState.image_format,
        }));
    };

    const handleChangeImageFormat = (option?: ImageFormat) => {
        setSubmitValues(prevState => ({
            ...prevState,
            image_format: option,
        }));
    };

    const imageFormats = (storageType?: StorageType) => {
        const storage = storageTypes.find(item => item.name === storageType);

        if (!storage) {
            return [];
        }

        return storage.formats;
    };

    return (
        <>
            <MigrationFormMetric
                data-cy={TEST.SERVERS_MIGRATION_FORM.METRICS.VM}
                title={<Translate content="computeResource.servers.migrationForm.totalServers" />}
                value={servers.length}
            />&nbsp;
            <MigrationFormMetric
                data-cy={TEST.SERVERS_MIGRATION_FORM.METRICS.MEMORY}
                title={<Translate content="computeResource.servers.migrationForm.totalMemory" />}
                value={`${convertToDataUnit(totalMemory, DataUnit.MiB)} MiB`}
            />&nbsp;
            <MigrationFormMetric
                data-cy={TEST.SERVERS_MIGRATION_FORM.METRICS.DISK}
                title={<Translate content="computeResource.servers.migrationForm.totalDisk" />}
                value={`${convertToDataUnit(totalDisk, DataUnit.GiB)} GiB`}
            />
            <Form
                id="serversMigrationForm"
                footerClassName="hidden"
                onFieldChange={handleFieldChange}
                onSubmit={handleSubmit}
                values={submitValues}
                errors={formErrors}
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <FormField
                    name="destination_compute_resource_id"
                    label={<Translate content="computeResource.servers.migrationForm.destination" />}
                >
                    {({ getId }: IFormProps<unknown>) => (
                        <AsyncSelectInput
                            value={selectedDst}
                            loadOptions={loadComputeResourceOptions}
                            onChange={handleDestinationChange}
                            debounceTimeout={1000}
                            additional={{ page: 1 }}
                            menuPosition="fixed"
                            isMulti={false}
                            data-cy={TEST.SERVERS_MIGRATION_FORM.DESTINATION}
                            inputId={getId()}
                        />
                    )}
                </FormField>
                <FormFieldCheckbox
                    name="is_live"
                    label={<Translate content="computeResource.servers.migrationForm.live"/>}
                />
                <FormFieldCheckbox
                    name="preserve_ips"
                    label={<Translate content="computeResource.servers.migrationForm.preserveIPs"/>}
                    disabled={submitValues.is_live}
                />
                {
                    (formErrors as IFormErrors)?.migration &&
                        <FormErrors
                            title={<Translate content="computeResource.servers.migrationForm.errors"/>}
                            errors={(formErrors as IFormErrors).migration}
                        />
                }
                {isKVMOnly && (
                    <>
                        <Tooltip
                            shown={submitValues.is_live}
                            title={<Translate content="computeResource.servers.migrationForm.tooltips.convertDisks"/>}
                        >
                            <FormFieldCheckbox
                                name="convert_disks"
                                label={<Translate content="computeResource.servers.migrationForm.convertDisks"/>}
                                disabled={submitValues.is_live}
                            />
                        </Tooltip>
                        <SubInputs>
                            <FormField
                                name="storage_type"
                                label={<Translate content="computeResource.servers.migrationForm.storageType" />}
                            >
                                <StyledSegmentedControlBlock className="pul-segmented-control">
                                    <ButtonGroup>
                                        {KVMStorageTypes.map((storageType, i) => (
                                            <Button
                                                key={i}
                                                ghost={true}
                                                onClick={() => handleChangeStorageType(storageType)}
                                                disabled={!submitValues.convert_disks || !storageTypes.find(item => item.name === storageType)}
                                                tooltip={!storageTypes.find(item => item.name === storageType) && (
                                                    <Translate content="computeResource.servers.migrationForm.tooltips.crDoesNotSupportStorageType" />
                                                )}
                                                className={submitValues.storage_type === storageType ? 'pul-button--selected' : undefined}
                                            >
                                                {STORAGE_TYPES_TRANSLATION_MAP[storageType]}
                                            </Button>
                                        ))}
                                    </ButtonGroup>
                                </StyledSegmentedControlBlock>
                            </FormField>
                            <FormField
                                name="image_format"
                                label={<Translate content="computeResource.servers.migrationForm.imageFormat" />}
                            >
                                <StyledSegmentedControlBlock className="pul-segmented-control">
                                    <ButtonGroup>
                                        {[ImageFormat.RAW, ImageFormat.QCOW2].map((format, i) => (
                                            <Button
                                                key={i}
                                                ghost={true}
                                                onClick={() => handleChangeImageFormat(format)}
                                                disabled={!submitValues.convert_disks || !imageFormats(submitValues.storage_type).includes(format)}
                                                className={submitValues.image_format === format ? 'pul-button--selected' : undefined}
                                            >
                                                {format}
                                            </Button>
                                        ))}
                                    </ButtonGroup>
                                </StyledSegmentedControlBlock>
                            </FormField>
                        </SubInputs>
                    </>
                )}
                <Button
                    type="submit"
                    form="serversMigrationForm"
                    fill={true}
                    intent={INTENT_TYPE.PRIMARY}
                    size="lg"
                    isLoading={isCreating}
                >
                    <Translate content="computeResource.servers.migrationForm.submit" />
                </Button>
            </Form>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    formErrors: nestStringProperties(state),
    isCreating: state.app.loadingFlags.has(LOADING_FLAGS.SERVERS_MIGRATION_CREATE),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    serversMigrationActions: bindActionCreators(serversMigrationActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MigrationForm);
