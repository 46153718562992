// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    AnyAction,
    Dispatch,
} from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    AxiosPromise,
    CancelTokenSource,
} from 'axios';
import {
    api,
    IApiResponse,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import { HTTP_CODES } from 'common/api/constants';
import { bakeForegroundToast } from 'common/modules/app/toaster/actions';
import { INTENT_TYPE } from 'common/constants';
import { clearFormErrors } from 'common/modules/app/formErrors/actions';

interface IBaseAction<Response> {
    dispatch: Dispatch;
    loadingFlag: LOADING_FLAGS;
    action: (param: Response) => AnyAction;
}

interface IPaginatedAction<Response> {
    dispatch: Dispatch;
    loadingFlag: LOADING_FLAGS;
    action: (param: IPaginateApiResponse<Response>) => AnyAction;
}

interface ICreateAction<Request, Response> extends IBaseAction<Response> {
    data: Request;
    apiCall: (r: Request) => AxiosPromise<IApiResponse<Response>>;
    translations: {
        success: string;
    };
}

interface IListAction<Response> extends IBaseAction<Response> {
    apiCall: () => AxiosPromise<IApiResponse<Response>>;
}

interface INestedListAction<Response> extends IBaseAction<Response> {
    apiCall: (parentId: number) => AxiosPromise<IApiResponse<Response>>;
}

interface IPaginateListAction<Response> extends IPaginatedAction<Response> {
    apiCall: () => AxiosPromise<IPaginateApiResponse<Response>>;
}

interface IPaginateSiblingsListAction<Response> extends IPaginatedAction<Response> {
    apiCall: (parentId: number) => AxiosPromise<IPaginateApiResponse<Response>>;
}

interface ILoadOnScroll<Response> extends IBaseAction<Response> {
    isLoading: boolean;
    nextPage: string;
}

interface IRemoveAction<Response> extends IBaseAction<Response> {
    apiCall: (id: number) => AxiosPromise;
    setLoadingAction: (id: number, isDeleting: boolean) => AnyAction;
    translations: {
        success: string;
    };
}

interface IRemoveSilentAction {
    dispatch: Dispatch;
    loadingFlag: LOADING_FLAGS;
    apiCall: (id: number, force?: boolean) => AxiosPromise;
    setLoadingAction: (id: number, isDeleting: boolean) => AnyAction;
    translations: {
        success: string;
    };
}

interface IRemoveBatchAction<Response> extends IBaseAction<Response> {
    apiCall: (ids: number[], force?: boolean) => AxiosPromise;
    translations: {
        success: string;
    };
}

interface IRemoveBatchSilentAction {
    dispatch: Dispatch;
    loadingFlag: LOADING_FLAGS;
    apiCall: (ids: number[], force?: boolean) => AxiosPromise;
    translations: {
        success: string;
    };
}

interface IGetAction<Response> extends IBaseAction<Response> {
    apiCall: (id: number, cancelToken?: CancelTokenSource) => AxiosPromise<IApiResponse<Response>>;
}

interface IUpdateAction<Request, Response> extends IBaseAction<Response> {
    apiCall: (id: number, data: Request) =>  AxiosPromise<IApiResponse<Response>>;
    data: Request;
    translations: {
        success: string;
    };
}

async function loadOnScroll<Response>(params: ILoadOnScroll<Response>) {
    const { dispatch, loadingFlag, action, isLoading, nextPage } = params;

    if (nextPage && !isLoading) {
        dispatch(setIsLoading(loadingFlag));

        try {
            const result = await api.get(nextPage);

            if (result.status === HTTP_CODES.OK) {
                dispatch(action(result.data));
            }

            return result;
        } finally {
            dispatch(unsetIsLoading(loadingFlag));
        }
    }

    return;
}

async function create<Request, Response>(params: ICreateAction<Request, Response>) {
    const { dispatch, data, loadingFlag, action, apiCall, translations } = params;

    dispatch(setIsLoading(loadingFlag));

    try {
        const result = await apiCall(data);

        if ([HTTP_CODES.CREATED, HTTP_CODES.OK].includes(result.status)) {
            bakeForegroundToast(INTENT_TYPE.SUCCESS, translations.success)(dispatch);
            dispatch(action(result.data.data));
            dispatch(clearFormErrors());
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(loadingFlag));
    }
}

async function list<Response>(params: IListAction<Response>) {
    const { dispatch, loadingFlag, apiCall, action } = params;

    dispatch(setIsLoading(loadingFlag));

    try {
        const result = await apiCall();

        if (result.status === HTTP_CODES.OK) {
            dispatch(action(result.data.data));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(loadingFlag));
    }
}

async function nestedList<Response>(parentId: number, params: INestedListAction<Response>) {
    const { dispatch, loadingFlag, apiCall, action } = params;

    dispatch(setIsLoading(loadingFlag));

    try {
        const result = await apiCall(parentId);

        if (result.status === HTTP_CODES.OK) {
            dispatch(action(result.data.data));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(loadingFlag));
    }
}

async function paginateList<Response>(params: IPaginateListAction<Response>) {
    const { dispatch, loadingFlag, apiCall, action } = params;

    dispatch(setIsLoading(loadingFlag));

    try {
        const result = await apiCall();

        if (result.status === HTTP_CODES.OK) {
            dispatch(action(result.data));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(loadingFlag));
    }
}

async function paginateSiblingsList<Response>(parentId: number, params: IPaginateSiblingsListAction<Response>) {
    const { dispatch, loadingFlag, apiCall, action } = params;

    dispatch(setIsLoading(loadingFlag));

    try {
        const result = await apiCall(parentId);

        if (result.status === HTTP_CODES.OK) {
            dispatch(action(result.data));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(loadingFlag));
    }
}

async function get<Response>(id: number, params: IGetAction<Response>, cancelToken?: CancelTokenSource) {
    const { dispatch, loadingFlag, apiCall, action } = params;

    dispatch(setIsLoading(loadingFlag));

    try {
        const result = await apiCall(id, cancelToken);

        if (result.status === HTTP_CODES.OK) {
            dispatch(action(result.data.data));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(loadingFlag));
    }
}

async function remove(id: number, params: IRemoveAction<number>) {
    const { dispatch, loadingFlag, apiCall, action, setLoadingAction, translations } = params;

    dispatch(setIsLoading(loadingFlag));
    dispatch(setLoadingAction(id, true));

    try {
        const result = await apiCall(id);

        bakeForegroundToast(INTENT_TYPE.SUCCESS, translations.success)(dispatch);
        dispatch(action(id));

        return result;
    } finally {
        dispatch(unsetIsLoading(loadingFlag));
        dispatch(setLoadingAction(id, false));
    }
}

async function removeSilent(id: number, params: IRemoveSilentAction, force?: boolean) {
    const { dispatch, loadingFlag, apiCall, setLoadingAction, translations } = params;

    dispatch(setIsLoading(loadingFlag));
    dispatch(setLoadingAction(id, true));

    try {
        const result = await apiCall(id, force);
        if (result.status === HTTP_CODES.NO_CONTENT) {
            bakeForegroundToast(INTENT_TYPE.SUCCESS, translations.success)(dispatch);
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(loadingFlag));
        dispatch(setLoadingAction(id, false));
    }
}

async function removeBatch(ids: number[], params: IRemoveBatchAction<number[]>, force?: boolean) {
    const { dispatch, loadingFlag, apiCall, action, translations } = params;

    dispatch(setIsLoading(loadingFlag));

    try {
        const result = await apiCall(ids, force);

        if (result.status === HTTP_CODES.OK) {
            bakeForegroundToast(
                INTENT_TYPE.SUCCESS,
                translations.success,
                { count: result.data.length }
            )(dispatch);
            dispatch(action(result.data));
        }

        return result.data;
    } finally {
        dispatch(unsetIsLoading(loadingFlag));
    }
}

async function removeBatchSilent(ids: number[], params: IRemoveBatchSilentAction, force?: boolean) {
    const { dispatch, loadingFlag, apiCall, translations } = params;

    dispatch(setIsLoading(loadingFlag));

    try {
        const result = await apiCall(ids, force);

        if (result.status === HTTP_CODES.OK) {
            bakeForegroundToast(
                INTENT_TYPE.SUCCESS,
                translations.success,
                { count: result.data.length }
            )(dispatch);
        }

        return result.data;
    } finally {
        dispatch(unsetIsLoading(loadingFlag));
    }
}

async function update<Request, Response>(id: number, params: IUpdateAction<Request, Response>) {
    const { dispatch, loadingFlag, apiCall, action, translations, data } = params;

    dispatch(setIsLoading(loadingFlag));

    try {
        const result = await apiCall(id, data);
        if (result.status === HTTP_CODES.OK) {
            dispatch(clearFormErrors());
            bakeForegroundToast(INTENT_TYPE.SUCCESS, translations.success)(dispatch);
            dispatch(action(result.data.data));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(loadingFlag));
    }
}

export {
    loadOnScroll,
    paginateList,
    paginateSiblingsList,
    create,
    get,
    list,
    nestedList,
    remove,
    removeSilent,
    removeBatch,
    removeBatchSilent,
    update,
};
