// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import {
    IBackupListRequest,
    IBackupResponse,
} from 'common/api/resources/Backup/model';
import { QueryBuilder } from 'common/api/resources/Request/QueryBuilder';
import { ITaskResponse } from 'common/api/resources/Task';
import { CancelTokenSource } from 'axios';

export const backups = {
    list: (params?: IBackupListRequest, cancelToken?: CancelTokenSource) =>
        api.get<IPaginateApiResponse<IBackupResponse[]>>('backups', {
            params: new QueryBuilder(params).build(),
            cancelToken: cancelToken?.token,
        }),
    remove: (id: number) => api.delete(`backups/${id}`),
    restore: (id: number) => api.post<IApiResponse<ITaskResponse>>(`backups/${id}/restore`),
    removeBatch: (ids: number[]) => api.delete('backups', { data: { ids } }),
    getNextScheduledDate: () => api.get('backups/next_scheduled_date'),
};
