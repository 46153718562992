// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    IVmUpdateRequest,
    updateFqdns,
} from 'common/api/resources/ComputeResourceVm';
import * as React from 'react';
import { RootState } from 'admin/core/store';
import { getProcessedErrors } from 'common/modules/app/formErrors/selectors';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as computeResourceVmActions from 'common/modules/computeResourceVm/actions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import * as userProjectsActions from 'admin/user/actions/projects';
import { connect } from 'react-redux';
import { IProjectResponse } from 'common/api/resources/Project';
import {
    Form,
    FormField,
    FormFieldSelect,
    FormFieldText,
    Section,
    Translate,
} from '@plesk/ui-library';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import { createOptionsLoader } from 'common/components/Select/helpers';
import {
    IUserResponse,
    users,
} from 'common/api/resources/User';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { Button } from 'admin/common/components/Button/Button';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    domainRule,
    intMinRule,
    requiredRule,
    validate,
} from 'common/validator';
import {
    Loader,
    Tooltip,
} from 'common/components';
import { ValueType } from 'react-select';
import { IFormProps } from 'common/components/Form/types';
import { InlineFields } from 'admin/computeResource/page/containers/dialogs/ServerCreateDialog/Styles';
import { shouldRegisterFqdnOnServerCreate } from 'common/modules/settings/selectors';
import { canChangeHostname } from 'common/services/ServerCapabilities';

export interface ISelectOption {
    label: string;
    value: number;
}

interface IServerEditDialogProps {
    computeResourceVmId: number;
    onClose: () => void;
}

export type ServerEditDialogProps =
    IServerEditDialogProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const ServerEditDialog: React.FC<ServerEditDialogProps> = ({
    registerFqdn,
    computeResourceVmId,
    computeResourceVm,
    formErrors,
    projects,
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
    userProjectsActions: { getProjects },
    permissions: { canGetUsers },
    computeResourceVmActions: {
        getComputeResourceVm,
        updateComputeResourceVm,
    },
    isLoading,
    isSaving,
    isProjectsLoading,
    onClose,
}) => {
    React.useEffect(() => {
        getComputeResourceVm(computeResourceVmId);
    }, [getComputeResourceVm, computeResourceVmId]);

    const [submitValues, setSubmitValues] = React.useState<IVmUpdateRequest>({
        name: computeResourceVm.name,
        user_id: computeResourceVm.user.id,
        project_id: computeResourceVm.project.id,
    });
    const loadUserProjects = React.useCallback((userId: number) => {
        getProjects(userId, {
            filters: {
                owner_id: userId,
            },
        });
    }, [getProjects]);

    React.useEffect(() => {
        if (computeResourceVm.user.id !== 0) {
            setSubmitValues({
                name: computeResourceVm.name,
                user_id: computeResourceVm.user.id,
                project_id: computeResourceVm.project.id,
            });
            loadUserProjects(computeResourceVm.user.id);
        }

        return () => {
            clearFormErrors();
        };
    }, [clearFormErrors, computeResourceVm, loadUserProjects]);

    React.useEffect(() => {
        if (submitValues.user_id === computeResourceVm.user.id) {
            setSubmitValues({
                ...submitValues,
                project_id: computeResourceVm.project.id,
            });
        } else {
            const defaultProject = projects.data.find((item: IProjectResponse) => item.is_default);
            if (defaultProject !== undefined) {
                setSubmitValues({
                    ...submitValues,
                    project_id: defaultProject.id,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projects]);

    const projectOptions = React.useMemo(() => projects.data.map((item: IProjectResponse) => ({
        label: item.name,
        value: item.id,
    })), [projects]);
    const isHostnameChangingDisabled = React.useMemo(
        () => !canChangeHostname(computeResourceVm),
        [computeResourceVm]
    );

    const loadUserOptions = createOptionsLoader(
        users.list,
        (item: IUserResponse) => ({
            label: item.email,
            value: item.id,
        }),
        canGetUsers
    );

    const handleUserChange = (option: ValueType<ISelectOption>) => {
        const value = option ? (option as ISelectOption).value : 0;
        if (value) {
            setSubmitValues(values => ({
                ...values,
                user_id: value,
                project_id: 0,
            }));
            loadUserProjects(value);
        }
    };

    const handleProjectChange = () => (value: number) => {
        setSubmitValues(values => ({
            ...values,
            project_id: value,
        }));
    };

    const handleSubmit = async (values: IVmUpdateRequest) => {
        const rules = {
            name: requiredRule(<Translate content="validate.fieldRequired" />),
            user_id: intMinRule(<Translate content="validate.fieldRequired" />, 1),
            project_id: intMinRule(<Translate content="validate.fieldRequired" />, 1),
        };

        if (registerFqdn && computeResourceVm.name !== values.name) {
            rules.name = domainRule(<Translate content="validate.badDomain" />);
        }

        const errors = validate<IVmUpdateRequest>(values, rules);

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        try {
            if (registerFqdn && computeResourceVm.name !== values.name) {
                values.fqdns = updateFqdns(computeResourceVm, values.name!);
            }

            await updateComputeResourceVm(computeResourceVm.id, values);

            onClose();
        } catch (e) {
            throw e;
        }
    };

    return (
        <Loader isLoading={isLoading} center={false}>
            <Form
                id="vmEditForm"
                footerClassName="hidden"
                values={submitValues}
                errors={formErrors}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                hideRequiredLegend={true}
                vertical={true}
                onSubmit={handleSubmit}
            >
                <Section>
                    <Tooltip
                        shown={isHostnameChangingDisabled}
                        title={<Translate content="servers.guestToolsMissing" />}
                    >
                        <FormFieldText
                            name="name"
                            description={<Translate content="computeResource.servers.form.nameDescription" />}
                            size={SIZE.FILL}
                            maxLength={63}
                            required={true}
                            label={<Translate content="computeResource.servers.form.name" />}
                            disabled={isHostnameChangingDisabled}
                        />
                    </Tooltip>
                    <InlineFields>
                        <FormField
                            name="user_id"
                            size={SIZE.FILL}
                            required={true}
                            label={<Translate content="computeResource.servers.form.user" />}
                        >
                            {({ getId }: IFormProps<unknown>) => (
                                <AsyncSelectInput
                                    inputId={getId()}
                                    menuPosition="fixed"
                                    loadOptions={loadUserOptions}
                                    onChange={handleUserChange}
                                    debounceTimeout={1000}
                                    additional={{ page: 1 }}
                                    defaultValue={{
                                        value: computeResourceVm.user.id,
                                        label: computeResourceVm.user.email,
                                    }}
                                />
                            )}
                        </FormField>
                        <FormFieldSelect
                            id="project_id"
                            name="project_id"
                            size={SIZE.FILL}
                            required={true}
                            label={<Translate content="computeResource.servers.form.project" />}
                            value={submitValues.project_id}
                            disabled={isProjectsLoading || projectOptions.length === 0}
                            onChange={handleProjectChange}
                        >
                            {projectOptions.map((option, key) => (
                                <option key={key} value={option.value}>{option.label}</option>
                            ))}
                        </FormFieldSelect>
                    </InlineFields>
                </Section>
                <Button
                    type="submit"
                    form="vmEditForm"
                    fill={true}
                    intent={INTENT_TYPE.PRIMARY}
                    size={SIZE.LG}
                    isLoading={isSaving}
                >
                    <Translate content="computeResource.servers.form.saveBtn" />
                </Button>
            </Form>
        </Loader>
    );
};

const mapStateToProps = (state: RootState) => ({
    registerFqdn: shouldRegisterFqdnOnServerCreate(state),
    formErrors: getProcessedErrors(state),
    permissions: {
        canGetUsers: hasPermission(state, PERMISSION_LIST.MANAGE_USERS),
    },
    projects: state.project.list,
    computeResourceVm: state.computeResourceVm.item,
    isLoading: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM),
    isProjectsLoading: state.app.loadingFlags.has(LOADING_FLAGS.PROJECT_LIST),
    isSaving: state.app.loadingFlags.has(LOADING_FLAGS.PROJECT_VM_ITEM),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    computeResourceVmActions: bindActionCreators(computeResourceVmActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
    userProjectsActions: bindActionCreators(userProjectsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServerEditDialog);
