// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    requiredRule,
    validate,
} from 'common/validator';
import * as isoImageActions from 'common/modules/isoImage/actions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import {
    Form,
    FormField,
    FormFieldCheckbox,
    FormFieldSelect,
    FormFieldText,
    Section,
    SelectOption,
    setIn,
    Translate,
} from '@plesk/ui-library';
import {
    INTENT_TYPE,
    OS_TYPES,
    SIZE,
} from 'common/constants';
import { Button } from 'admin/common/components/Button/Button';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import {
    IIsoImageRequest,
    IsoChecksumMethod,
    IsoImageVisibility,
} from 'common/api/resources/IsoImage/model';
import { IFormProps } from 'common/components/Form/types';
import { IconSelector } from 'admin/icon/components/IconSelector/IconSelector';
import { IconType } from 'common/api/resources/Icon';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import { createOptionsLoader } from 'common/components/Select/helpers';
import {
    IUserResponse,
    users,
} from 'common/api/resources/User';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import { SegmentedControl } from 'common/components/SegmentedControl/SegmentedControl';

interface IIsoImageFormProps {
    onSubmit: () => void;
    fields: string[];
}

export type IsoImageFormProps =
    IIsoImageFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const FIELDS = {
    NAME: 'name',
    ICON: 'icon',
    USER: 'user',
    VISIBILITY: 'visibility',
    OS: 'os',
    USE_TLS: 'use_tls',
    URL: 'url',
    CHECKSUM_METHOD: 'checksum_method',
    CHECKSUM: 'checksum',
    SHOW_URL_AND_CHECKSUM: 'show_url_and_checksum',
    SHOW_TLS: 'show_tls',
};

export const IsoImageForm: React.FC<IsoImageFormProps> = ({
    isoImage,
    user,
    isItemSaving,
    formErrors,
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
    isoImageActions: {
        unsetIsoImageItem,
        updateIsoImage,
        createIsoImage,
    },
    onSubmit,
    canGetUsers,
    canGetIcons,
    fields,
}) => {
    const [submitValues, setSubmitValues] = React.useState({
        ...isoImage,
        user_id: isoImage.user.id > 0 ? isoImage.user.id : user.id,
    });

    React.useEffect(() => () => {
        clearFormErrors();
        unsetIsoImageItem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async (values: IIsoImageRequest) => {
        const rules = {
            name: requiredRule(<Translate content="validate.fieldRequired" />),
            os_type: requiredRule(<Translate content="validate.fieldRequired" />),
            iso_url: requiredRule(<Translate content="validate.fieldRequired" />),
            use_tls: requiredRule(<Translate content="validate.fieldRequired" />),
        };

        if (values.iso_checksum_method || values.iso_checksum) {
            rules['iso_checksum_method'] = requiredRule(<Translate content="validate.fieldRequired" />);
            rules['iso_checksum'] = requiredRule(<Translate content="validate.fieldRequired" />);
        }

        const errors = validate<IIsoImageRequest>(values, rules);

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        isoImage.id
            ? await updateIsoImage(isoImage.id, values)
            : await createIsoImage(values);
        onSubmit();
    };

    const loadUserOptions = createOptionsLoader(
        users.list,
        (item: IUserResponse) => ({
            label: item.email,
            value: item.id,
        }),
        canGetUsers
    );

    const handleFieldChange = (field: string, value: string) => setSubmitValues(setIn(submitValues, field, value));

    const handleChangeIcon = (value: number) => {
        setSubmitValues(state => ({
            ...state,
            icon_id: value,
        }));
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleUserChange = (option: any) => {
        if (option !== null) {
            setSubmitValues(values => ({
                ...values,
                user_id: option.value,
            }));
        }
    };

    return (
        <>
            <Form
                id="isoImageForm"
                onSubmit={handleSubmit}
                values={submitValues}
                onFieldChange={handleFieldChange}
                footerClassName="hidden"
                errors={formErrors}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <Section>
                    {fields.includes(FIELDS.NAME) && (
                        <FormFieldText
                            name="name"
                            size={SIZE.FILL}
                            label={<Translate content="isoImage.form.name" />}
                            required={true}
                        />
                    )}
                    {fields.includes(FIELDS.ICON) && canGetIcons && (
                        <FormField name="icon_id" label={<Translate content="isoImage.form.icon" />}>
                            {({ getId }: IFormProps<unknown>) => (
                                <IconSelector
                                    inputId={getId()}
                                    type={IconType.OS}
                                    onChange={handleChangeIcon}
                                    selected={submitValues.icon}
                                />
                            )}
                        </FormField>
                    )}
                    {fields.includes(FIELDS.USER) && canGetUsers && (
                        <FormField
                            name="user_id"
                            required={true}
                            label={<Translate content="isoImage.form.user" />}
                        >
                            {({ getId }: IFormProps<unknown>) => (
                                <AsyncSelectInput
                                    inputId={getId()}
                                    loadOptions={loadUserOptions}
                                    onChange={handleUserChange}
                                    debounceTimeout={1000}
                                    additional={{ page: 1 }}
                                    defaultValue={{
                                        value: isoImage.user.id > 0 ? isoImage.user.id : user.id,
                                        label: isoImage.user.id > 0 ? isoImage.user.email : user.email,
                                    }}
                                />
                            )}
                        </FormField>
                    )}
                    {fields.includes(FIELDS.VISIBILITY) && (
                        <FormField
                            name="visibility"
                            required={true}
                            value={submitValues.visibility}
                            label={<Translate content="isoImage.form.visibility" />}
                        >
                            {({ setValue }: IFormProps<string>) => (
                                <SegmentedControl
                                    buttons={[
                                        {
                                            title: <Translate content="isoImage.visibility.public" />,
                                            value: IsoImageVisibility.PUBLIC,
                                        },
                                        {
                                            title: <Translate content="isoImage.visibility.private" />,
                                            value: IsoImageVisibility.PRIVATE,
                                        },
                                    ]}
                                    selected={submitValues.visibility}
                                    onChange={setValue}
                                />
                            )}
                        </FormField>
                    )}
                    {fields.includes(FIELDS.OS) && (
                        <FormField
                            name="os_type"
                            required={true}
                            value={submitValues.os_type}
                            label={<Translate content="isoImage.form.osType" />}
                        >
                            {({ setValue }: IFormProps<string>) => (
                                <SegmentedControl
                                    buttons={[
                                        {
                                            title: OS_TYPES.LINUX,
                                            value: OS_TYPES.LINUX,
                                        },
                                        {
                                            title: OS_TYPES.WINDOWS,
                                            value: OS_TYPES.WINDOWS,
                                        },
                                    ]}
                                    selected={submitValues.os_type}
                                    onChange={setValue}
                                />
                            )}
                        </FormField>
                    )}
                    {fields.includes(FIELDS.URL) && (
                        <FormFieldText
                            name="iso_url"
                            size={SIZE.FILL}
                            label={<Translate content="isoImage.form.url" />}
                            required={true}
                        />
                    )}
                    {fields.includes(FIELDS.USE_TLS) && (
                        <FormFieldCheckbox
                            name="use_tls"
                            label={
                                <Translate content="isoImage.form.useTls" />
                            }
                        />
                    )}
                    {fields.includes(FIELDS.CHECKSUM_METHOD) && (
                        <FormFieldSelect
                            name="iso_checksum_method"
                            size={SIZE.FILL}
                            label={<Translate content="isoImage.form.checksumMethod" />}
                            clearable={true}
                        >
                            {Object.values(IsoChecksumMethod).map(mode => (
                                <SelectOption key={mode} value={mode}>
                                    {mode}
                                </SelectOption>
                            ))}
                        </FormFieldSelect>
                    )}
                    {fields.includes(FIELDS.CHECKSUM) && (
                        <FormFieldText
                            name="iso_checksum"
                            size={SIZE.FILL}
                            label={<Translate content="isoImage.form.checksum" />}
                        />
                    )}
                    {fields.includes(FIELDS.SHOW_URL_AND_CHECKSUM) && (
                        <FormFieldCheckbox
                            name="show_url_and_checksum"
                            label={
                                <Translate content="isoImage.form.showUrlAndChecksum" />
                            }
                        />
                    )}
                    {fields.includes(FIELDS.SHOW_TLS) && (
                        <FormFieldCheckbox
                            name="show_tls"
                            label={
                                <Translate content="isoImage.form.showTls" />
                            }
                        />
                    )}
                </Section>
            </Form>
            <Button
                type="submit"
                form="isoImageForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size={SIZE.LG}
                isLoading={isItemSaving}
            >
                <Translate content="isoImage.form.saveBtn" />
            </Button>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    isoImage: state.isoImage.item,
    user: state.auth.user,
    isItemSaving: state.app.loadingFlags.has(LOADING_FLAGS.ISO_IMAGE_ITEM_SAVE),
    canGetUsers: hasPermission(state, PERMISSION_LIST.MANAGE_USERS) || hasPermission(state, PERMISSION_LIST.GET_USERS),
    canGetIcons: hasPermission(state, PERMISSION_LIST.MANAGE_ICONS) || hasPermission(state, PERMISSION_LIST.GET_ICONS),
    formErrors: nestStringProperties(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    isoImageActions: bindActionCreators(isoImageActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(IsoImageForm);
