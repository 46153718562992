// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { RootState } from 'client/core/store';
import * as authActions from 'common/modules/auth/actions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import {
    Redirect,
    RouteComponentProps,
    withRouter,
} from 'react-router';
import {
    passwordRule,
    requiredRule,
    validate,
} from 'common/validator';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { NetworkErrorText } from 'common/modules/auth/components/NetworkErrorText';
import {
    FieldWithInfo,
    InfoBlock,
    StyledForm,
} from 'common/modules/auth/components/StyledForm';
import { LOGIN_FORM } from 'client/auth/constants/test';
import * as responseErrorActions from 'common/modules/app/responseError/actions';
import {
    Button,
    Form,
    Section,
    Translate,
} from '@plesk/ui-library';
import { FormFieldEmail } from 'common/components/Form/FormFieldEmail/FormFieldEmail';
import FormFieldPassword from 'common/components/Form/FormFieldPassword/FormFieldPassword';
import { IRegisterRequest } from 'common/api/resources/Auth';

export type RegisterFormProps =
    RouteComponentProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const RegisterForm: React.FC<RegisterFormProps> = ({
    authActions: actions,
    language,
    location,
    errors,
    isSigningUp,
    responseError,
    responseErrorActions: { clearResponseError },
    formErrorsActions: { setFormErrors },
    isAuthenticated,
}) => {
    const [submitValues, setSubmitValues] = React.useState<IRegisterRequest>({
        email: '',
        password: '',
        language_id: language?.id,
    });

    React.useEffect(() => () => {
        setFormErrors({});
        clearResponseError();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        setSubmitValues({
            ...submitValues,
            language_id: language?.id,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]);

    const createHandleChange = (property: string) => (val: string) => {
        setSubmitValues({
            ...submitValues,
            [property]: val,
        });
    };

    const handleRegister = async (values: IRegisterRequest) => {
        const token = new URLSearchParams(location.search).get('token');
        const { register } = actions;

        const validationErrors = validate<IRegisterRequest>(values, {
            email: requiredRule(<Translate content="validate.fieldRequired" />),
            password: passwordRule(<Translate content="validate.badPassword" />),
        });

        if (Object.keys(validationErrors).length) {
            setFormErrors(validationErrors);
            return;
        }

        if (!values.language_id) {
            delete values.language_id;
        }

        values.token = token;
        await register(values);
    };

    if (isAuthenticated) {
        return (<Redirect to="/" />);
    }

    return (
        <StyledForm>
            <Form
                onSubmit={handleRegister}
                applyButton={false}
                values={submitValues}
                vertical={true}
                errors={errors}
                cancelButton={false}
                hideRequiredLegend={true}
                submitButton={false}
                novalidate="novalidate"
            >
                <Section>
                    <FormFieldEmail
                        name="email"
                        label={<Translate content="auth.login" />}
                        data-cy={LOGIN_FORM.EMAIL}
                        size="fill"
                        required={false}
                        onChange={createHandleChange('email')}
                    />
                    <FieldWithInfo>
                        <FormFieldPassword
                            size="fill"
                            data-cy={LOGIN_FORM.PASSWORD}
                            name="password"
                            label={<Translate content="auth.password" />}
                            description={<Translate content="account.settings.passwordDescription" />}
                            onChange={createHandleChange('password')}
                        />
                        <InfoBlock>
                            <NetworkErrorText error={responseError} />
                        </InfoBlock>
                    </FieldWithInfo>
                    <Button
                        fill={true}
                        style={{ marginTop: '20px' }}
                        data-cy={LOGIN_FORM.SUBMIT}
                        intent="primary"
                        type="submit"
                        state={isSigningUp ? 'loading' : null}
                    >
                        <Translate content="auth.registerBtn" />
                    </Button>
                </Section>
            </Form>
        </StyledForm>
    );
};

const mapStateToProps = (state: RootState) => ({
    language: state.app.language,
    isAuthenticated: state.auth.isAuth,
    errors: state.app.formErrors,
    responseError: state.app.responseError,
    isSigningUp: state.app.loadingFlags.has(LOADING_FLAGS.REGISTER),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    authActions: bindActionCreators(authActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
    responseErrorActions: bindActionCreators(responseErrorActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterForm));
