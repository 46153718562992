// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { RootState } from 'client/core/store';
import * as authActions from 'common/modules/auth/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import * as responseErrorActions from 'common/modules/app/responseError/actions';
import isEmpty from 'validator/lib/isEmpty';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import { LoaderBar } from 'common/components';
import { validate } from 'common/validator';
import { LOGIN_FORM } from 'client/auth/constants/test';
import { SocialitePanel } from 'common/modules/auth/containers/LoginForm/Styles';
import {
    FieldWithInfo,
    FieldWithLoader,
    StyledForm,
} from 'common/modules/auth/components/StyledForm';
import {
    Button,
    Form,
    Section,
    Translate,
} from '@plesk/ui-library';
import { InfoBlock } from 'common/modules/auth/containers/ForgotPassword/Styles';
import { NetworkErrorText } from 'common/modules/auth/components/NetworkErrorText';
import { pathTo } from 'common/helpers/core';
import FormFieldPassword from 'common/components/Form/FormFieldPassword/FormFieldPassword';
import { ILoginRequest } from 'common/api/resources/Auth';
import { FormFieldEmail } from 'common/components/Form/FormFieldEmail/FormFieldEmail';
import { Link } from 'common/modules/auth/Styles';
import { Link as RouterLink } from 'react-router-dom';

export type LoginFormProps =
    RouteComponentProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const defaultValues = {
    email: '',
    password: '',
};

export const LoginForm: React.FC<LoginFormProps> = ({
    authActions: actions,
    errors,
    isLoggedIn,
    isLinkRequested,
    isPasswordRecoveryEnabled,
    responseError,
    socialiteProviders,
    responseErrorActions: { clearResponseError },
    formErrorsActions: { setFormErrors },
    history,
}) => {
    const [submitValues] = React.useState({ ...defaultValues });

    React.useEffect(() => () => {
        setFormErrors({});
        clearResponseError();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLogin = async(values: ILoginRequest) => {
        const { email, password } = values;
        const { login } = actions;

        const validationErrors = validate<ILoginRequest>(values, {
            email: {
                validator: isEmpty,
                message: <Translate content="validate.fieldRequired" />,
                comparison: true,
            },
            password: {
                validator: isEmpty,
                message: <Translate content="validate.fieldRequired" />,
                comparison: true,
            },
        });

        if (Object.keys(validationErrors).length) {
            setFormErrors(validationErrors);
            return;
        }

        await login({ email, password });
    };

    return (
        <StyledForm>
            <Form
                onSubmit={handleLogin}
                applyButton={false}
                values={submitValues}
                vertical={true}
                errors={errors}
                cancelButton={false}
                hideRequiredLegend={true}
                submitButton={false}
            >
                <Section>
                    <FieldWithLoader>
                        <FormFieldEmail
                            size="fill"
                            data-cy={LOGIN_FORM.EMAIL}
                            name="email"
                            label={<Translate content="auth.login"/>}
                            required={false}
                        />
                        <LoaderBar isLoading={isLinkRequested} />
                    </FieldWithLoader>
                    <FieldWithInfo>
                        <FormFieldPassword
                            size="fill"
                            data-cy={LOGIN_FORM.PASSWORD}
                            name="password"
                            label={<Translate content="auth.password"/>}
                            required={false}
                        />
                        <InfoBlock>
                            <NetworkErrorText error={responseError}/>
                        </InfoBlock>
                    </FieldWithInfo>
                    {socialiteProviders.length > 0 && (
                        <SocialitePanel>
                            {socialiteProviders.map((provider, key) => (
                                <a href={`/api/v1/socialite/${provider.provider}`} key={key}>
                                    <img src={provider?.icon?.url} alt={provider.provider} />
                                    <Translate content="auth.activeDirectorySignIn" />
                                </a>
                            ))}
                        </SocialitePanel>
                    )}
                    <Button
                        data-cy={LOGIN_FORM.SUBMIT}
                        fill={true}
                        intent="primary"
                        type="submit"
                        state={isLoggedIn ? 'loading' : null}
                    >
                        <Translate content="auth.loginBtn"/>
                    </Button>
                    {isPasswordRecoveryEnabled && (
                        <Link>
                            <Button
                                component={RouterLink}
                                to={pathTo('forgot_password')}
                                ghost={true}
                                data-cy={LOGIN_FORM.PASSWORD_RECOVERY_BUTTON}
                            >
                                <Translate content="auth.forgotPassword"/>
                            </Button>
                        </Link>
                    )}
                </Section>
            </Form>
        </StyledForm>
    );
};

const mapStateToProps = (state: RootState) => ({
    socialiteProviders: state.settings.socialite_providers.filter(item => item.enabled),
    errors: state.app.formErrors,
    isLoggedIn: state.app.loadingFlags.has(LOADING_FLAGS.LOGIN),
    responseError: state.app.responseError,
    isLinkRequested: state.app.loadingFlags.has(LOADING_FLAGS.PASSWORD_RESET_LINK),
    isPasswordRecoveryEnabled: state.settings.features.allow_password_recovery,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    authActions: bindActionCreators(authActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
    responseErrorActions: bindActionCreators(responseErrorActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginForm));
