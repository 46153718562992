// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { connect } from 'react-redux';
import { initEchoConnection } from 'common/services/EchoService';
import {
    SOCKET_CHANNELS,
    SOCKET_EVENTS,
} from 'common/constants';
import * as echoActions from 'admin/common/containers/EchoProvider/actions';
import {
    IUpdateComputeResource,
    IUpdateComputeResourceInstallStep,
    IUpdateSnapshot,
    IUpdateUser,
} from 'common/api/resources/ComputeResource';
import {
    IMoveComputeResourceVm,
    IUpdateComputeResourceVm,
    IUpdateDiskUsageComputeResourceVm,
    IUpdatePasswordComputeResourceVm,
    IUpdateServerLimits,
} from 'common/api/resources/ComputeResourceVm';
import { INotificationResponse } from 'common/api/resources/Notification';
import {
    IUpdateUpdateData,
    IUpgradeResult,
} from 'common/api/resources/Update';
import { IUpdateBackupResource } from 'common/api/resources/Backup';
import { IUpdateStorageResource } from 'common/api/resources/Storage';
import { updateUserTaskAction } from 'common/modules/task/actions';
import {
    IUpdateTask,
    IUpdateUserTask,
} from 'common/api/resources/Task';
import { IUpdateSolusVMLicense } from 'common/api/resources/License/solusVM';
import { IUpdateManagementNodeBackupResource } from 'common/api/resources/ManagementNodeBackup';
import { IClusterImportResource } from 'common/api/resources/ClusterImport';

export type EchoProviderProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const EchoProvider: React.FC<React.PropsWithChildren<EchoProviderProps>> = ({
    isAuthenticated,
    authData,
    userId,
    serverUuid,
    echoActions: {
        updateInstallStepAction,
        checkVersionAction,
        notificationAction,
        updateItemAction,
        updateTaskAction,
        updateUserAction,
        updateVmAction,
        updateVmDiskUsageAction,
        updateVmPasswordAction,
        moveVmAction,
        upgradeResultAction,
        updateBackupItemAction,
        updateManagementNodeBackupAction,
        updateComputeResourceStorageAction,
        updateSolusVMLicenseAction,
        updateClusterImportAction,
        updateServerLimitsAction,
        updateSnapshotItemAction,
    },
    echoUpdateUserTaskAction,
    children,
}) => {
    React.useEffect(() => {
        if (!isAuthenticated) {
            return;
        }

        const credentials = `${authData.token_type} ${authData.access_token}`;

        const echoConnection = initEchoConnection(credentials);

        const backupChannel = echoConnection.private(SOCKET_CHANNELS.BACKUP);
        const managementNodeBackupChannel = echoConnection.private(SOCKET_CHANNELS.MANAGEMENT_NODE_BACKUP);
        const storageChannel = echoConnection.private(SOCKET_CHANNELS.STORAGE);
        const computeResourceChannel = echoConnection.private(SOCKET_CHANNELS.COMPUTE_RESOURCE);
        const taskChannel = echoConnection.private(SOCKET_CHANNELS.TASK);
        const userChannel = echoConnection.private(`${SOCKET_CHANNELS.USER}.${userId}`);
        const updateChannel = echoConnection.private(SOCKET_CHANNELS.UPDATE);
        const userTaskChannel = echoConnection.private(`${SOCKET_CHANNELS.USER_TASK}.${userId}`);
        const solusVMLicenseChannel = echoConnection.private(SOCKET_CHANNELS.SOLUSVM_LICENSE);
        const clusterImportChannel = echoConnection.private(SOCKET_CHANNELS.CLUSTER_IMPORT);

        // register listeners
        backupChannel
            .listen(SOCKET_EVENTS.BACKUP_UPDATED, (e: IUpdateBackupResource) => updateBackupItemAction(e));

        managementNodeBackupChannel
            .listen(
                SOCKET_EVENTS.MANAGEMENT_NODE_BACKUP_UPDATED,
                (e: IUpdateManagementNodeBackupResource) => updateManagementNodeBackupAction(e)
            );

        storageChannel
            .listen(SOCKET_EVENTS.STORAGE_UPDATED, (e: IUpdateStorageResource) => updateComputeResourceStorageAction(e));

        computeResourceChannel
            .listen(SOCKET_EVENTS.UPDATE_COMPUTE_RESOURCE, (e: IUpdateComputeResource) => updateItemAction(e))
            .listen(SOCKET_EVENTS.UPDATE_COMPUTE_RESOURCE_VM, (e: IUpdateComputeResourceVm) => updateVmAction(e))
            // todo refactor https://webpros.atlassian.net/browse/SIO-2993
            .listen(SOCKET_EVENTS.UPDATE_DISK_USAGE_COMPUTE_RESOURCE_VM, (e: IUpdateDiskUsageComputeResourceVm) =>
                updateVmDiskUsageAction(e.id, e.size))
            .listen(SOCKET_EVENTS.UPDATE_PASSWORD_COMPUTE_RESOURCE_VM, (e: IUpdatePasswordComputeResourceVm) =>
                updateVmPasswordAction(e.id, e.password))
            .listen(SOCKET_EVENTS.MOVE_COMPUTE_RESOURCE_VM, (e: IMoveComputeResourceVm) => moveVmAction(e))
            .listen(SOCKET_EVENTS.UPDATE_COMPUTE_RESOURCE_INSTALL_STEP, (e: IUpdateComputeResourceInstallStep) => {
                updateInstallStepAction(e);
            });

        taskChannel.listen(SOCKET_EVENTS.TASK_UPDATED, (e: IUpdateTask) => updateTaskAction(e));
        userTaskChannel.listen(SOCKET_EVENTS.USER_TASK_UPDATED, (e: IUpdateUserTask) => echoUpdateUserTaskAction(e));
        userChannel.listen(SOCKET_EVENTS.UPDATE_USER, (e: IUpdateUser) => updateUserAction(e));
        userChannel.notification((notification: INotificationResponse) => {
            notificationAction(notification);
        });
        updateChannel
            .listen(SOCKET_EVENTS.UPDATE_DATA_UPDATED, (e: IUpdateUpdateData) => {
                checkVersionAction(e);
            })
            .listen(SOCKET_EVENTS.UPDATE_DATA_UPGRADE_RESULT, (e: IUpgradeResult) => {
                upgradeResultAction(e);
            });

        solusVMLicenseChannel
            .listen(SOCKET_EVENTS.SOLUSVM_LICENSE_UPDATED, (e: IUpdateSolusVMLicense) => updateSolusVMLicenseAction(e));

        clusterImportChannel
            .listen(SOCKET_EVENTS.CLUSTER_IMPORT_UPDATED, (e: IClusterImportResource) => updateClusterImportAction(e));

        if (serverUuid) {
            echoConnection.private(`${SOCKET_CHANNELS.COMPUTE_RESOURCE_VM}.${serverUuid}`)
                .listen(SOCKET_EVENTS.UPDATE_SERVER_LIMITS, (e: IUpdateServerLimits) => updateServerLimitsAction(e.limits))
                .listen(SOCKET_EVENTS.SNAPSHOT_UPDATED, (e: IUpdateSnapshot) => updateSnapshotItemAction(e.snapshot));
        }

        return () => {
            echoConnection.disconnect();
        };
    });

    return <>{children}</>;
};

const mapStateToProps = (state: RootState) => ({
    isAuthenticated: state.auth.isAuth,
    authData: state.auth.authData,
    userId: state.auth.user.id,
    serverUuid: state.computeResourceVm.item?.uuid,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    echoActions: bindActionCreators(echoActions, dispatch),
    echoUpdateUserTaskAction: bindActionCreators(updateUserTaskAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EchoProvider);
